import { createContext, useContext } from 'react'
import { Document } from '../models/Document'
import { IDocumentEditorState } from '../components/documentEditor/DocumentEditor.types'

export interface IDesktopDocument {
  document: Document
  addedAt: Date
  documentEditorState: IDocumentEditorState
}

export interface IDesktopContextState {
  notSavedDocuments: Document[]
  isDocumentNotSaved: (document: Document) => boolean

  desktopDocuments: IDesktopDocument[]
  currentDocumentIndex: number
  addDocument: (document: Document, editorState?: Partial<IDocumentEditorState>) => void
  removeDocument: (document: Document) => void
  removeAllDocuments: () => void

  setCurrentDesktopDocument: (desktopDocument: IDesktopDocument) => void
  isCurrentDocument: (document: Document) => boolean
  updateDocumentEditorState: (
    document: Document,
    update: (documentEditorState: IDocumentEditorState) => IDocumentEditorState
  ) => void
  getDocumentEditorState: (document: Document) => IDocumentEditorState | undefined
}

export const DesktopContext = createContext<IDesktopContextState>({
  notSavedDocuments: [],
  isDocumentNotSaved: () => false,
  desktopDocuments: [],
  currentDocumentIndex: -1,
  addDocument: () => {},
  removeDocument: () => {},
  removeAllDocuments: () => {},
  setCurrentDesktopDocument: () => {},
  isCurrentDocument: () => false,
  updateDocumentEditorState: () => {},
  getDocumentEditorState: (document: Document) => {
    return undefined
  },
})

export function useDesktopContext() {
  return useContext(DesktopContext)
}
