import { useEffect, useState } from 'react'
import { Document } from '../../models/Document'
import { IDocumentEditorState, IDocumentEditorStateEvents } from './DocumentEditor.types'
import { DocumentRevision } from '../../models/DocumentRevision'
import { getDocumentEditorSavingInfo } from './DocumentEditorUtils'

export type UseDocumentEditorStateArgs = {
  document: Document | undefined | null
  onChangeDocument: (document: Document) => void
  isEditing: boolean
  onChangeIsEditing: (isEditing: boolean) => void
  initialCommand?: string
  onChangeInitialCommand: (initialCommand?: string) => void
}

export type UseDocumentEditorState = {
  documentEditorState: IDocumentEditorState & IDocumentEditorStateEvents
  isUnsaved: boolean
}

export function useDocumentEditorState(props: UseDocumentEditorStateArgs): UseDocumentEditorState | undefined {
  const { document, onChangeDocument, isEditing, onChangeIsEditing, initialCommand, onChangeInitialCommand } = props
  const [sectionIndex, setSectionIndex] = useState(0)
  const [revisionId, setRevisionId] = useState<number>(0)
  const [draftRevision, setDraftRevision] = useState<DocumentRevision>()
  const [draftDocument, setDraftDocument] = useState<Document>()
  const [isMarkdownContentChanged, setIsMarkdownContentChanged] = useState(false)
  const [markdownContentLen, setMarkdownContentLen] = useState(0)

  useEffect(() => {
    if (!document) {
      setSectionIndex(0)
      setRevisionId(0)
      setDraftRevision(undefined)
      setDraftDocument(undefined)
    }
  }, [document])

  if (!document) {
    return undefined
  }

  const documentEditorState: IDocumentEditorState = {
    document: document,
    isEditing: isEditing,
    sectionIndex: sectionIndex,
    isEditable: true,
    revisionId: revisionId,
    draftRevision: draftRevision,
    draftDocument: draftDocument,
    isMarkdownContentChanged: isMarkdownContentChanged,
    markdownContentLen: markdownContentLen,
    initialCommand: initialCommand,
  }

  const info = getDocumentEditorSavingInfo(documentEditorState)

  const isUnsaved = isEditing && info.canSave

  return {
    isUnsaved,
    documentEditorState: {
      ...documentEditorState,
      onChangeDocument: onChangeDocument,
      onChangeIsEditing: onChangeIsEditing,
      onChangeRevisionId: setRevisionId,
      onChangeDraftRevision: setDraftRevision,
      onChangeDraftDocument: setDraftDocument,
      onChangeSectionIndex: setSectionIndex,
      onChangeMarkdownContent: setIsMarkdownContentChanged,
      onChangeMarkdownContentLen: setMarkdownContentLen,
      onChangeInitialCommand: onChangeInitialCommand,
    },
  }
}
