import { Avatar, Box, useTheme } from '@mui/material'
import { AccountType } from '../../shared/Constants'
import { IAvatarImageProps } from './AvatarImage.types'

export default function AvatarImage(props: IAvatarImageProps) {
  const {
    width,
    height,
    borderSize,
    borderColor,
    placeholderIndicatorSize,
    identity,
    initials,
    image,
    accountType,
    avatarClicked,
    ...sx
  } = props
  const theme = useTheme()

  return (
    <Box {...sx} sx={{ position: 'relative' }}>
      <Avatar
        sx={{
          width: width || 40,
          height: height || 40,
          border: `${borderSize || 1}px solid ${borderColor || theme.palette.common.gray7}`,
        }}
        alt={identity}
        src={image}
        onClick={(e: React.MouseEvent<HTMLHeadingElement>) => {
          e.stopPropagation()
          if (avatarClicked) {
            avatarClicked()
          }
        }}
      >
        {initials}
      </Avatar>

      {accountType === AccountType.placeholderUser && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -2,
            right: -2,
            width: placeholderIndicatorSize || 12,
            height: placeholderIndicatorSize || 12,
            borderRadius: '50%',
            bgcolor: theme.palette.primary.light,
            border: `2px solid ${theme.palette.common.white}`,
          }}
        />
      )}
    </Box>
  )
}
