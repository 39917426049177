import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Document } from '../../models/Document'
import { ArchiveItemSourceType } from '../../shared/Constants'
import {
  AddToDesktopIco,
  DocExternalIco,
  DocInternalIco,
  DoxIco,
  DraftIco,
  InterdictIco,
  MoreVertIco,
  UserAnonymousIco,
} from '../../components/icons'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import DocCommandsMenu from '../../components/docCommandsMenu/DocCommandsMenu'
import msgIds from '../../locales/msgIds'
import { IDocumentsTableProps } from './DocumentsTable.types'
import { SortQuery, invertOrder } from '../../models/DocumentsQuery'
import { useAuthContext } from '../../contexts/AuthContext'
import { StyledTableRow } from '../../shared/StyledControls'
import { IDownload, useDocumentCacheContext } from '../../contexts/DocumentCacheContext'
import { useDesktopContext } from '../../contexts/DesktopContext'
import { findDocument } from '../../contexts/DesktopContextProvider'

const zIndexSx = { position: 'relative', zIndex: 1 }

export default function DocumentsTable(props: IDocumentsTableProps): JSX.Element {
  const { documents } = props
  const theme = useTheme()
  const authContext = useAuthContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const { i18n } = useTranslation()
  const [observer, setObserver] = useState<IntersectionObserver | null>(null)
  const triggerRowRef = useRef<HTMLTableRowElement | null>(null)
  const documentCacheContext = useDocumentCacheContext()
  const desktop = useDesktopContext()

  const triggerIndex = documents.length - 1
  function onLoadTrigger(row: HTMLTableRowElement | null | undefined, index: number) {
    if (!row || !observer || triggerIndex !== index || row === triggerRowRef.current) {
      return
    }
    triggerRowRef.current = row
    observer.disconnect()
    observer.observe(row)
  }

  useEffect(() => {
    const onShowTriggerRow = props.onShowTriggerRow
    if (!onShowTriggerRow) {
      return
    }
    const o = new IntersectionObserver((entries) => {
      const visible = entries.find((entry) => entry.intersectionRatio > 0)
      if (!visible) {
        return
      }
      const rowIndexAttr = visible.target.getAttribute('data-row-index')
      if (!rowIndexAttr) {
        return
      }
      const rowIndex = parseInt(rowIndexAttr, 10)
      onShowTriggerRow(rowIndex)
    }, {})
    setObserver(o)

    return () => {
      o.disconnect()
    }
  }, [props.onShowTriggerRow])

  function isSelected(doc: Document): boolean {
    return -1 !== Utils.findIndexByAttribute(props.selection, 'documentId', doc.documentId)
  }

  function onChangeSortQuery(sortQuery: SortQuery) {
    props.onChangeSortQuery(sortQuery)
  }

  function onSelectAll(flag: boolean) {
    if (flag) {
      props.onChangeSelection(documents)
    } else {
      props.onChangeSelection([])
    }
  }

  function onSelectDocument(doc: Document, flag: boolean) {
    if (flag) {
      if (!isSelected(doc)) {
        props.onChangeSelection([...props.selection, doc])
      }
    } else {
      const selection = props.selection.filter(({ documentId }) => documentId !== doc.documentId)
      props.onChangeSelection(selection)
    }
  }

  function onClickDocument(doc: Document) {
    props.onClickDocument(doc)
  }

  function onAction(event: MouseEvent<HTMLButtonElement, any>, document: Document, action: string) {
    event.stopPropagation()
    event.preventDefault()
    props.onDocumentCommand(document, action)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorId, setAnchorId] = useState<null | string>(null)
  const [menuDoc, setMenuDoc] = useState<null | Document>(null)
  function onClickMore(event: MouseEvent<HTMLButtonElement>, doc: Document) {
    event.stopPropagation()
    event.preventDefault()

    setMenuDoc(doc)
    setAnchorId(event.currentTarget.id)
    setAnchorEl(event.currentTarget)
  }
  function onCloseCommands() {
    setAnchorEl(null)
  }

  return (
    <TableContainer sx={{ maxHeight: '100%', background: theme.palette.common.white, paddingBottom: '8px' }}>
      {menuDoc && anchorId && (
        <DocCommandsMenu
          doc={menuDoc}
          anchorEl={anchorEl}
          anchorId={anchorId}
          onClose={onCloseCommands}
          onDocumentCommand={props.onDocumentCommand}
        />
      )}
      <Table stickyHeader size="small">
        <DocumentsTableHead
          showCheckbox={props.showCheckbox}
          selectedCount={props.selection.length}
          rowsCount={documents.length}
          onSelectAll={onSelectAll}
          sortQuery={props.sortQuery}
          onChangeSortQuery={onChangeSortQuery}
        />
        <TableBody>
          {documents.map((doc, index) => {
            const names: any = { primary: null, secondary: null }
            const archivedRevision = doc.revisions.find(({ revisionId }) => revisionId === doc.lastArchivedRevisionId)
            const draftRevision = doc.revisions.find(({ revisionId }) => revisionId === doc.draftRevisionId)
            if (archivedRevision?.name && archivedRevision.name !== draftRevision?.name) {
              names.primary = archivedRevision.name
              names.secondary = draftRevision?.name
            } else {
              names.primary = archivedRevision?.name || draftRevision?.name
            }
            const revision = archivedRevision || draftRevision
            const isDocSelected = isSelected(doc)
            const labelId = `docs-table-checkbox-${index}`
            const dateString = Utils.toLocaleDateString(revision?.editedAt, i18n)
            const moreButtonId = `dox-more-button-${doc.documentId}`
            const rowId = `doc-row-${doc.documentId}`

            const download: IDownload | undefined = Object.values(documentCacheContext.downloadMap).findLast(
              (d) => d.documentId === doc.documentId
            )
            const progress = (download?.data ? 100 : download?.progress) || 0
            const showDownloadProgress = Boolean(download && progress && progress < 100)

            const selectedButtonStyle = {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.default,
            }

            return (
              <StyledTableRow
                hover
                onClick={() => onClickDocument(doc)}
                role="checkbox"
                aria-checked={isDocSelected}
                tabIndex={-1}
                key={rowId}
                id={rowId}
                data-row-index={index}
                selected={isDocSelected}
                sx={{ cursor: 'pointer', position: 'relative' }}
                ref={(el) => onLoadTrigger(el, index)}
              >
                {props.showCheckbox && (
                  <TableCell padding="checkbox">
                    <Box component="label" id={labelId} sx={{ width: '100%', height: '100%' }}>
                      <Checkbox
                        sx={zIndexSx}
                        color="primary"
                        checked={isDocSelected}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => onSelectDocument(doc, event.target.checked)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </Box>
                  </TableCell>
                )}
                <TableCell align="center">
                  {doc.sourceType === ArchiveItemSourceType.internalSource ? (
                    <DocInternalIco sx={{ ...zIndexSx, color: theme.palette.common.content4 }} />
                  ) : (
                    <DocExternalIco sx={{ ...zIndexSx, color: theme.palette.common.content1 }} />
                  )}
                </TableCell>
                <TableCell id={labelId} scope="row" padding={isMobile ? 'normal' : 'none'}>
                  {showDownloadProgress && (
                    <Box
                      position="absolute"
                      height="100%"
                      top={0}
                      left={0}
                      width={`${Math.floor(progress)}%`}
                      sx={{
                        backgroundColor: theme.palette.primary.lighter,
                        zIndex: 0,
                      }}
                    ></Box>
                  )}
                  <Stack>
                    <Typography sx={zIndexSx} variant={isMobile ? 'subtitle2' : 'body1'}>
                      {names.primary}
                    </Typography>
                    {names.secondary && (
                      <Typography sx={zIndexSx} variant="body2" color={theme.palette.common.gray5}>
                        {names.secondary}
                      </Typography>
                    )}
                    {isMobile && (
                      <Typography sx={zIndexSx} variant="body2">
                        {dateString}
                      </Typography>
                    )}
                    {isMobile && (
                      <Typography sx={zIndexSx} variant="body2">
                        {revision?.creatorIdentity}
                      </Typography>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row">
                    {!isMobile && !isTablet && !authContext.loggedAccount?.isGuest && (
                      <IconButton
                        sx={zIndexSx}
                        style={findDocument(desktop.desktopDocuments, doc) ? selectedButtonStyle : {}}
                        aria-label=""
                        onClick={(e) =>
                          findDocument(desktop.desktopDocuments, doc)
                            ? onAction(e, doc, 'removeFromDesktop')
                            : onAction(e, doc, 'addToDesktop')
                        }
                      >
                        <AddToDesktopIco />
                      </IconButton>
                    )}
                    {!isMobile && !isTablet && (
                      <IconButton sx={zIndexSx} aria-label="" onClick={(e) => onAction(e, doc, 'associateToDox')}>
                        <DoxIco />
                      </IconButton>
                    )}
                    <IconButton
                      sx={zIndexSx}
                      id={moreButtonId}
                      aria-haspopup="true"
                      aria-expanded={anchorEl ? 'true' : undefined}
                      aria-controls={anchorEl ? 'doc-commands-menu' : undefined}
                      onClick={(event) => onClickMore(event, doc)}
                    >
                      <MoreVertIco />
                    </IconButton>
                  </Stack>
                </TableCell>
                {!isMobile && (
                  <TableCell align="left">
                    <Typography sx={zIndexSx}>{dateString}</Typography>
                  </TableCell>
                )}
                {!isMobile && (
                  <TableCell align="left">
                    <Typography sx={zIndexSx}>{revision?.creatorIdentity}</Typography>
                  </TableCell>
                )}
                {!isMobile && !isTablet && (
                  <TableCell align="right">
                    <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
                      {doc.draftRevisionId !== 0 && doc.authorProfileId === authContext.loggedProfileId && (
                        <DraftIco sx={zIndexSx} />
                      )}
                      {!!doc.expiredAt && <InterdictIco sx={zIndexSx} />}
                      {!!doc.anonymousAt ? (
                        <UserAnonymousIco sx={zIndexSx} />
                      ) : (
                        <Box sx={{ ...zIndexSx, width: '24px' }} />
                      )}
                    </Stack>
                  </TableCell>
                )}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface HeadCell {
  id: string
  label: string
  align: 'left' | 'right' | 'center'
  sortable?: string
  disablePadding: boolean
  mobile: boolean
  tablet: boolean
}

interface DocumentsTableHeadProps {
  selectedCount: number
  rowsCount: number
  showCheckbox: boolean
  onSelectAll: (flag: boolean) => void
  sortQuery: SortQuery
  onChangeSortQuery: (sortQuery: SortQuery) => void
}

function DocumentsTableHead(props: DocumentsTableHeadProps): JSX.Element {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  const headCells: HeadCell[] = [
    {
      id: 'icon',
      label: '',
      align: 'center',
      disablePadding: true,
      mobile: true,
      tablet: true,
    },
    {
      id: 'title',
      label: t(msgIds.MSG_DOCS_TABLE_HEADER_NAME),
      align: 'left',
      disablePadding: !isMobile,
      mobile: true,
      tablet: true,
      sortable: 'name',
    },
    {
      id: 'actions',
      label: '',
      align: 'left',
      disablePadding: false,
      mobile: true,
      tablet: true,
    },
    {
      id: 'date',
      label: t(msgIds.MSG_DOCS_TABLE_HEADER_DATE),
      align: 'left',
      disablePadding: false,
      mobile: false,
      tablet: true,
      sortable: 'edited_at',
    },
    {
      id: 'author',
      label: t(msgIds.MSG_DOCS_TABLE_HEADER_AUTHOR),
      align: 'left',
      disablePadding: false,
      mobile: false,
      tablet: true,
    },
    {
      id: 'sign',
      label: '',
      align: 'left',
      disablePadding: false,
      mobile: false,
      tablet: false,
    },
    {
      id: 'anonymous',
      label: '',
      align: 'left',
      disablePadding: true,
      mobile: false,
      tablet: false,
    },
  ]
  const { sortQuery } = props
  function onClickSortLabel(headCell: HeadCell) {
    if (!headCell.sortable) {
      return
    }
    props.onChangeSortQuery({ field: headCell.sortable, order: invertOrder(sortQuery.order) })
  }
  return (
    <TableHead>
      <TableRow>
        {props.showCheckbox && <TableCell padding="checkbox"></TableCell>}
        {headCells
          .filter(({ mobile, tablet }) => !((!mobile && isMobile) || (!tablet && isTablet)))
          .map((headCell) => {
            const isSorting = headCell.sortable === sortQuery.field
            return (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={isSorting ? sortQuery.order : false}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    onClick={() => onClickSortLabel(headCell)}
                    active={isSorting}
                    direction={sortQuery.order}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}
