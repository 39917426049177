import { Box, IconButton, Input, Stack, Typography, useTheme } from '@mui/material'
import { DeleteIco, UploadDocIco } from '../icons'
import { ChangeEvent, useState, useRef, useEffect, DragEvent, useMemo } from 'react'
import { FileContent } from './FileContent'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { IDocumentUploadProps } from './DocumentUpload.types'
import { Utils } from '../../shared/Utils'
import { isPersonalDoxMimetype } from '../../shared/Constants'

export default function DocumentUpload(props: IDocumentUploadProps): JSX.Element {
  const { revision, onChangeRevision, onRestoreSavedDraftContent } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const fileData = useMemo(() => {
    if (isPersonalDoxMimetype(revision.mimetype)) {
      // not supported
      return null
    }
    const binaryStr = Utils.arrayBufferToBinary(revision.content as ArrayBuffer)
    return `data:${revision.mimetype};base64,` + btoa(binaryStr)
  }, [revision.content, revision.mimetype])
  const [files, setFiles] = useState<File[]>([])
  const file: File | undefined = files[0]
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(fileData)
  const fileMimetype = revision.mimetype
  const fileName = revision.filename
  const fileSize = revision.size
  const [isHoverDrop, setIsHoverDrop] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)

  function onChangeFiles(event: ChangeEvent<HTMLInputElement>) {
    if (!event.currentTarget.files) {
      setFiles([])
      return
    }
    setFiles(Utils.mapFileList(event.currentTarget.files))
    event.target.value = '' // due to resolve this issue: https://github.com/ngokevin/react-file-reader-input/issues/11
  }

  function onDrop(event: DragEvent<HTMLElement>) {
    event.preventDefault()
    const files = Utils.filesFromDragEvent(event)
    if (Array.isArray(files) && files.length > 0) {
      setFiles(files)
    }
  }

  useEffect(() => {
    if (!file) {
      return
    }
    const abortController = new AbortController()
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = function (event) {
      if (abortController.signal.aborted) {
        return
      }
      if (event.target) {
        setFileContent(event.target.result)
      }
    }

    return () => {
      abortController.abort()
    }
  }, [file])

  const fileContentArrayBuf = useMemo(() => {
    if (!fileContent) {
      return null
    }
    const strBuffer = fileContent as string

    // extract binary from base64 image
    // TODO: put that computation in a WebWorker to avoid UI freeze
    var BASE64_MARKER = ';base64,'
    var base64Index = strBuffer.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    const strImage = strBuffer.substring(base64Index, strBuffer.length)
    var raw = atob(strImage)
    var rawLength = raw.length
    var array = new Uint8Array(new ArrayBuffer(rawLength))
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }
    return array as ArrayBuffer
  }, [fileContent])

  const fileContentChecksum = useMemo(() => {
    if (!fileContentArrayBuf) {
      return ''
    }
    // TODO: put that computation in a WebWorker to avoid UI freeze
    return Utils.getBinarySHA256Checksum(fileContentArrayBuf)
  }, [fileContentArrayBuf])

  useEffect(() => {
    if (!file) {
      return
    }
    const changed =
      fileContentChecksum !== revision.checksum || file.type !== revision.mimetype || file.name !== revision.filename

    if (!changed) {
      return
    }
    onChangeRevision({
      ...revision,
      content: fileContentArrayBuf || '',
      checksum: fileContentChecksum || '',
      mimetype: file?.type,
      filename: file?.name,
      size: file?.size,
    })
  }, [fileContentChecksum, fileContentArrayBuf, file, onChangeRevision, revision])

  async function onDeleteFile(file: File) {
    // console.log({ onDeleteFile: file, ref: inputFileRef.current })
    setFiles([])
    setFileContent(null)
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }
    onRestoreSavedDraftContent()
  }

  return (
    <Stack spacing={0} padding={2} sx={{ boxSizing: 'border-box' }} height="100%" style={props.style}>
      {!!fileData && (
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Stack direction="column" flexGrow={1}>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: '500' }}>{t(msgIds.MSG_DOCUMENT_UPLOAD_FILE_NAME_LABEL)}:</Typography>
              <Typography>{fileName}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: '500' }}>{t(msgIds.MSG_DOCUMENT_UPLOAD_FILE_SIZE_LABEL)}:</Typography>
              <Typography>{Utils.displayByteSize(fileSize)}</Typography>
            </Stack>
          </Stack>
          {files.length > 0 && (
            <IconButton color="inherit" sx={{ flexShrink: 0 }} onClick={() => onDeleteFile(files[0])}>
              <DeleteIco />
            </IconButton>
          )}
          <IconButton color="inherit" sx={{ flexShrink: 0 }} onClick={() => inputFileRef.current?.click()}>
            <UploadDocIco />
          </IconButton>
        </Stack>
      )}
      <Typography sx={{ fontWeight: '300', fontSize: 12, alignSelf: 'center' }}>
        {t(msgIds.MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL)}:
      </Typography>
      <Box
        onMouseOver={() => setIsHoverDrop(true)}
        onMouseLeave={() => setIsHoverDrop(false)}
        onDrop={onDrop}
        onDragOver={(event) => event.preventDefault()}
        id="file-input-label"
        component="label"
        sx={{
          flex: 1,
          cursor: 'pointer',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          minWidth: '400px',
          minHeight: '300px',
          width: '100%',
          borderStyle: 'dashed',
          borderRadius: 2,
          borderWidth: 1,
          backgroundColor: isHoverDrop ? theme.palette.grey[200] : theme.palette.grey[50],
        }}
      >
        {!!fileData ? (
          <FileContent
            mimetype={fileMimetype || ''}
            filename={fileName || ''}
            data={fileData || files[0].webkitRelativePath}
          />
        ) : (
          <>
            <UploadDocIco />
            <Typography>{t(msgIds.MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL)}</Typography>
          </>
        )}
        <Input ref={inputFileRef} sx={{ display: 'none' }} type="file" required onChange={onChangeFiles} />
      </Box>
    </Stack>
  )
}
