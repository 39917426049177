import { MutableRefObject, useEffect, useRef } from 'react'

export function useObserver<T extends Element>(
  callback: () => void
): MutableRefObject<T | null> | ((el: T | null) => void) {
  const loadMoreRef = useRef<T | null>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    console.log({ useObserver: 'creating IntersectionObserver' })
    const observer = new IntersectionObserver((entries) => {
      const visible = entries.find((entry) => entry.intersectionRatio > 0)
      if (!visible) {
        return
      }
      callback()
    }, {})
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }
    observerRef.current = observer
    return () => {
      observer.disconnect()
    }
  }, [callback])

  return (el: T | null) => {
    loadMoreRef.current = el || null
    if (observerRef.current && el) {
      observerRef.current.observe(el)
    }
  }
}
