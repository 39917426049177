import { Box, Divider, Stack } from '@mui/material'
import * as dalAuth from '../../dal/DalAuth'
import { useState } from 'react'
import { Account } from '../../models/Account'
import AccountIdentity from '../identities/AccountIdentity'
import { useTranslation } from 'react-i18next'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { IProfileSelectorProps } from './ProfileSelector.types'
import { useDesktopContext } from '../../contexts/DesktopContext'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { IDialogResultBase } from '../template/dialogTemplate/DialogTemplate.types'
import msgIds from '../../locales/msgIds'

export function ProfileSelector(props: IProfileSelectorProps): JSX.Element {
  const { accounts, onChangeAccount } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const desktop = useDesktopContext()
  const [notSavedDocumentsDialogOpen, setNotSavedDocumentsDialogOpen] = useState<boolean>(false)
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()

  function onClickAccount(account: Account) {
    if (desktop.notSavedDocuments.length > 0) {
      setNotSavedDocumentsDialogOpen(true)
      setSelectedAccount(account)
      return
    }
    changeActiveProfile(account)
  }

  async function changeActiveProfile(account: Account) {
    const profileId = account.profile?.profileId
    if (!profileId) {
      return
    }
    const abortController = new AbortController()
    try {
      const account = await dalAuth.changeActiveProfile(abortController.signal, profileId)
      authContext.setLoggedAccount(account)
      if (onChangeAccount) {
        onChangeAccount(account)
      }
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    }
  }

  function onCloseNotSavedDocuments(result: IDialogResultBase) {
    if (result.userChoice === 'yes' && selectedAccount) {
      changeActiveProfile(selectedAccount)
    }
    setNotSavedDocumentsDialogOpen(false)
  }

  return (
    <Stack spacing={2}>
      {accounts.map((account, i) => (
        <Stack key={i} spacing={2}>
          <Box onClick={() => onClickAccount(account)} sx={{ cursor: 'pointer' }}>
            <AccountIdentity
              account={account}
              infoToShow={['main']}
              showProfileDsc={true}
              showProfileInfo={false}
              avatarClicked={() => {}}
            />
          </Box>
          <Divider />
        </Stack>
      ))}
      <SimpleDialog
        isOpen={notSavedDocumentsDialogOpen}
        onClose={onCloseNotSavedDocuments}
        title={t(msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_TITLE)}
        content={t(msgIds.MSG_DOCUMENTS_NOT_SAVED_DIALOG_CONTENT)}
        actionsStyle="yesNO"
      />
    </Stack>
  )
}
