import { useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import Editor from '@toast-ui/editor'
import { IMarkdownEditorProps } from './MarkdownEditor.types'
import { RedoIco, UndoIco } from '../icons'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'

export default function MarkdownEditor(props: IMarkdownEditorProps) {
  const { initialValue, onContentChanged, height, style } = props
  const { t } = useTranslation()
  const ref = useRef(null)
  const editorInstRef = useRef<Editor | null>(null)
  const isEditorInitializedRef = useRef(false)
  const isContentInitializingRef = useRef(false)
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  function createUndoButton() {
    const button = document.createElement('button')
    button.className = 'toastui-editor-toolbar-icons last'
    button.style.backgroundImage = 'none'
    button.style.margin = '0'

    const root = createRoot(button)
    root.render(<UndoIco />)

    button.addEventListener('click', () => {
      editorInstRef.current?.exec('undo')
    })

    return button
  }

  function createRedoButton() {
    const button = document.createElement('button')
    button.className = 'toastui-editor-toolbar-icons last'
    button.style.backgroundImage = 'none'
    button.style.margin = '0'

    const root = createRoot(button)
    root.render(<RedoIco />)

    button.addEventListener('click', () => {
      editorInstRef.current?.exec('redo')
    })

    return button
  }

  useEffect(() => {
    if (!editorInstRef.current) {
      editorInstRef.current = new Editor({
        el: ref.current!,
        height: height ?? 'auto',
        autofocus: true,
        usageStatistics: false,
        hideModeSwitch: true,
        initialEditType: 'wysiwyg',
        toolbarItems: [
          [
            {
              el: createUndoButton(),
              name: 'Undo',
              tooltip: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_UNDO).toString(),
            },
            {
              el: createRedoButton(),
              name: 'Redo',
              tooltip: t(msgIds.MSG_DOCUMENT_EDITOR_COMMAND_REDO).toString(),
            },
          ],
          ['heading', 'bold', 'italic', 'strike'],
          ['ul', 'ol'],
          ['indent', 'outdent'],
          ['hr'],
        ],
        plugins: [],
        events: {
          load: () => {
            console.log(`mdeditor.load isEditorInitializedRef:${isEditorInitializedRef.current}`)

            const md = editorInstRef.current?.getMarkdown()
            if (md !== '') {
              isEditorInitializedRef.current = true
            } else {
              isEditorInitializedRef.current = false
              setTimeout(() => {
                initializeText(' ')
                setTimeout(() => {
                  initializeText()
                  setTimeout(() => {
                    isEditorInitializedRef.current = true
                  }, 50)
                }, 50)
              }, 50)
            }
          },
          change: () => {
            if (isEditorInitializedRef.current === false || isContentInitializingRef.current === true) return

            if (changeTimeoutRef.current) {
              clearTimeout(changeTimeoutRef.current)
            }

            // TODO: consider combined tecniques to evaluate if aquire the content like:
            //       - velocity of the writer
            //       - word completion
            changeTimeoutRef.current = setTimeout(() => {
              if (ref.current && onContentChanged) {
                const md = editorInstRef.current?.getMarkdown() ?? ''
                onContentChanged(md, initialValue !== md, md.length)
              }
            }, 500)
          },
        },
        ...props,
      })
    }

    return () => {
      if (editorInstRef?.current) {
        editorInstRef.current.destroy()
        editorInstRef.current = null
      }

      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current)
      }
    }
  }, [ref.current])

  useEffect(() => {
    initializeText()
  }, [initialValue])

  function initializeText(text?: string) {
    if (editorInstRef?.current) {
      const newText = text || initialValue || ''
      const md = editorInstRef.current?.getMarkdown()
      if (md !== newText) {
        isContentInitializingRef.current = true
        editorInstRef.current.setMarkdown(newText)
        isContentInitializingRef.current = false
      }
      onContentChanged(md, false, md.length)
      setTimeout(() => {
        editorInstRef.current?.moveCursorToEnd()
      }, 50)
    }
  }

  return <div ref={ref} style={{ ...style }} />
}
