import { useTranslation } from 'react-i18next'
import CommandBar from '../../components/commandBar/CommandBar'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import { Registration } from '../../components/registration/Registration'
import msgIds from '../../locales/msgIds'
import { ContractType, ProfileType, ProfileTypeTranslationMap, RegistrationPurpose } from '../../shared/Constants'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { RegistrationStepId } from '../../components/registration/Registration.types'
import { IRegistrationPageProps } from './RegistrationPage.types'
import { useAuthContext } from '../../contexts/AuthContext'

export type BusinessPlanType = 'free' | 'trial' | 'paid'

export function RegistrationPage(): JSX.Element {
  const { t } = useTranslation()
  const location = useLocation()
  const state = location.state as IRegistrationPageProps
  const { profileType, purpose, productId, priceId, isTrial, isFreePlan, contractTypes, hasBillingData } = state
  const authContext = useAuthContext()

  const steps: RegistrationStepId[] = useMemo(() => {
    if (profileType === ProfileType.customer) {
      switch (purpose) {
        case RegistrationPurpose.contractRevision: {
          authContext.showOnlyPageContent()
          const _steps: RegistrationStepId[] = []
          if (contractTypes?.includes(ContractType.privacyPolicy)) {
            _steps.push('contractPrivacyPolicy')
          }
          if (contractTypes?.includes(ContractType.termsOfUse)) {
            _steps.push('contractTermsOfUse')
          }
          _steps.push('summary')
          return _steps
        }
        case RegistrationPurpose.profileActivation: {
          return ['contractPrivacyPolicy', 'contractTermsOfUse', 'summary']
        }
        case RegistrationPurpose.userRegistration: {
          return ['contractPrivacyPolicy', 'contractTermsOfUse', 'personIdentity', 'credentials', 'summary']
        }
        case RegistrationPurpose.placeholderRegistration: {
          return ['contractPrivacyPolicy', 'personIdentity', 'summary']
        }
      }
    } else if (profileType === ProfileType.operatorAdmin) {
      switch (purpose) {
        case RegistrationPurpose.contractRevision: {
          authContext.showOnlyPageContent()
          const _steps: RegistrationStepId[] = []
          if (contractTypes?.includes(ContractType.privacyPolicy)) {
            _steps.push('contractPrivacyPolicy')
          }
          if (contractTypes?.includes(ContractType.termsOfUse)) {
            _steps.push('contractTermsOfUse')
          }
          if (contractTypes?.includes(ContractType.dataProcessor)) {
            _steps.push('contractDataProcessor')
          }
          _steps.push('summary')
          return _steps
        }
        case RegistrationPurpose.profileActivation: {
          if (priceId && !hasBillingData && !isFreePlan && !isTrial) {
            return [
              'contractPrivacyPolicy',
              'contractTermsOfUse',
              'contractDataProcessor',
              'companyIdentity',
              'billing',
              'summary',
            ]
          } else {
            return [
              'contractPrivacyPolicy',
              'contractTermsOfUse',
              'contractDataProcessor',
              'companyIdentity',
              'summary',
            ]
          }
        }
        case RegistrationPurpose.userRegistration: {
          if (priceId && !isFreePlan && !isTrial) {
            return [
              'contractPrivacyPolicy',
              'contractTermsOfUse',
              'contractDataProcessor',
              'personIdentity',
              'companyIdentity',
              'billing',
              'credentials',
              'summary',
            ]
          } else {
            return [
              'contractPrivacyPolicy',
              'contractTermsOfUse',
              'contractDataProcessor',
              'personIdentity',
              'companyIdentity',
              'credentials',
              'summary',
            ]
          }
        }
        case RegistrationPurpose.changePlan: {
          return ['billing', 'summary']
        }
      }
    }
    return []
  }, [profileType, purpose])

  function getTitle() {
    switch (purpose) {
      case RegistrationPurpose.contractRevision:
        return t(msgIds.MSG_REGISTRATION_PAGE_TITLE_CONTRACT_REVISION, {
          profileTypeDsc: t(ProfileTypeTranslationMap[profileType]),
        })
      case RegistrationPurpose.profileActivation:
        return t(msgIds.MSG_REGISTRATION_PAGE_TITLE_PROFILE_CREATION, {
          profileTypeDsc: t(ProfileTypeTranslationMap[profileType]),
        })
      case RegistrationPurpose.userRegistration:
        return t(msgIds.MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION)
      case RegistrationPurpose.changePlan:
        return t(msgIds.MSG_REGISTRATION_PAGE_TITLE_CHANGE_PLAN)
      case RegistrationPurpose.placeholderRegistration:
        return t(msgIds.MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION)
      default:
        throw new Error(`Registration purpose non managed: ${purpose}`)
    }
  }

  return (
    <PageContainer>
      <CommandBar style={{ minHeight: 'auto' }} title={getTitle()} commands={[]} />
      <PageContent disablePadding>
        <Registration
          profileType={state.profileType}
          productId={state.productId}
          priceId={state.priceId}
          isTrial={state.isTrial}
          isFreePlan={state.isFreePlan}
          purpose={state.purpose}
          hasBillingData={state.hasBillingData}
          title={getTitle()}
          steps={steps}
          sx={{ p: 4, flex: 1 }}
        />
      </PageContent>
    </PageContainer>
  )
}
