import { Dox } from './Dox'

export interface IDocumentRetentionJson {
  id: number
  document_id?: number
  dox_id: number
  target_profile_id: number
  notes: string
  type: number
  treatment_id: number
  treatment_description: string
  start_at?: string
  end_at?: string
  notice_at?: string
  created_at: string
  updated_at: string
}

export class DocumentRetention {
  id: number = 0
  documentId?: number = undefined
  doxId: number = 0
  targetProfileId: number = 0
  notes: string = ''
  type: number = 0
  treatmentId: number = 0
  treatmentDescription: string = ''
  startAt?: string = undefined
  endAt?: string = undefined
  noticeAt?: string = undefined
  createdAt: string = ''
  updatedAt: string = ''

  dox?: Dox = undefined

  constructor() {}

  public static serialize(obj: DocumentRetention): IDocumentRetentionJson {
    return {
      id: obj.id,
      document_id: obj.documentId,
      dox_id: obj.doxId,
      target_profile_id: obj.targetProfileId,
      notes: obj.notes,
      type: obj.type,
      treatment_id: obj.treatmentId,
      treatment_description: obj.treatmentDescription,
      start_at: obj.startAt,
      end_at: obj.endAt,
      notice_at: obj.notes,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
    }
  }

  public static deserialize(json: IDocumentRetentionJson): DocumentRetention {
    const res = new DocumentRetention()
    res.id = json.id
    res.documentId = json.document_id
    res.doxId = json.dox_id
    res.targetProfileId = json.target_profile_id
    res.notes = json.notes
    res.type = json.type
    res.treatmentId = json.treatment_id
    res.treatmentDescription = json.treatment_description
    res.startAt = json.start_at
    res.endAt = json.end_at
    res.noticeAt = json.notice_at
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    return res
  }

  public static serializeArray(objs: DocumentRetention[]): IDocumentRetentionJson[] {
    const jsons = objs.map((p) => {
      return DocumentRetention.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IDocumentRetentionJson[]): DocumentRetention[] {
    const res = json.map((p) => {
      return DocumentRetention.deserialize(p)!
    })
    return res
  }
}
