import { useEffect, useMemo, useState } from 'react'
import { DesktopContext, IDesktopContextState, IDesktopDocument } from './DesktopContext'
import { Document } from '../models/Document'
import { IDocumentEditorState } from '../components/documentEditor/DocumentEditor.types'
import { DesktopContextProviderState, IDesktopContextProviderProps } from './DesktopContextProvider.types'
import {
  areRevisionInfoChanged,
  getDocumentRevision,
  isRevisionContentChanged,
} from '../components/documentEditor/DocumentEditorUtils'

export function DesktopContextProvider(props: IDesktopContextProviderProps): JSX.Element {
  const { children } = props
  const [state, setState] = useState<DesktopContextProviderState>({
    desktopDocuments: [],
    currentDocumentIndex: -1,
  })

  useEffect(() => {
    if (state.desktopDocuments.length === 0) {
      setState((state) => {
        return { ...state, currentDocumentIndex: -1 }
      })
    }
  }, [state.desktopDocuments.length])

  function addDocument(document: Document, editorState?: Partial<IDocumentEditorState>) {
    setState((state) => {
      const documents = state.desktopDocuments
      if (document.documentId) {
        const d = findDocument(documents, document.documentId)
        if (d) {
          return state
        }
      }
      const desktopDocument: IDesktopDocument = {
        document,
        addedAt: new Date(),
        documentEditorState: {
          isEditing: false,
          isEditable: true,
          sectionIndex: 0,
          revisionId: 0,
          ...editorState,
          document,
          isMarkdownContentChanged: false,
          markdownContentLen: 0,
        },
      }
      return {
        ...state,
        desktopDocuments: [desktopDocument, ...documents],
        currentDocumentIndex: 0,
      }
    })
  }

  function removeDocument(document: Document) {
    setState((state) => {
      const { desktopDocuments } = state
      const desktopDocumentIndex = findDocumentIndex(desktopDocuments, document)
      if (desktopDocumentIndex < 0) {
        return { ...state }
      }
      const copy = [...desktopDocuments]
      copy.splice(desktopDocumentIndex, 1)
      let currentDocumentIndex
      if (desktopDocumentIndex === state.currentDocumentIndex) {
        currentDocumentIndex = copy.length === 0 ? -1 : 0
      } else if (desktopDocumentIndex < state.currentDocumentIndex) {
        currentDocumentIndex = state.currentDocumentIndex - 1
      } else {
        currentDocumentIndex = state.currentDocumentIndex
      }
      return { ...state, desktopDocuments: copy, currentDocumentIndex }
    })
  }

  function removeAllDocuments() {
    setState((state) => {
      return { ...state, desktopDocuments: [], currentDocumentIndex: -1 }
    })
  }

  function setCurrentDesktopDocument(desktopDocument: IDesktopDocument) {
    setState((state) => {
      const index = state.desktopDocuments.findIndex((p) => p === desktopDocument)
      console.log(`index of document in desktop: ${index}`)
      if (index === -1) {
        return state
      }
      return { ...state, currentDocumentIndex: index }
    })
  }

  function isCurrentDocument(document: Document) {
    const currentDocument: IDesktopDocument | undefined = state.desktopDocuments[state.currentDocumentIndex]
    return (currentDocument?.document || false) && isSameDocument(currentDocument.document, document)
  }

  function updateDocumentEditorState(
    document: Document,
    update: (documentEditorState: IDocumentEditorState) => IDocumentEditorState
  ) {
    setState((state) => {
      const { desktopDocuments } = state
      const desktopDocumentIndex = findDocumentIndex(desktopDocuments, document)
      if (desktopDocumentIndex < 0) {
        return { ...state }
      }
      const desktopDocument = desktopDocuments[desktopDocumentIndex]
      if (!desktopDocument) {
        return { ...state }
      }
      const updatedDocuments = [...desktopDocuments]
      const newDocumentEditorState = update(desktopDocument.documentEditorState)
      const newDesktopDocument = {
        ...desktopDocument,
        document: newDocumentEditorState.document,
        documentEditorState: newDocumentEditorState,
      }
      updatedDocuments.splice(desktopDocumentIndex, 1, newDesktopDocument)
      return { ...state, desktopDocuments: updatedDocuments }
    })
  }

  function getDocumentEditorState(document: Document) {
    const documentState = state.desktopDocuments.find((p) => p.document === document)
    const documentEditorState = documentState?.documentEditorState
    return documentEditorState
  }

  const notSavedDocuments = useMemo(() => {
    return state.desktopDocuments
      .filter((desktopDocument) => {
        const { isEditing, revisionId, draftRevision, draftDocument, document } = desktopDocument.documentEditorState
        const revision = getDocumentRevision(document, revisionId, isEditing)
        const infoChanged = areRevisionInfoChanged(draftRevision, revision, draftDocument, document)
        const contentChanged = isRevisionContentChanged(draftRevision, revision)
        return isEditing && (infoChanged || contentChanged)
      })
      .map((d) => d.document)
  }, [state.desktopDocuments])

  function isDocumentNotSaved(document: Document) {
    return notSavedDocuments.findIndex((notSaved) => isSameDocument(notSaved, document)) !== -1
  }

  const value: IDesktopContextState = {
    notSavedDocuments,
    isDocumentNotSaved,
    desktopDocuments: state.desktopDocuments,
    currentDocumentIndex: state.currentDocumentIndex,
    addDocument,
    removeDocument,
    removeAllDocuments,
    setCurrentDesktopDocument,
    isCurrentDocument,
    updateDocumentEditorState,
    getDocumentEditorState,
  }
  return <DesktopContext.Provider value={value}>{children}</DesktopContext.Provider>
}

export function findDocument(documents: IDesktopDocument[], document: number | Document): IDesktopDocument | undefined {
  if (typeof document === 'number') {
    return documents.find(({ document: doc }) => doc.documentId === document)
  } else {
    return documents.find(({ document: doc }) => isSameDocument(doc, document))
  }
}

export function findDocumentIndex(documents: IDesktopDocument[], document: number | Document): number {
  if (typeof document === 'number') {
    return documents.findIndex(({ document: doc }) => doc.documentId === document)
  } else {
    return documents.findIndex(({ document: doc }) => isSameDocument(doc, document))
  }
}

export function isSameDocument(docA: Document | undefined, docB: Document | undefined): boolean {
  if (docA && docB) {
    return Object.is(docA, docB) || (Boolean(docA.documentId && docB.documentId) && docA.documentId === docB.documentId)
  } else {
    return false
  }
}
