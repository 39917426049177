import { PropsWithChildren, useMemo, useState } from 'react'
import { ITutorialContextState, Tutorial, TutorialAction, TutorialContext } from './TutorialContext'
import { TutorialView } from '../components/tutorialView/TutorialView'
import { DraggableOverWindow } from '../components/draggableOverWindow/DraggableOverWindow'
import { DoxIco, UserIco } from '../components/icons'

const initTutorials: Tutorial[] = [
  {
    id: 'first',
    icon: <UserIco />,
    title: 'Primo tutorial',
    steps: [
      {
        markdown: `questo è un tutorial:
        - punto 1
        - punto 2
        `,
      },
      {
        markdown: `**SECONDO STEP**`,
      },
    ],
    stepIndex: 0,
  },
  {
    id: 'second',
    icon: <DoxIco />,
    title: 'second tutorial',
    steps: [
      {
        markdown: `questo è un **altro** tutorial:
        - punto A
        - punto B
        `,
      },
      {
        markdown: `secondo step:
        - ciao
        - ciao
        `,
      },
    ],
    stepIndex: 0,
  },
]

function applyTutorialAction(tutorial: Tutorial, action: TutorialAction): Tutorial {
  if (action === 'previous' && tutorial.stepIndex > 0) {
    return { ...tutorial, stepIndex: tutorial.stepIndex - 1 }
  } else if (action === 'next' && tutorial.stepIndex < tutorial.steps.length - 1) {
    return { ...tutorial, stepIndex: tutorial.stepIndex + 1 }
  } else if (action === 'stop') {
    return { ...tutorial, stepIndex: 0 }
  } else {
    return tutorial
  }
}

export function TutorialContextProvider(props: PropsWithChildren): JSX.Element {
  const { children } = props
  const [tutorials, setTutorials] = useState<Tutorial[]>(initTutorials)
  const [currentTutorialId, setCurrentTutorialId] = useState<string | undefined>()
  const visibleTutorial = tutorials.find(({ id }) => id === currentTutorialId)
  const openTutorialWindow = Boolean(visibleTutorial)
  const onCloseTutorialWindow = () => setCurrentTutorialId(undefined)

  const { tutorialTitle, disabledActions } = useMemo(() => {
    const disabledActions: TutorialAction[] = []
    if (!visibleTutorial) {
      return { tutorialTitle: undefined, disabledActions }
    }
    if (visibleTutorial.stepIndex >= visibleTutorial.steps.length - 1) {
      disabledActions.push('next')
    }
    if (visibleTutorial.stepIndex <= 0) {
      disabledActions.push('previous')
    }
    return {
      tutorialTitle: `(${visibleTutorial.stepIndex + 1}/${visibleTutorial.steps.length}) ${visibleTutorial.title}`,
      disabledActions,
    }
  }, [visibleTutorial])
  console.log({ disabledActions });

  //useEffect(() => {
  //  // load tutorial (or tutorial state) from somewhere
  //  // setTutorials(...)
  //}, [])

  function showTutorial(tutorialId: string) {
    setCurrentTutorialId(tutorialId)
  }

  function updateTutorial(tutorialId: string, action: TutorialAction) {
    if (action === 'stop') {
      setCurrentTutorialId(undefined)
    }
    setTutorials((tutorials) => {
      const index = tutorials.findIndex(({ id }) => id === tutorialId)
      if (index === -1) {
        return tutorials
      }
      return tutorials.map((t, i) => {
        if (i === index) {
          return applyTutorialAction(t, action)
        }
        return t
      })
    })
  }

  const value: ITutorialContextState = {
    tutorials,
    showTutorial,
    updateTutorial,
  }
  return (
    <TutorialContext.Provider value={value}>
      {children}
      <DraggableOverWindow title={tutorialTitle || ''} open={openTutorialWindow} onClose={onCloseTutorialWindow}>
        {visibleTutorial && (
          <TutorialView
            tutorial={visibleTutorial}
            disabledActions={disabledActions}
            onAction={(action) => updateTutorial(visibleTutorial.id, action)}
            sx={{ height: '100%' }}
          />
        )}
      </DraggableOverWindow>
    </TutorialContext.Provider>
  )
}
