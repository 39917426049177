import { useTranslation } from 'react-i18next'
import CommandBar from '../../components/commandBar/CommandBar'
import { PageContainer, PageContent } from '../../components/pageContainer/PageContainer'
import msgIds from '../../locales/msgIds'
import log from '../../shared/Logger'
import * as dalDocument from '../../dal/DalDocument'
import { IDesktopDocument, useDesktopContext } from '../../contexts/DesktopContext'
import { Document } from '../../models/Document'
import { IconButton, Paper, Stack } from '@mui/material'
import DocumentEditor from '../../components/documentEditor/DocumentEditor'
import { DesktopDocumentsMenuMode } from '../../components/desktopDocumentsMenu/DesktopDocumentsMenu.types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ActionType, ArchiveItemSourceType } from '../../shared/Constants'
import { Utils } from '../../shared/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import { DesktopMenu } from '../../components/desktopMenu/DesktopMenu'
import { DocumentFile } from '../../shared/types/DocumentFile'
import { IDocumentEditorState } from '../../components/documentEditor/DocumentEditor.types'
import { useLayout } from '../../hooks/Layout'
import {
  AddDocExternalIco,
  AddDocInternalIco,
  AddDocInternalTemplateIco,
  ClearDesktopIco,
  SettingsIco,
} from '../../components/icons'
import { Center } from '../../components/center/Center'
import { InternalDocumentTemplate } from '../../models/InternalDocumentTemplate'
import { useLocation } from 'react-router'
import { IDesktopPageProps } from './DesktopPage.types'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { IInternalDocTemplateSelectorDialogData } from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog.types'
import InternalDocTemplateSelectorDialog from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog'
import { DocumentRevision } from '../../models/DocumentRevision'
import { useSnackbar } from 'notistack'

export default function DesktopPage() {
  const location = useLocation()
  const state = location.state as IDesktopPageProps
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { theme, isMobile } = useLayout()
  const desktop = useDesktopContext()
  const authContext = useAuthContext()
  const currentDesktopDocumentRef = useRef<IDesktopDocument>()
  const desktopDocumentsRef = useRef<IDesktopDocument[]>()
  const notSavedDocumentsRef = useRef<Document[]>()

  desktopDocumentsRef.current = desktop.desktopDocuments
  notSavedDocumentsRef.current = desktop.notSavedDocuments
  currentDesktopDocumentRef.current = desktop.desktopDocuments[desktop.currentDocumentIndex]
  const currentDocument = currentDesktopDocumentRef.current?.document
  const isCurrentDocumentInEditing = currentDesktopDocumentRef.current?.documentEditorState.isEditing
  const currentDraft = currentDesktopDocumentRef.current?.documentEditorState.draftRevision

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [internalDocTemplateSelectorDialogData, setInternalDocTemplateSelectorDialogData] =
    useState<IInternalDocTemplateSelectorDialogData>()
  const [internalDocTemplateSelectorDialogOpen, setInternalDocTemplateSelectorDialogOpen] = useState(false)

  useEffect(() => {
    const documentId = state.documentId
    if (!documentId) {
      return
    }
    const desktopDocument = desktop.desktopDocuments.find((p) => p.document.documentId === documentId)
    if (desktopDocument) {
      desktop.setCurrentDesktopDocument(desktopDocument)
    }
  }, [state.documentId])

  function onClickDesktopDocument(desktopDocument: IDesktopDocument) {
    desktop.setCurrentDesktopDocument(desktopDocument)
  }

  async function onRemoveDesktopDocument(desktopDocument: IDesktopDocument) {
    const document = desktopDocument.document
    if (desktop.isDocumentNotSaved(document)) {
      await checkNotSavedChanges([document])
    } else {
      desktop.removeDocument(document)
    }
  }

  async function onRemoveAllDocuments() {
    const desktopDocuments = desktopDocumentsRef.current?.map((p) => p.document) ?? []
    const notSaveDocuments = notSavedDocumentsRef.current ?? []
    const savedDocuments = desktopDocuments.filter(
      (p) => !notSaveDocuments.map((o) => o.documentId).includes(p.documentId)
    )
    savedDocuments.forEach((document) => desktop.removeDocument(document))
    await checkNotSavedChanges(notSaveDocuments)
  }

  async function checkNotSavedChanges(documents: Document[]) {
    for (const document of documents) {
      const documentEditorState = desktop.getDocumentEditorState(document)
      const revision = documentEditorState?.draftRevision
      if (revision) {
        await new Promise<void>((resolve) => {
          setSimpleDialogData({
            title: t(msgIds.MSG_DOX_SELECTOR_DIALOG_TITLE),
            content: t(msgIds.MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY, { name: revision.name }),
            actionsStyle: 'YESnoabort',
            onClose: async (result) => {
              setSimpleDialogOpen(false)
              try {
                if (result.userChoice === 'yes') {
                  await saveDocument(document, revision)
                  enqueueSnackbar(t(msgIds.MSG_DOCUMENT_SAVED_SUCCESSFULLY), { variant: 'success' })
                  desktop.removeDocument(document)
                } else if (result.userChoice === 'no') {
                  desktop.removeDocument(document)
                }
              } catch (err) {
                Utils.enqueueSnackbarError2(err, t)
              }
              resolve() // Risolve promise to go to next document
            },
          })
          setSimpleDialogOpen(true)
        })
      }
    }
  }

  async function saveDocument(document: Document, revision: DocumentRevision) {
    const abortController = new AbortController()
    let savedDocument: Document
    if (document.documentId === 0) {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.createInternalDocument(abortController.signal, document, revision)
          : await dalDocument.createExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    } else {
      savedDocument =
        document.sourceType === ArchiveItemSourceType.internalSource
          ? await dalDocument.updateInternalDocument(abortController.signal, document, revision)
          : await dalDocument.updateExternalDocument(abortController.signal, document, revision, (progress) => {
              log.debug({ downloadDocumentRevision: 'download update progress', progress })
              //args.state.patchUpload(uploadId, { progress })
            })
    }

    document.documentId = savedDocument.documentId
    document.createdAt = savedDocument.createdAt
    document.updatedAt = savedDocument.updatedAt
    const savedRevision =
      revision.revisionId === 0 ? savedDocument.revisions[0] : savedDocument.getRevision(revision?.revisionId)
    if (savedRevision) {
      revision.revisionId = savedRevision.revisionId
      revision.createdAt = savedRevision.createdAt
      revision.updatedAt = savedRevision.updatedAt
    }
  }

  const title =
    isCurrentDocumentInEditing && currentDraft
      ? currentDraft.name || (t(msgIds.MSG_DESKTOP_PAGE_TITLE) as string)
      : currentDocument?.getName() || (t(msgIds.MSG_DESKTOP_PAGE_TITLE) as string)
  const [menuMode, setMenuMode] = useState<DesktopDocumentsMenuMode>('compact')

  const hoverTimer = useRef<any>()
  function onHoverMenu() {
    if (hoverTimer.current) {
      return
    }
    hoverTimer.current = setTimeout(() => {
      setMenuMode('expanded')
      hoverTimer.current = undefined
    }, 300)
  }

  function onLeaveMenu() {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current)
      hoverTimer.current = undefined
    }
    setMenuMode('compact')
  }

  function toggleMenuMode() {
    setMenuMode((m) => (m === 'expanded' ? 'compact' : 'expanded'))
  }

  function onCreateDocumentFromTemplate(template: InternalDocumentTemplate) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const name = createNewUniqueDocumentName()
    const document = Utils.createDocument(ArchiveItemSourceType.internalSource, authContext, t, name, template.content)
    desktop.addDocument(document, { isEditing: true })
  }

  function onCreateDocument(sourceType: ArchiveItemSourceType, file?: DocumentFile) {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const name = createNewUniqueDocumentName()
    const document = Utils.createDocument(sourceType, authContext, t, name)
    if (file && file.data) {
      console.log({ file })
      const revision = document.revisions[0]
      if (revision) {
        revision.content = file.data
        revision.mimetype = file.mimetype
        if (file.checksum) {
          revision.checksum = file.checksum
        }
        revision.name = file.name
        revision.filename = file.name
        revision.size = file.size
      }
    }
    desktop.addDocument(document, { isEditing: true })
  }

  function createNewUniqueDocumentName() {
    const names =
      desktopDocumentsRef.current?.map((desktopDocument) => {
        const draftRevision = desktopDocument?.documentEditorState.draftRevision
        const name = draftRevision ? draftRevision.name : desktopDocument?.document?.getName()
        return name ?? '...'
      }) ?? []
    const name = Utils.computeDefaultDistinctName(t(msgIds.MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME), names)
    return name
  }

  function onChangeDocumentEditorState(key: keyof IDocumentEditorState, value: any) {
    if (!currentDesktopDocumentRef?.current) return
    desktop.updateDocumentEditorState(currentDesktopDocumentRef.current.document, (documentEditorState) => {
      if (typeof value === 'function') {
        return { ...documentEditorState, [key]: value(documentEditorState[key]) }
      }
      return { ...documentEditorState, [key]: value }
    })
  }

  const pageRef = useRef<HTMLDivElement>(null)

  const currentDocumentMarkerWidth = 16
  const menuCollapsedWidth = isMobile ? 0 : 54
  const menuExpandedWidth =
    isMobile && pageRef.current ? pageRef.current.clientWidth - currentDocumentMarkerWidth * 2 : 300
  const paddingLeft = isMobile
    ? currentDocumentMarkerWidth * 2 + 'px'
    : menuCollapsedWidth + currentDocumentMarkerWidth * 2 + 'px'

  const commandBarCommands = useMemo(() => {
    const commands = []
    if (
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates &&
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates?.length > 0
    ) {
      commands.push({
        commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE),
        tooltipText: t(msgIds.MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP),
        icon: <AddDocInternalTemplateIco />,
        onClick: () => {
          createNewDocumentFromTemplate()
        },
      })
    }

    commands.push({
      commandText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
      tooltipText: t(msgIds.MSG_COMMAND_CREATE_DOCUMENT),
      icon: <AddDocInternalIco />,
      onClick: () => {
        onCreateDocument(ArchiveItemSourceType.internalSource)
      },
    })
    commands.push({
      commandText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
      tooltipText: t(msgIds.MSG_COMMAND_UPLOAD_DOCUMENT),
      icon: <AddDocExternalIco />,
      onClick: () => {
        onCreateDocument(ArchiveItemSourceType.externalSource)
      },
    })
    commands.push({
      commandText: t(msgIds.MSG_DESKTOP_CLEAR_TOOLTIP),
      tooltipText: t(msgIds.MSG_DESKTOP_CLEAR_TOOLTIP),
      icon: <ClearDesktopIco />,
      onClick: () => {
        onRemoveAllDocuments()
      },
    })
    return commands
  }, [authContext.loggedProfileId, t])

  function createNewDocumentFromTemplate() {
    if (!authContext.loggedAccount?.canDo(ActionType.createDocuments)) return
    const templates =
      authContext.linkedStructureAccount?.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || []
    if (templates.length > 0) {
      setInternalDocTemplateSelectorDialogOpen(true)
      setInternalDocTemplateSelectorDialogData({
        onResult: (result) => {
          setInternalDocTemplateSelectorDialogOpen(false)
          if (result.userChoice !== 'yes') return
          console.log(result.template)
          const newDocument = Utils.createDocument(
            ArchiveItemSourceType.internalSource,
            authContext,
            t,
            undefined,
            result.template.content
          )
          desktop.addDocument(newDocument, { isEditing: true })
        },
      })
    } else {
      setSimpleDialogOpen(true)
      setSimpleDialogData({
        title: t(msgIds.MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE),
        content: t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY),
        actionsStyle: 'Ok',
        onClose: () => {
          setSimpleDialogOpen(false)
        },
      })
    }
  }

  return (
    <PageContainer>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {internalDocTemplateSelectorDialogData && (
        <InternalDocTemplateSelectorDialog
          {...internalDocTemplateSelectorDialogData}
          isOpen={internalDocTemplateSelectorDialogOpen}
          onClose={() => setInternalDocTemplateSelectorDialogOpen(false)}
        />
      )}

      <Stack direction="row" alignItems="stretch">
        {isMobile && (
          <Center sx={{ width: 54, backgroundColor: theme.palette.grey[400] }}>
            <IconButton onClick={() => toggleMenuMode()} sx={{ flexShrink: 0 }}>
              <SettingsIco />
            </IconButton>
          </Center>
        )}
        <CommandBar style={{ minHeight: 'auto', width: 'auto', flex: 1 }} title={title} commands={commandBarCommands} />
      </Stack>
      <PageContent
        ref={pageRef}
        flexDirection="row"
        justifyContent="stretch"
        alignItems="stretch"
        sx={{ position: 'relative', padding: 0 }}
      >
        <DesktopMenu
          sx={{
            zIndex: 25,
            position: 'absolute',
            height: '100%',
            left: 0,
            top: 0,
            bottom: 0,
          }}
          onMouseEnter={onHoverMenu}
          onMouseLeave={onLeaveMenu}
          menuMode={menuMode}
          collapsedWith={menuCollapsedWidth}
          expandedWith={menuExpandedWidth}
          toggleMenuMode={toggleMenuMode}
          onCreateDocumentFromTemplate={onCreateDocumentFromTemplate}
          onCreateDocument={onCreateDocument}
          onRemoveDesktopDocument={onRemoveDesktopDocument}
          onRemoveAllDocuments={onRemoveAllDocuments}
          onClickDesktopDocument={onClickDesktopDocument}
        />
        <Stack p={2} flex={1} pl={paddingLeft}>
          <Paper sx={{ flex: 1, display: 'flex', maxHeight: '100%' }}>
            {currentDocument && (
              <DocumentEditor
                {...currentDesktopDocumentRef.current.documentEditorState}
                isEditable={true}
                onChangeDocument={(doc) => onChangeDocumentEditorState('document', doc)}
                onChangeIsEditing={(isEditing) => onChangeDocumentEditorState('isEditing', isEditing)}
                onChangeSectionIndex={(index) => onChangeDocumentEditorState('sectionIndex', index)}
                onChangeRevisionId={(revisionId) => onChangeDocumentEditorState('revisionId', revisionId)}
                onChangeDraftRevision={(revision) => onChangeDocumentEditorState('draftRevision', revision)}
                onChangeDraftDocument={(document) => onChangeDocumentEditorState('draftDocument', document)}
                onChangeMarkdownContent={(isMarkdownContentChanged) => {
                  onChangeDocumentEditorState('isMarkdownContentChanged', isMarkdownContentChanged)
                }}
                onChangeMarkdownContentLen={(markdownContentLen) => {
                  onChangeDocumentEditorState('markdownContentLen', markdownContentLen)
                }}
                onChangeInitialCommand={() => {}}
                onCloseDocumentEditor={() => {
                  desktop.removeDocument(currentDocument)
                }}
                onEditedDocumentAssignedDoxChanged={() => {}}
                isDesktop={true}
              />
            )}
          </Paper>
        </Stack>
      </PageContent>
    </PageContainer>
  )
}
