import { useState } from 'react'
import { Skeleton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import msgIds from '../../locales/msgIds'
import { IAccountInfoContactsProps } from './AccountInfoContacts.types'
import FieldsPanel from '../fieldsPanel/FieldsPanel'
import FieldInfo from '../fieldInfo/FieldInfo'
import { StyledToggleButtonDoL, StyledToggleButtonGroupDoL } from '../../shared/StyledControls'
import { StructureIco, UserIco } from '../icons'
import { AccountType, ProfileType } from '../../shared/Constants'

export default function AccountInfoIdentity(props: IAccountInfoContactsProps) {
  const { isLoading, account, showProfileDetailsSelector } = props
  const { i18n, t } = useTranslation()
  const [showOwnedInfo, setShowOwnedInfo] = useState(
    account.user?.accountType === AccountType.placeholderUser ? true : false
  )

  const hasAddress = () => {
    return showOwnedInfo
      ? account.profileDetailsOwnedByOthers?.street ||
          account.profileDetailsOwnedByOthers?.streetNumber ||
          account.profileDetailsOwnedByOthers?.city ||
          account.profileDetailsOwnedByOthers?.province ||
          account.profileDetailsOwnedByOthers?.zip ||
          account.profileDetailsOwnedByOthers?.country
      : account.profileDetailsOwnedBySelf?.street ||
          account.profileDetailsOwnedBySelf?.streetNumber ||
          account.profileDetailsOwnedBySelf?.city ||
          account.profileDetailsOwnedBySelf?.province ||
          account.profileDetailsOwnedBySelf?.zip ||
          account.profileDetailsOwnedBySelf?.country
  }
  const hasPhone = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.phone : account.profileDetailsOwnedBySelf?.phone
  }
  const hasMobilePhone1 = () => {
    return showOwnedInfo
      ? account.profileDetailsOwnedByOthers?.mobilePhone1
      : account.profileDetailsOwnedBySelf?.mobilePhone1
  }
  const hasMobilePhone2 = () => {
    return showOwnedInfo
      ? account.profileDetailsOwnedByOthers?.mobilePhone2
      : account.profileDetailsOwnedBySelf?.mobilePhone2
  }
  const hasWebsite = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.website : account.profileDetailsOwnedBySelf?.website
  }
  const hasEmail1 = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.email1 : account.profileDetailsOwnedBySelf?.email1
  }
  const hasEmail2 = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.email2 : account.profileDetailsOwnedBySelf?.email2
  }
  const hasEmail3 = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.email3 : account.profileDetailsOwnedBySelf?.email3
  }
  const hasEmail4 = () => {
    return showOwnedInfo ? account.profileDetailsOwnedByOthers?.email4 : account.profileDetailsOwnedBySelf?.email4
  }
  const hasPec = () => {
    return showOwnedInfo ? account.userDetails?.pec : account.userDetails?.pec
  }
  const hasAnyField = () => {
    return (
      hasAddress() ||
      hasPhone() ||
      hasMobilePhone1() ||
      hasMobilePhone2() ||
      hasWebsite() ||
      hasEmail1() ||
      hasEmail2() ||
      hasEmail3() ||
      hasEmail4() ||
      hasPec()
    )
  }

  return (
    <>
      {isLoading && (
        <Stack direction="column" alignItems="center" padding={1} spacing={2}>
          <Skeleton variant="rectangular" width={'100%'} height={340} />
        </Stack>
      )}
      {!isLoading && (hasAnyField() || showProfileDetailsSelector) && (
        <Stack direction={'column'} alignItems={'stretch'} padding={1} spacing={3}>
          <FieldsPanel>
            {account.user?.accountType !== AccountType.placeholderUser && (
              <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
                {showProfileDetailsSelector && (
                  <Typography>
                    {showOwnedInfo ? t(msgIds.MSG_DETAILS_OWNED_BY_STRUCTURE) : t(msgIds.MSG_DETAILS_OWNED_BY_CUSTOMER)}
                  </Typography>
                )}
                {showProfileDetailsSelector && (
                  <StyledToggleButtonGroupDoL
                    exclusive
                    value={showOwnedInfo}
                    onChange={(event, newShowOwnedInfo) => {
                      if (newShowOwnedInfo === !showOwnedInfo) {
                        setShowOwnedInfo(newShowOwnedInfo)
                      }
                    }}
                  >
                    <StyledToggleButtonDoL
                      value={true}
                      data-testid="structures-search-button"
                      aria-label={t(msgIds.MSG_STRUCTURE)!}
                    >
                      <StructureIco />
                    </StyledToggleButtonDoL>
                    <StyledToggleButtonDoL
                      value={false}
                      data-testid="customers-search-button"
                      aria-label={t(msgIds.MSG_USER)!}
                    >
                      <UserIco />
                    </StyledToggleButtonDoL>
                  </StyledToggleButtonGroupDoL>
                )}
              </Stack>
            )}

            {hasAddress() && (
              <FieldInfo
                title={t(msgIds.MSG_ADDRESS)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.getAddressInfo(true, true, true, true)
                    : account.profileDetailsOwnedBySelf?.getAddressInfo(true, true, true, true)
                }
              />
            )}

            {hasPhone() && (
              <FieldInfo
                title={t(msgIds.MSG_PHONE)}
                text={
                  showOwnedInfo ? account.profileDetailsOwnedByOthers?.phone : account.profileDetailsOwnedBySelf?.phone
                }
              />
            )}

            {hasMobilePhone1() && (
              <FieldInfo
                title={t(msgIds.MSG_MOBILE1)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.mobilePhone1
                    : account.profileDetailsOwnedBySelf?.mobilePhone1
                }
              />
            )}

            {hasMobilePhone2() && (
              <FieldInfo
                title={t(msgIds.MSG_MOBILE2)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.mobilePhone2
                    : account.profileDetailsOwnedBySelf?.mobilePhone2
                }
              />
            )}

            {hasWebsite() && (
              <FieldInfo
                title={t(msgIds.MSG_WEBSITE)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.website
                    : account.profileDetailsOwnedBySelf?.website
                }
              />
            )}

            {hasEmail1() && (
              <FieldInfo
                title={t(msgIds.MSG_EMAIL1)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.email1
                    : account.profileDetailsOwnedBySelf?.email1
                }
              />
            )}

            {hasEmail2() && (
              <FieldInfo
                title={t(msgIds.MSG_EMAIL2)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.email2
                    : account.profileDetailsOwnedBySelf?.email2
                }
              />
            )}

            {hasEmail3() && (
              <FieldInfo
                title={t(msgIds.MSG_EMAIL3)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.email3
                    : account.profileDetailsOwnedBySelf?.email3
                }
              />
            )}

            {hasEmail4() && (
              <FieldInfo
                title={t(msgIds.MSG_EMAIL4)}
                text={
                  showOwnedInfo
                    ? account.profileDetailsOwnedByOthers?.email4
                    : account.profileDetailsOwnedBySelf?.email4
                }
              />
            )}

            {account.profile?.type === ProfileType.structure && hasPec() && (
              <FieldInfo title={t(msgIds.MSG_PEC)} text={account.userDetails?.pec} />
            )}
          </FieldsPanel>
        </Stack>
      )}
    </>
  )
}
