import { Dox } from '../../models/Dox'
import { ArchiveDoxes } from '../../shared/types/ArchiveDoxes'

export enum DoxSelectorMode {
  selectSingle,
  selectSingleWithRetention,
  selectSingleExceptSelfAndChilds,
  selectSingleWithRetentionExceptSelfAndChilds,
}

export interface IDoxSelectorInProps {
  selectionMode: DoxSelectorMode
  assignableDoxes: ArchiveDoxes
  showRoot?: boolean
  selectedDoxIds?: number[]
  undeterminedDoxIds?: number[]
  disabledDoxId?: number
  visibleDoxIds?: number[]
}

export interface IDoxSelectorOutProps {
  onEditDox: (dox: Dox) => void
  onAddDox: (dox: Dox) => void
  onConfirm: (selectedDoxIds: number[], undeterminedDoxIds: number[]) => void
  onCancel: () => void
  onExpandDox: (dox: Dox, expanded: boolean) => void
}

export interface IDoxSelectorProps extends IDoxSelectorInProps, IDoxSelectorOutProps {}
