import {
  CustomerVisibilityRules,
  CustomerVisibilityRules_all,
  RetentionDuration,
  RetentionDuration_all,
  RetentionEndBehavior,
  RetentionEndBehavior_all,
  TreatmentLegalBase,
  TreatmentLegalBase_all,
} from '../shared/Constants'
import { Utils } from '../shared/Utils'
import { ITreatedDataTypesJson, TreatedDataTypes } from './TreatedDataTypes'

export interface ITreatmentJson {
  id: number
  owner_profile_id: number
  legal_base: number
  retention_duration_base: number
  retention_duration_count: number
  code: string
  short_description: string
  description: string
  retention_notice_days_before_expiration?: number
  retention_end_behavior: number
  is_in_use: boolean
  is_deletable: boolean
  disabled_at: string
  customer_visibility_rules: number
  created_at: string
  updated_at: string
  data_types?: ITreatedDataTypesJson
}

export class Treatment {
  id: number = 0
  ownerProfileId: number = 0
  legalBase: TreatmentLegalBase_all = TreatmentLegalBase.explicitConsent
  retentionDurationBase: RetentionDuration_all = RetentionDuration.none
  retentionDurationCount: number = 0
  code: string = ''
  shortDescription: string = ''
  description: string = ''
  retentionNoticeDaysBeforeExpiration?: number
  retentionEndBehavior: RetentionEndBehavior_all = RetentionEndBehavior.none
  isInUse: boolean = false
  isDeletable: boolean = false
  disabledAt: string = ''
  customerVisibilityRules: CustomerVisibilityRules_all = CustomerVisibilityRules.none
  createdAt: string = ''
  updatedAt: string = ''
  dataTypes?: TreatedDataTypes

  constructor() {}

  public static serialize(obj: Treatment): ITreatmentJson {
    return {
      id: obj.id,
      owner_profile_id: obj.ownerProfileId,
      legal_base: obj.legalBase,
      retention_duration_base: obj.retentionDurationBase,
      retention_duration_count: obj.retentionDurationCount,
      code: obj.code,
      short_description: obj.shortDescription,
      description: obj.description,
      retention_notice_days_before_expiration: obj.retentionNoticeDaysBeforeExpiration,
      retention_end_behavior: obj.retentionEndBehavior,
      is_in_use: obj.isInUse,
      is_deletable: obj.isDeletable,
      disabled_at: obj.disabledAt,
      customer_visibility_rules: obj.customerVisibilityRules,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      data_types: obj.dataTypes ? TreatedDataTypes.serialize(obj.dataTypes) : undefined,
    }
  }

  public static deserialize(json: ITreatmentJson): Treatment {
    const res = new Treatment()
    res.id = json.id
    res.ownerProfileId = json.owner_profile_id
    res.legalBase = json.legal_base
    res.retentionDurationBase = json.retention_duration_base
    res.retentionDurationCount = json.retention_duration_count
    res.code = json.code
    res.shortDescription = json.short_description
    res.description = json.description
    res.retentionNoticeDaysBeforeExpiration = json.retention_notice_days_before_expiration
    res.retentionEndBehavior = json.retention_end_behavior
    res.isInUse = json.is_in_use
    res.isDeletable = json.is_deletable
    res.disabledAt = json.disabled_at
    res.customerVisibilityRules = json.customer_visibility_rules
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    res.dataTypes = json.data_types ? TreatedDataTypes.deserialize(json.data_types) : undefined
    return res
  }

  public static serializeArray(objs: Treatment[]): ITreatmentJson[] {
    const jsons = objs.map((p) => {
      return Treatment.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: ITreatmentJson[]): Treatment[] {
    const res = json.map((p) => {
      return Treatment.deserialize(p)!
    })
    return res
  }

  public computeRetentionEndFrom(startAt: Date) {
    switch (this.retentionDurationBase) {
      case RetentionDuration.day: {
        return Utils.daysFromDate(startAt, this.retentionDurationCount)
      }
      case RetentionDuration.week: {
        return Utils.weeksFromDate(startAt, this.retentionDurationCount)
      }
      case RetentionDuration.month: {
        return Utils.monthsFromDate(startAt, this.retentionDurationCount)
      }
      case RetentionDuration.year: {
        return Utils.yearsFromDate(startAt, this.retentionDurationCount)
      }
      default: {
        return startAt
      }
    }
  }
}
