import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { InputAdornment, TextField, Tooltip } from '@mui/material'
import { IAccountEditorResidenceProps } from './AccountEditorResidence.types'
import { useEffect, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { useLayout } from '../../hooks/Layout'
import { UpdateProfileArgs } from '../../dal/DalAccount'
import * as dalAccount from '../../dal/DalAccount'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { useSnackbar } from 'notistack'
import { useAccountEditorContext } from '../../contexts/AccountEditorContext'
import { AsteriskIco } from '../icons'
import { AccountType, TreatedFieldName } from '../../shared/Constants'
import { SimpleAutocompleteTextField } from '../simpleAutocompleteTextField/SimpleAutocompleteTextField'
import { CountryOption, countryOptions } from '../../shared/types/Country'
import { ProvinceOption, provinceOptions } from '../../shared/types/Province'

export function AccountEditorResidence(props: IAccountEditorResidenceProps): JSX.Element {
  const { account, disabled, isEditMode, setIsLoading } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile } = useLayout()
  const accountEditorContext = useAccountEditorContext()
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateProfileArgs>({})
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)
  const profileId = account.profile?.profileId

  function initFields() {
    const profileDetails =
      account.user?.accountType === AccountType.placeholderUser
        ? account.profileDetailsOwnedByOthers
        : account.profileDetailsOwnedBySelf
    setArgs({
      country: profileDetails?.country,
      city: profileDetails?.city,
      province: profileDetails?.province,
      street: profileDetails?.street,
      streetNumber: profileDetails?.streetNumber,
      zip: profileDetails?.zip,
    })
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    const profileDetails =
      account.user?.accountType === AccountType.placeholderUser
        ? account.profileDetailsOwnedByOthers
        : account.profileDetailsOwnedBySelf
    const check =
      args?.country !== profileDetails?.country ||
      args?.city !== profileDetails?.city ||
      args?.province !== profileDetails?.province ||
      args?.street !== profileDetails?.street ||
      args?.streetNumber !== profileDetails?.streetNumber ||
      args?.zip !== profileDetails?.zip
    setAreUnsavedChanges(check)
  }, [args])

  function validateData(val: UpdateProfileArgs) {
    const errors = {}
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onUpdateProfile() {
    if (!profileId) {
      return
    }

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, { ...args })
      account.profileDetails = updatedAccount.profileDetails
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  function getAdornment(fieldName: string) {
    if (props.mandatoryFieldsNames?.includes(fieldName)) {
      return (
        <InputAdornment position="start">
          <Tooltip title={t(msgIds.MSG_MANDATORY)}>
            <AsteriskIco color="error" />
          </Tooltip>
        </InputAdornment>
      )
    } else if (props.optionalFieldsNames?.includes(fieldName)) {
      return (
        <InputAdornment position="start">
          <Tooltip title={t(msgIds.MSG_OPTIONAL2)}>
            <AsteriskIco color="warning" />
          </Tooltip>
        </InputAdornment>
      )
    }
  }

  function onChangeCountry(country: CountryOption | null) {
    setArgs({ ...args, country: country ? country.label : undefined })
  }
  const country = countryOptions.find((c) => c.label === args.country)

  function onChangeProvince(province: ProvinceOption | null) {
    setArgs({ ...args, province: province ? province.label : undefined })
  }
  const province = provinceOptions.find((c) => c.label === args.province)

  return (
    <EditorFormContainer>
      {accountEditorContext.fieldsAcl.isVisible('residenceCountry') && (
        <SimpleAutocompleteTextField
          error={!!errors.country}
          helperText={t(errors.country)}
          label={t(msgIds.MSG_TREATED_FIELDS_COUNTRY)}
          options={countryOptions}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceCountry') || disabled || !isEditMode}
          value={country || null}
          onChange={onChangeCountry}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('residenceCity') && (
        <TextField
          error={!!errors.city}
          helperText={t(errors.city)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceCity') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_CITY)}
          variant="outlined"
          value={args?.city || ''}
          onChange={(event) => setArgs({ ...args, city: event.target.value })}
          id="outlined-start-adornment"
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.residenceCity),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('residenceProvince') && (
        <SimpleAutocompleteTextField
          error={!!errors.province}
          helperText={t(errors.province)}
          label={t(msgIds.MSG_TREATED_FIELDS_PROVINCE)}
          options={provinceOptions}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceProvince') || disabled || !isEditMode}
          value={province || null}
          onChange={onChangeProvince}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('residenceStreet') && (
        <TextField
          error={!!errors.street}
          helperText={t(errors.street)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceStreet') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_STREET)}
          variant="outlined"
          value={args?.street || ''}
          onChange={(event) => setArgs({ ...args, street: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.residenceStreet),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('residenceStreetNumber') && (
        <TextField
          error={!!errors.streetNumber}
          helperText={t(errors.streetNumber)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceStreetNumber') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_STREET_NUMBER)}
          variant="outlined"
          value={args?.streetNumber || ''}
          onChange={(event) => setArgs({ ...args, streetNumber: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.residenceStreetNumber),
          }}
        />
      )}
      {accountEditorContext.fieldsAcl.isVisible('residenceZip') && (
        <TextField
          error={!!errors.zip}
          helperText={t(errors.zip)}
          disabled={!accountEditorContext.fieldsAcl.isEditable('residenceZip') || disabled || !isEditMode}
          label={t(msgIds.MSG_TREATED_FIELDS_ZIP)}
          variant="outlined"
          value={args?.zip || ''}
          onChange={(event) => setArgs({ ...args, zip: event.target.value })}
          InputProps={{
            endAdornment: getAdornment(TreatedFieldName.residenceZip),
          }}
        />
      )}
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onUpdateProfile} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
