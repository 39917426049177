import { AccountType, AccountType_all } from '../shared/Constants'

export interface IUserJson {
  user_id: number
  res_id: string
  email: string
  account_type: number
  activated_at: string
  last_login_at: string
  has_2fa: boolean
  has_pin: boolean
  default_business_profile: number
  default_consumer_profile: number
  created_at: string
  updated_at: string
}

export class User {
  userId: number = 0
  resId: string = ''
  email: string = ''
  accountType: AccountType_all = AccountType.none
  activatedAt: string = ''
  lastLoginAt: string = ''
  has2fa: boolean = false
  hasPin: boolean = false
  defaultBusinessProfile: number = 0
  defaultConsumerProfile: number = 0
  createdAt: string = ''
  updatedAt: string = ''

  constructor() {}

  public static serialize(obj: User): IUserJson {
    return {
      user_id: obj.userId,
      res_id: obj.resId,
      email: obj.email,
      account_type: obj.accountType,
      activated_at: obj.activatedAt,
      last_login_at: obj.lastLoginAt,
      has_2fa: obj.has2fa,
      has_pin: obj.hasPin,
      default_business_profile: obj.defaultBusinessProfile,
      default_consumer_profile: obj.defaultConsumerProfile,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
    }
  }

  public static deserialize(json: IUserJson): User {
    const res = new User()
    res.userId = json.user_id
    res.resId = json.res_id
    res.email = json.email
    res.accountType = json.account_type
    res.activatedAt = json.activated_at
    res.lastLoginAt = json.last_login_at
    res.has2fa = json.has_2fa
    res.hasPin = json.has_pin
    res.defaultBusinessProfile = json.default_business_profile
    res.defaultConsumerProfile = json.default_consumer_profile
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    return res
  }
}
