import { FormControl, InputLabel, MenuItem, Select, Stack, StackProps } from '@mui/material'
import FromToDatePicker from '../fromToDatePicker/FromToDatePicker'
import { INotificationsFilters, INotificationsFiltersFormProps } from './NotificationsFiltersForm.types'
import { FakeSearchInput } from '../fakeSearchInput/FakeSearchInput'
import { PageStepper } from '../pageStepper/PageStepper'
import { useStackState } from '../../hooks/Stack'
import { PageStep } from '../pageStepper/PageStep'
import { AccountsSearch } from '../accountsSearch/AccountsSearch'
import { AccountSearchTypes, NotificationArgumentType } from '../../shared/Constants'
import { Account } from '../../models/Account'
import { ViewContent } from '../viewContent/ViewContent'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import AccountInfoOperator from '../accountInfoOperator/AccountInfoOperator'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { ViewActions } from '../viewActions/ViewActions'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useEffect, useState } from 'react'

type StepperState = {
  step: 'form' | 'search' | 'account'
  searchOption?: AccountSearchTypes
  account?: Account
}

export function NotificationsFiltersForm(props: INotificationsFiltersFormProps & StackProps): JSX.Element {
  const { filters: filtersProp, onChangeFilters, onCancel, ...rest } = props
  const { t } = useTranslation()
  const stack = useStackState<StepperState>([{ step: 'form' }])
  const [filters, setFilters] = useState<INotificationsFilters>(filtersProp)

  useEffect(() => {
    setFilters(filtersProp)
  }, [filtersProp])

  function onClickSearchStructure() {
    stack.push({ step: 'search', searchOption: 'structures' })
  }

  function onClickSearchOperator() {
    stack.push({ step: 'search', searchOption: 'operators' })
  }

  function onAccountSelected(account: Account) {
    if (stack.head?.searchOption === 'structures') {
      setFilters((filters) => {
        return { ...filters, authorLinkedAccount: account }
      })
    } else {
      setFilters((filters) => {
        return { ...filters, authorAccount: account }
      })
    }
    stack.pop()
  }

  function onClickAvatar(account: Account) {
    stack.push({ step: 'account', account, searchOption: stack.head?.searchOption })
  }

  function onOk() {
    onChangeFilters(filters)
  }

  function onCancelProxy() {
    setFilters(filtersProp)
    onCancel()
  }

  return (
    <>
      <ViewHeader
        title={t(msgIds.MSG_EVENT_HISTORY_FILTERS_FORM_TITLE)}
        backButtonVisible={stack.length > 1}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={onCancelProxy}
      />
      <PageStepper spacing={4} {...rest} activeStep={stack.head?.step}>
        <PageStep step="form">
          <ViewContent>
            <Stack spacing={4}>
              <FromToDatePicker
                justifyContent="space-between"
                fromDate={filters.fromDate}
                onChangeFromDate={(date) =>
                  setFilters((filters) => {
                    return { ...filters, fromDate: date }
                  })
                }
                toDate={filters.toDate}
                onChangeToDate={(date) =>
                  setFilters((filters) => {
                    return { ...filters, toDate: date }
                  })
                }
              />
              <FormControl fullWidth>
                <InputLabel id="argument-selection-label">{t(msgIds.MSG_NOTIFICATION_ARGUMENT_LABEL)}</InputLabel>
                <Select
                  labelId="argument-selection-label"
                  label={t(msgIds.MSG_NOTIFICATION_ARGUMENT_LABEL)}
                  value={filters.argumentType}
                  onChange={(event) =>
                    setFilters((filters) => {
                      return { ...filters, argumentType: event.target.value as NotificationArgumentType }
                    })
                  }
                >
                  <MenuItem value={NotificationArgumentType.none}>{t(msgIds.MSG_NOTIFICATION_ARGUMENT_NONE)}</MenuItem>
                  <MenuItem value={NotificationArgumentType.message}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_MESSAGE)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.profileManagement}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.accountManagement}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.pdxContract}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.dossier}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOSSIER)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.document}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.privacySettings}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.privacyPolicy}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.archiveManagement}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT)}
                  </MenuItem>
                  <MenuItem value={NotificationArgumentType.invitation}>
                    {t(msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION)}
                  </MenuItem>
                </Select>
              </FormControl>
              <FakeSearchInput
                onClickSearch={onClickSearchStructure}
                onClickClear={() =>
                  setFilters((filters) => {
                    return { ...filters, authorLinkedAccount: undefined }
                  })
                }
                value={filters.authorLinkedAccount?.getIdentity() || ''}
              />
              <FakeSearchInput
                onClickSearch={onClickSearchOperator}
                onClickClear={() =>
                  setFilters((filters) => {
                    return { ...filters, authorAccount: undefined }
                  })
                }
                value={filters.authorAccount?.getIdentity() || ''}
              />
            </Stack>
          </ViewContent>
          <ViewActions>
            <ViewActionsButton defaultAction onClick={onOk}>
              {t(msgIds.MSG_OK)}
            </ViewActionsButton>
            <ViewActionsButton onClick={onCancelProxy}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
          </ViewActions>
        </PageStep>
        <PageStep step="search">
          {stack.head?.searchOption && (
            <AccountsSearch
              searchOption={stack.head.searchOption}
              onCancel={stack.pop}
              onAccountSelected={onAccountSelected}
              onClickAvatar={onClickAvatar}
              goToAccountPageOption={false}
              showPrivacyPolicy={false}
            />
          )}
        </PageStep>
        <PageStep step="account">
          {stack.head?.account &&
            stack.head.searchOption &&
            (stack.head.searchOption === 'structures' ? (
              <AccountInfoStructure account={stack.head.account} />
            ) : (
              <AccountInfoOperator account={stack.head.account} />
            ))}
        </PageStep>
      </PageStepper>
    </>
  )
}
