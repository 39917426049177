import { Stack } from '@mui/material'
import { IContractVersionViewerProps } from './ContractVersionViewer.types'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { useLayout } from '../../hooks/Layout'
import { useContractVersionViewerHook } from './ContractVersionViewerHook'
import { ContractVersionViewerData } from '../contractVersionViewerData/ContractVersionViewerData'

const ContractVersionViewer = forwardRef((props: IContractVersionViewerProps, ref) => {
  const { t } = useTranslation()
  const { isMobile } = useLayout()
  const {
    consentsSnapshot,
    missingInformations,
    isNewAcknoledgmentRequired,
    acceptedSections,
    editableSections,
    editableSectionsFields,
    contractType,
    availableMailFields,
    availableMobilePhoneFields,
    onChangeFieldOption,
    onChange,
    onSave,
    onCancel,
    isLoading,
    simpleDialogOpen,
    simpleDialogData,
    areUnsavedChanges,
    onGrantorInfoUpdated,
  } = useContractVersionViewerHook(props)

  const internalRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => ({
    onGrantorInfoUpdated,
  }))

  return (
    <Stack ref={internalRef} gap={4} padding={isMobile ? 2 : 4}>
      <ContractVersionViewerData
        disablePadding={props.disablePadding}
        consentsSnapshot={consentsSnapshot}
        missingInformations={missingInformations}
        isNewAcknoledgmentRequired={isNewAcknoledgmentRequired}
        acceptedSections={acceptedSections}
        editableSections={editableSections}
        editableSectionsFields={editableSectionsFields}
        contractType={contractType}
        availableMobilePhoneFields={availableMobilePhoneFields}
        availableMailFields={availableMailFields}
        onChangeFieldOption={onChangeFieldOption}
        onAddMissingInfo={props.onAddMissingInfo}
        onChange={onChange}
        isLoading={isLoading}
        simpleDialogData={simpleDialogData}
        simpleDialogOpen={simpleDialogOpen}
        showMissingInfoFields={true}
      />

      <ViewActions disablePadding={true}>
        <ViewActionsButton onClick={onSave} defaultAction disabled={!areUnsavedChanges}>
          {t(msgIds.MSG_SAVE)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onCancel} disabled={!areUnsavedChanges}>
          {t(msgIds.MSG_CANCEL)}
        </ViewActionsButton>
      </ViewActions>
    </Stack>
  )
})

export default ContractVersionViewer
