import { Stack, StackProps } from '@mui/material'
import { useStackState } from '../../hooks/Stack'
import { PageStepper } from '../pageStepper/PageStepper'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { PageStep } from '../pageStepper/PageStep'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { Account } from '../../models/Account'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import { ContractType, ProfileType } from '../../shared/Constants'
import AccountInfoCustomer from '../accountInfoCustomer/AccountInfoCustomer'
import AccountInfoOperator from '../accountInfoOperator/AccountInfoOperator'
import { IEventHistoryStepperProps } from './EventHistoryStepper.types'
import { EventHistoryDetails } from '../eventHistoryDetails/EventHistoryDetails'
import { ViewContent } from '../viewContent/ViewContent'
import { DoxDetails } from '../doxDetails/DoxDetails'
import { Dox } from '../../models/Dox'
import { Treatment } from '../../models/Treatment'
import { useChange } from '../../hooks/Change'
import { History } from '../../models/History'
import DocumentEditor from '../documentEditor/DocumentEditor'
import { Document } from '../../models/Document'
import { useState } from 'react'
import ContractVersionViewer from '../contractVersionViewer/ContractVersionViewer'
import { ContractVersion } from '../../models/ContractVersion'
import { useAuthContext } from '../../contexts/AuthContext'
import { AccountEditorContextProvider } from '../../contexts/AccountEditorContext'
import { ProfileInformations } from '../profileInformations/ProfileInformations'

type StepperState = {
  step: any
  history?: History
  account?: Account
  dox?: Dox
  treatment?: Treatment
  document?: Document
  revisionId?: number
  informationsProfileId?: number
  informationsOwnerProfileId?: number
  mandatoryFieldsNames?: string[]
  optionalFieldsNames?: string[]
  contractVersion?: ContractVersion
  contractType?: ContractType
  targetProfileType?: ProfileType
  contractOwnerProfileId?: number
}

export function EventHistoryStepper(props: IEventHistoryStepperProps & StackProps): JSX.Element {
  const { history, setMaxWidth, onClickExit, ...rest } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const stack = useStackState<StepperState>([])
  const [sectionIndex, setSectionIndex] = useState(0)

  useChange(() => {
    setMaxWidth('sm')
    stack.setItems([{ step: 'history_details', history: history }])
  }, [history])

  function onClickBack() {
    setMaxWidth('sm')
    stack.pop()
  }

  function onClickAvatar(account: Account) {
    setMaxWidth('sm')
    stack.push({ step: 'account', account })
  }

  function onShowDoxDetails(dox: Dox, treatment?: Treatment) {
    setMaxWidth('sm')
    stack.push({ step: 'dox_details', dox, treatment })
  }

  function onShowDocumentDetails(document: Document, revisionId?: number) {
    setMaxWidth('xl')
    stack.push({ step: 'document_details', document, revisionId })
  }

  function onChangeDocumentRevisionId(revisionId: number) {
    stack.push({ ...stack.head, revisionId } as StepperState)
  }

  function onShowContractVersion(
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    contractVersion: ContractVersion
  ) {
    setMaxWidth('md')
    stack.push({
      step: 'contract_version',
      contractOwnerProfileId,
      contractType,
      targetProfileType,
      contractVersion,
    })
  }

  function onAddMissingInfo(ownerProfileId: number, mandatoryFieldsNames: string[], optionalFieldsNames: string[]) {
    stack.push({
      step: 'profile_informations',
      informationsProfileId: authContext.loggedAccount?.profile?.profileId,
      informationsOwnerProfileId: ownerProfileId,
      mandatoryFieldsNames: mandatoryFieldsNames,
      optionalFieldsNames: optionalFieldsNames,
    })
  }

  return (
    <PageStepper {...rest} activeStep={stack.head?.step}>
      <ViewHeader
        title={t(msgIds.MSG_HISTORY_DIALOG_DETAILS_TITLE)}
        backButtonVisible={stack.length > 1}
        onClickBack={onClickBack}
        exitButtonVisible={true}
        onClickExit={onClickExit}
      />
      <PageStep step="history_details">
        <ViewContent overflow={'auto'}>
          {stack.head?.history && (
            <EventHistoryDetails
              history={stack.head.history}
              onShowDoxDetails={onShowDoxDetails}
              onShowDocumentDetails={onShowDocumentDetails}
              onShowContractVersion={onShowContractVersion}
              onClickAvatar={onClickAvatar}
              onCloseDialog={onClickExit}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="dox_details">
        <ViewContent display="flex" flexDirection="column" disablePadding={false} overflow={'auto'}>
          {stack.head?.dox && <DoxDetails dox={stack.head.dox} treatment={stack.head.treatment} />}
        </ViewContent>
      </PageStep>
      <PageStep step="document_details">
        <ViewContent display="flex" flexDirection="column" disablePadding={false} overflow={'auto'} height={700}>
          {stack.head?.document && (
            <DocumentEditor
              document={stack.head?.document}
              isEditing={false}
              sectionIndex={sectionIndex}
              isEditable={false}
              revisionId={stack.head?.revisionId ?? 0}
              isMarkdownContentChanged={false}
              markdownContentLen={0}
              onChangeDocument={() => {}}
              onChangeIsEditing={() => {}}
              onChangeSectionIndex={setSectionIndex}
              onChangeRevisionId={onChangeDocumentRevisionId}
              onChangeDraftRevision={() => {}}
              onChangeDraftDocument={() => {}}
              onChangeMarkdownContent={() => {}}
              onChangeMarkdownContentLen={() => {}}
              onChangeInitialCommand={() => {}}
              onCloseDocumentEditor={() => {}}
              onEditedDocumentAssignedDoxChanged={() => {}}
              isDesktop={false}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="contract_version">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.contractType && stack.head?.targetProfileType && stack.head?.contractVersion && (
            <ContractVersionViewer
              isEditable={false}
              sectionsEditability={
                stack.head?.contractOwnerProfileId === 0 ? 'allExceptMandatory' : 'allExceptNotRevocable'
              }
              contractOwnerProfileId={stack.head?.contractOwnerProfileId}
              contractType={stack.head?.contractType}
              targetProfileType={stack.head?.targetProfileType}
              consentsSnapshot={stack.head?.contractVersion}
              onAddMissingInfo={(mandatoryFieldsNames, optionalFieldsNames) => {
                if (!stack.head?.contractOwnerProfileId) return
                onAddMissingInfo(stack.head?.contractOwnerProfileId, mandatoryFieldsNames, optionalFieldsNames)
              }}
              disablePadding={true}
              onSaved={() => {
                stack.pop()
              }}
              onCanceled={() => {
                stack.pop()
              }}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="profile_informations">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.informationsProfileId && (
            <AccountEditorContextProvider>
              <ProfileInformations
                isInDialog={true}
                profileId={stack.head?.informationsProfileId}
                ownerProfileId={stack.head?.informationsOwnerProfileId}
                mandatoryFieldsNames={stack.head?.mandatoryFieldsNames}
                optionalFieldsNames={stack.head?.optionalFieldsNames}
              />
            </AccountEditorContextProvider>
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="account">
        <Stack>
          {stack.head?.account &&
            (stack.head.account.profile?.type === ProfileType.customer ? (
              <AccountInfoCustomer account={stack.head.account} />
            ) : stack.head.account?.profile?.type === ProfileType.structure ? (
              <AccountInfoStructure account={stack.head.account} />
            ) : (
              <AccountInfoOperator account={stack.head.account} />
            ))}
        </Stack>
      </PageStep>
    </PageStepper>
  )
}
