import { Stack, Typography } from '@mui/material'
import { AccountType, ProfileType, isConsumer, isOperator, isStructure } from '../../shared/Constants'
import { IAccountIdentityProps } from './AccountIdentity.types'
import { useTranslation } from 'react-i18next'

import msgIds from '../../locales/msgIds'
import { Utils } from '../../shared/Utils'
import AvatarImage from '../avatarImage/AvatarImage'

export default function AccountIdentity(props: IAccountIdentityProps) {
  const { account, infoToShow, showProfileInfo, showProfileDsc, avatarClicked } = props
  const { t, i18n } = useTranslation()

  const getProfileDsc = () => {
    if (infoToShow.includes('main') && account && account.profile)
      switch (account.profile.type) {
        case ProfileType.customer: {
          return (
            <>
              <i>{t(msgIds.MSG_PRIVATE_USER)}</i>
            </>
          )
        }
        case ProfileType.operatorAdmin: {
          return (
            <>
              {account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_ADMIN_LO)})`}</i>
            </>
          )
        }
        case ProfileType.operatorInt: {
          return (
            <>
              {account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_INTERNAL_LO)})`}</i>
            </>
          )
        }
        case ProfileType.operatorExt: {
          return (
            <>
              {account?.linkedAccount?.getIdentityInverse()}
              <i>{` (${t(msgIds.MSG_OPERATOR)} - ${t(msgIds.MSG_EXTERNAL_LO)})`}</i>
            </>
          )
        }
        case ProfileType.structure: {
          return (
            <>
              <i>{t(msgIds.MSG_ORGANIZATION)}</i>
            </>
          )
        }
        default:
          return ''
      }
  }

  const linkedAccount = account.linkedAccount
  const relatedAccount = account.getRelatedAccount()

  return (
    <Stack direction={'row'} sx={{ marginLeft: 1 }}>
      <AvatarImage
        identity={account.getIdentityInverse()}
        initials={account.getInitials()}
        image={account.profile?.avatarImage}
        accountType={account.user?.accountType || AccountType.none}
        avatarClicked={() => avatarClicked(account)}
        sx={{ alignSelf: 'start' }}
      />

      {infoToShow.includes('linked') && linkedAccount && (
        <AvatarImage
          identity={account.getIdentityInverse()}
          initials={linkedAccount.getInitials()}
          image={linkedAccount.profile?.avatarImage}
          accountType={linkedAccount.user?.accountType || AccountType.none}
          avatarClicked={() => avatarClicked(linkedAccount)}
          sx={{ alignSelf: 'start' }}
        />
      )}

      {infoToShow.includes('principal') && relatedAccount && (
        <AvatarImage
          identity={account.getIdentityInverse()}
          initials={relatedAccount.getInitials()}
          image={relatedAccount.profile?.avatarImage}
          accountType={relatedAccount.user?.accountType || AccountType.none}
          avatarClicked={() => avatarClicked(relatedAccount)}
          sx={{ alignSelf: 'start' }}
        />
      )}

      <Stack direction={'column'} sx={{ marginLeft: 1 }}>
        <Typography variant="body2">
          <b>{account.getIdentityInverse()}</b>
        </Typography>
        {showProfileInfo && isConsumer(account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {t(msgIds.MSG_BORN_ON) +
              ': ' +
              (account.userDetails?.birthdate ? Utils.toLocaleDateString(account.userDetails?.birthdate, i18n) : '')}
          </Typography>
        )}
        {showProfileInfo && isOperator(account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {`${account?.linkedAccount?.getIdentityInverse() ?? ''} / ${
              account.profileDetailsOwnedBySelf?.phone ??
              account.profileDetailsOwnedByOthers?.phone ??
              account.profileDetailsOwnedBySelf?.mobilePhone1 ??
              account.profileDetailsOwnedByOthers?.mobilePhone1 ??
              account.profileDetailsOwnedBySelf?.mobilePhone2 ??
              account.profileDetailsOwnedByOthers?.mobilePhone2 ??
              ''
            }`}
          </Typography>
        )}
        {showProfileInfo && isStructure(account.profile?.type) && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {account.profileDetailsOwnedBySelf?.getCityInfo() ?? ''}
          </Typography>
        )}
        {showProfileDsc && (
          <Typography sx={{ fontSize: 11 }} variant="body2">
            {getProfileDsc()}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
