import { ActionType, ContractVersionState, ContractVersionState_all } from '../shared/Constants'
import { ContractSection, IContractSectionJson } from './ContractSection'

export interface IContractVersionJson {
  id: number
  contract_id: number
  major_version: number
  minor_version: number
  state: number
  published_at: string
  notes: string
  is_new_acknowledgment_required: boolean
  are_new_consents_required: boolean
  avoid_new_acknowledgment_explanation: string
  avoid_new_consents_explanation: string
  created_at: string
  updated_at: string
  sections: IContractSectionJson[]
  date?: string
}

export class ContractVersion {
  id: number = 0
  contractId: number = 0
  majorVersion: number = 0
  minorVersion: number = 0
  state: ContractVersionState_all = ContractVersionState.none
  publishedAt: string = ''
  notes: string = ''
  isNewAcknowledgmentRequired: boolean = false
  areNewConsentsRequired: boolean = false
  avoidNewAcknowledgmentExplanation: string = ''
  avoidNewConsentsExplanation: string = ''
  createdAt: string = ''
  updatedAt: string = ''
  sections: ContractSection[] = []
  consentsDate?: Date

  _grantedConsentsDateTime: Date | undefined
  get grantedConsentsDateTime(): Date | undefined {
    let latestDate: Date | undefined
    this.sections.forEach((section) => {
      if (section.consent) {
        const date = section.consent.createdAt
        if (!latestDate || date > latestDate) {
          latestDate = date
        }
      }
    })
    return latestDate
  }

  _revokedConsentsDateTime: Date | undefined
  get revokedConsentsDateTime(): Date | undefined {
    let latestDate: Date | undefined
    this.sections.forEach((section) => {
      if (section.consent) {
        const date = section.consent.revokedAt
        if (!latestDate || (date && date > latestDate)) {
          latestDate = date
        }
      }
    })
    return latestDate
  }

  constructor() {}

  public static serialize(obj: ContractVersion): IContractVersionJson {
    return {
      id: obj.id,
      contract_id: obj.contractId,
      major_version: obj.majorVersion,
      minor_version: obj.minorVersion,
      state: obj.state,
      published_at: obj.publishedAt,
      notes: obj.notes,
      is_new_acknowledgment_required: obj.isNewAcknowledgmentRequired,
      are_new_consents_required: obj.areNewConsentsRequired,
      avoid_new_acknowledgment_explanation: obj.avoidNewAcknowledgmentExplanation,
      avoid_new_consents_explanation: obj.avoidNewConsentsExplanation,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      sections: ContractSection.serializeArray(obj.sections),
      date: obj.consentsDate?.toISOString(),
    }
  }

  public static deserialize(json: IContractVersionJson): ContractVersion {
    const res = new ContractVersion()
    res.id = json.id
    res.contractId = json.contract_id
    res.majorVersion = json.major_version
    res.minorVersion = json.minor_version
    res.state = json.state
    res.publishedAt = json.published_at
    res.notes = json.notes
    res.isNewAcknowledgmentRequired = json.is_new_acknowledgment_required
    res.areNewConsentsRequired = json.are_new_consents_required
    res.avoidNewAcknowledgmentExplanation = json.avoid_new_acknowledgment_explanation
    res.avoidNewConsentsExplanation = json.avoid_new_consents_explanation
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    res.sections = json.sections ? ContractSection.deserializeArray(json.sections) : []
    res.consentsDate = json.date ? new Date(json.date) : undefined
    return res
  }

  public static serializeArray(objs: ContractVersion[]): IContractVersionJson[] {
    const jsons = objs.map((p) => {
      return ContractVersion.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IContractVersionJson[]): ContractVersion[] {
    const res = json.map((p) => {
      return ContractVersion.deserialize(p)!
    })
    return res
  }

  public getVersionString() {
    return `${this.majorVersion}.${this.minorVersion}`
  }

  public getDataProcessingSection() {
    const section =
      this.sections?.length > 0
        ? this.sections.find((p) => p.actions.findIndex((o) => o.action === ActionType.dataProcessing) !== -1)
        : undefined
    return section
  }

  public static sortByPublishedAt(versions: ContractVersion[]) {
    return versions.sort((a, b) => {
      if (a.publishedAt === undefined && b.publishedAt === undefined) {
        return 0 // no specific order
      }
      if (a.publishedAt === undefined) {
        return 1 // put a at bottom
      }
      if (b.publishedAt === undefined) {
        return -1 // put b at bottom
      }
      // order by most recent
      return new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    })
  }

  private findLatestConsentDate(calculateRevoked: boolean) {
    let latestConsentDate: Date | undefined
    this.sections.forEach((section) => {
      if (section.consent) {
        const date = calculateRevoked ? section.consent.revokedAt : section.consent.createdAt
        if (!latestConsentDate || (date && date > latestConsentDate)) {
          latestConsentDate = date
        }
      }
    })
    return latestConsentDate || new Date(0)
  }

  public static compareConsentsDateTime(a: ContractVersion, b: ContractVersion) {
    if (a.consentsDate && b.consentsDate) {
      const diff = new Date(b.consentsDate).getTime() - new Date(a.consentsDate).getTime()
      if (diff === 0) {
        const latestGrantedConsentDateA = a.findLatestConsentDate(false).getTime()
        const latestGrantedConsentDateB = b.findLatestConsentDate(false).getTime()
        const latestRevokedConsentDateA = a.findLatestConsentDate(true).getTime()
        const latestRevokedConsentDateB = b.findLatestConsentDate(true).getTime()
        return latestGrantedConsentDateB - latestGrantedConsentDateA !== 0
          ? latestGrantedConsentDateB - latestGrantedConsentDateA
          : latestRevokedConsentDateB - latestRevokedConsentDateA
      } else {
        return diff
      }
    } else {
      // If either date is missing, place the object with the missing date at the end
      return a.consentsDate ? -1 : 1
    }
  }
}
