import { useEffect, useMemo, useState } from 'react'
import { IContractSectionEditorProps } from './ContractSectionEditor.types'
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import msgIds from '../../locales/msgIds'
import * as dalContractSection from '../../dal/DalContractSection'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { InfoIco } from '../icons'
import { UpdateContractSectionArgs } from '../../dal/DalContractSection'
import { Treatment } from '../../models/Treatment'
import { TreatmentLegalBase } from '../../shared/Constants'
import { ITreatmentListDialogData } from '../../dialogs/treatmentListDialog/TreatmentListDialog.types'
import TreatmentListDialog from '../../dialogs/treatmentListDialog/TreatmentListDialog'
import { TreatmentsAccordion } from '../treatmentsAccordion/TreatmentsAccordion'
import MarkdownViewer from '../markdownEditor/MarkdownViewer'
import MarkdownEditor from '../markdownEditor/MarkdownEditor'

export default function ContractSectionEditor(props: IContractSectionEditorProps) {
  const { disabled, isEditMode, contractSection, contractId, treatments, isAuthorizedToEdit, setIsLoading } = props
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState<any>({})
  const [args, setArgs] = useState<UpdateContractSectionArgs>({})
  const [assignedTreatments, setAssignedTreatments] = useState<Treatment[]>([])
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false)

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [treatmentListDialogData, setTreatmentListDialogData] = useState<ITreatmentListDialogData>()
  const [treatmentListDialogOpen, setTreatmentListDialogOpen] = useState(false)

  const availableTreatments: Treatment[] = useMemo(() => {
    const assignedTreatmentsIds = assignedTreatments.map((p) => p.id)
    const otherSectionsTreatmentsIds = props.getOtherSectionsTreatmentsIds(contractSection.id)
    if (contractSection.isDataProcessingSection) {
      const _avialableTreatments =
        treatments?.filter(
          (t) =>
            t.legalBase !== TreatmentLegalBase.explicitConsent &&
            assignedTreatmentsIds?.indexOf(t.id) === -1 &&
            otherSectionsTreatmentsIds.indexOf(t.id) === -1
        ) ?? []
      return _avialableTreatments
    } else {
      const _avialableTreatments =
        treatments?.filter(
          (t) =>
            t.legalBase === TreatmentLegalBase.explicitConsent &&
            assignedTreatmentsIds?.indexOf(t.id) === -1 &&
            otherSectionsTreatmentsIds.indexOf(t.id) === -1
        ) ?? []
      return _avialableTreatments
    }
  }, [treatments, assignedTreatments])

  function initFields() {
    setArgs({
      text: contractSection.text,
      beforeOf: contractSection.beforeOf,
      consentMode: contractSection.consentMode,
      actions: _.cloneDeep(contractSection.actions),
    })
    const treatmentIds = contractSection.treatments.map((o) => o.id)
    setAssignedTreatments(treatments?.filter((p) => treatmentIds.includes(p.id)) ?? [])
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractSection])

  useEffect(() => {
    const arr1 = contractSection.treatments.map((p) => p.id)
    const arr2 = assignedTreatments.map((p) => p.id)
    const arr3 = Utils.getArrayIntersection(arr1, arr2)
    const areAssignedTreatmentsChanged = arr1.length !== arr3.length || arr2.length !== arr3.length

    const check =
      args?.text !== contractSection?.text ||
      args?.beforeOf !== contractSection?.beforeOf ||
      args?.consentMode !== contractSection?.consentMode ||
      JSON.stringify(args?.actions) !== JSON.stringify(contractSection.actions) ||
      areAssignedTreatmentsChanged

    setAreUnsavedChanges(check)
  }, [args, assignedTreatments])

  const showInfo = () => {
    setSimpleDialogOpen(true)
    setSimpleDialogData({
      title: t(msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO),
      content: t(msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_TEXT),
      actionsStyle: 'Ok',
      onClose: async (result) => {
        setSimpleDialogOpen(false)
      },
    })
  }

  async function AddTreatment() {
    setTreatmentListDialogOpen(true)
    setTreatmentListDialogData({
      treatments: availableTreatments,
      onResult: async (result) => {
        setTreatmentListDialogOpen(false)
        if (result.userChoice !== 'yes') return
        if (!result.treatment) return
        const selectedTreatment = result.treatment
        setAssignedTreatments((at) => [...at, selectedTreatment])
      },
    })
  }
  async function RemoveTreatment(treatment: Treatment) {
    setAssignedTreatments((at) => [...at.filter((p) => p.id !== treatment.id)])
  }

  function validateData(val: UpdateContractSectionArgs) {
    const errors = {}
    if (Object.values(errors).find((e) => !!e)) {
      return errors
    } else {
      return null
    }
  }

  async function onSave() {
    if (!args) return

    const errors = validateData(args)
    setErrors(errors || {})
    if (errors) {
      enqueueSnackbar(t(msgIds.MSG_VAL_ERR_THERE_ARE_FORM_ERRORS), { variant: 'error' })
      return
    }

    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const oldTreatments = contractSection.treatments.map((p) => p.id)
      const newTreatments = assignedTreatments.map((p) => p.id)
      const treatmentsToAdd = _.difference(newTreatments, oldTreatments)
      const treatmentsToRemove = _.difference(oldTreatments, newTreatments)
      await dalContractSection.updateContractSectionTreatments(
        abortController.signal,
        contractId,
        contractSection.id,
        treatmentsToAdd,
        treatmentsToRemove
      )
      contractSection.treatments = assignedTreatments

      const updatedSection = await dalContractSection.updateContractSection(
        abortController.signal,
        contractId,
        contractSection.id,
        args
      )
      contractSection.text = updatedSection.text
      setAreUnsavedChanges(false)
      props.onSave && props.onSave()
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onCancel() {
    initFields()
    setErrors({})
    props.onCancel && props.onCancel()
  }

  const isDisabled = disabled || !(isEditMode && isAuthorizedToEdit)

  return (
    <EditorFormContainer pt={0}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {treatmentListDialogData && (
        <TreatmentListDialog
          {...treatmentListDialogData}
          isOpen={treatmentListDialogOpen}
          onClose={() => setTreatmentListDialogOpen(false)}
        />
      )}

      <Stack spacing={1}>
        {isEditMode && contractSection.isDataProcessingSection && (
          <Stack direction={'row'} alignItems="center" gap={1}>
            <Typography>{t(msgIds.MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO)}</Typography>
            <IconButton onClick={showInfo} size="small">
              <InfoIco />
            </IconButton>
          </Stack>
        )}

        <TreatmentsAccordion
          treatments={assignedTreatments}
          editMode={isEditMode}
          onRemoveTreatment={RemoveTreatment}
        />

        <Divider sx={{ my: 2 }} />

        {!isDisabled &&
          (contractSection.isDataProcessingSection ||
            (!contractSection.isDataProcessingSection && contractSection.treatments.length === 0)) && (
            <Button
              sx={{ mb: 2, alignSelf: 'start' }}
              variant="contained"
              disabled={isDisabled}
              onClick={() => AddTreatment()}
            >
              {t(msgIds.MSG_CONTRACT_ADD_DATA_TREATMENT)}
            </Button>
          )}
        {isDisabled ? (
          <MarkdownViewer initialValue={contractSection.text || ''} />
        ) : (
          <MarkdownEditor
            initialValue={contractSection.text || ''}
            onContentChanged={(md, isChanged, len) => setArgs({ ...args, text: md })}
            height={'700px'}
          />
        )}
      </Stack>

      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!areUnsavedChanges}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
