import { useCallback, useMemo, useState } from 'react'

export function useStackState<T>(initValue?: T[]) {
  const [stack, setStack] = useState<T[]>(initValue || [])
  const head = useMemo(() => {
    if (stack.length === 0) {
      return null
    }
    return stack[0]
  }, [stack])

  const push = useCallback(
    (step: T) => {
      setStack((stack) => {
        return [step, ...stack]
      })
    },
    [setStack]
  )

  const pop = useCallback(() => {
    if (stack.length === 0) {
      return null
    }
    const step = stack[0]
    const newStack = [...stack]
    newStack.splice(0, 1)
    setStack(newStack)
    return step
  }, [stack, setStack])

  const popToRoot = useCallback(() => {
    if (stack.length === 0) {
      return null
    }
    const step = stack[0]
    setStack([step])
    return step
  }, [stack, setStack])

  const set = useCallback(
    (step: T) => {
      setStack([step])
    },
    [setStack]
  )

  return { head, push, pop, popToRoot, set, length: stack.length, setItems: setStack }
}
