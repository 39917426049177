import { Button } from '@mui/material'
import { ViewContent } from '../../components/viewContent/ViewContent'
import { ViewHeader } from '../../components/viewHeader/ViewHeader'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { StyledDialog } from '../styledDialog/StyledDialog'
import { IContractsUpdateWarningDialogProps } from './ContractsUpdateWarningDialog.types'
import MarkdownViewer from '../../components/markdownEditor/MarkdownViewer'
import { useEffect, useState } from 'react'
import { ContractAcceptanceStatus, ContractType, RegistrationPurpose } from '../../shared/Constants'
import { useNavigate } from 'react-router'
import { Utils } from '../../shared/Utils'
import { IRegistrationPageProps } from '../../pages/RegistrationPage/RegistrationPage.types'
import { useAuthContext } from '../../contexts/AuthContext'

export function ContractsUpdateWarningDialog(props: IContractsUpdateWarningDialogProps): JSX.Element {
  const { accountLegalStatus, profileType } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authContext = useAuthContext()

  const [isLoading, setIsLoading] = useState(false)
  const [msg, setMsg] = useState('')
  const [isGoToDashboardCmdVisible, setIsGoToDashboardCmdVisible] = useState(false)
  const [isGoToDashboardAndMutesTheMsgCmdVisible, setIsGoToDashboardAndMutesTheMsgCmdVisible] = useState(false)
  const [isShowNewContractVersionsCmdVisible, setIsShowNewContractVersionsCmdVisible] = useState(false)

  function onClose() {
    props.onClose({ userChoice: 'abort' })
  }

  useEffect(() => {
    checkAccountLegalStatus()
  }, [accountLegalStatus])

  const checkAccountLegalStatus = () => {
    if (accountLegalStatus) {
      let msgTmp = ''

      const contractTypesToAccept = accountLegalStatus.getContracts(ContractAcceptanceStatus.toAccept)
      msgTmp += getMsgForStatus(contractTypesToAccept, t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_HEAVY_CHANGES))

      const contractTypesToOptionalConsents = accountLegalStatus.getContracts(
        ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
      )
      msgTmp += getMsgForStatus(
        contractTypesToOptionalConsents,
        t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_OPTIONAL_CONSENTS_REQUESTED)
      )

      const contractTypesToConfirm = accountLegalStatus.getContracts(
        ContractAcceptanceStatus.implicityAcceptedButNotConfirmed
      )
      msgTmp += getMsgForStatus(contractTypesToConfirm, t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_LIGHT_CHANGES))

      if (contractTypesToAccept.length > 0 || contractTypesToOptionalConsents.length > 0) {
        msgTmp += t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_REDIRECT_TO_CONTRACTS)
        setIsShowNewContractVersionsCmdVisible(true)
      } else if (contractTypesToConfirm.length > 0) {
        msgTmp += t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_CONSENTS_VALID)
        setIsGoToDashboardCmdVisible(true)
        setIsGoToDashboardAndMutesTheMsgCmdVisible(true)
        setIsShowNewContractVersionsCmdVisible(true)
      }

      setMsg(msgTmp)
    }
  }

  function getMsgForStatus(contractTypes: ContractType[], intro: string) {
    let msg = ''
    if (contractTypes.length > 0) {
      msg = intro
      msg += getContractTypesDsc(contractTypes)
      msg += '\n\n'
    }
    return msg
  }

  function getContractTypesDsc(contractTypes: ContractType[]) {
    let msg = ''
    contractTypes.forEach((contractType) => {
      switch (contractType) {
        case ContractType.privacyPolicy: {
          msg += t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_PRIVACY_POLICY)
          break
        }
        case ContractType.termsOfUse: {
          msg += t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_TERMS_OF_USE)
          break
        }
        case ContractType.dataProcessor: {
          msg += t(msgIds.MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_DATA_PROCESSOR)
          break
        }
      }
    })
    msg += '\n\n'
    return msg
  }

  function navigateToDestination() {
    const location = authContext.getDestinationAfterLogin()
    if (!location) {
      navigate('/notice_board', {})
    } else {
      authContext.navigateToLastLocationAsLogged()
    }
  }

  async function provideImplicitConsentsAndNavigateToDestination() {
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      await Utils.confirmImplicitContractAcceptance(abortController.signal, profileType, accountLegalStatus)

      const location = authContext.getDestinationAfterLogin()
      if (!location) {
        navigate('/notice_board', {})
      } else {
        authContext.navigateToLastLocationAsLogged()
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  function goToContractsVersionsViewer() {
    const state = {
      profileType: profileType,
      purpose: RegistrationPurpose.contractRevision,
      contractTypes: accountLegalStatus.pdxContractTypesThatNeedsAction,
    } as IRegistrationPageProps

    navigate('/registration', { state: state })
  }

  return (
    <StyledDialog open={props.isOpen} onClose={onClose} minHeight="auto">
      <ViewHeader
        title={t(msgIds.MSG_CONTRACTS_UPDATE_WARNING_DIALOG_TITLE)}
        onClickExit={() => onClose()}
        exitButtonVisible={true}
      />
      <ViewContent spacing={2}>
        <MarkdownViewer initialValue={msg?.replaceAll('<br>', '\n&nbsp;')} />

        {isGoToDashboardCmdVisible && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            size="large"
            onClick={navigateToDestination}
          >
            {t(msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS)}
          </Button>
        )}

        {isGoToDashboardAndMutesTheMsgCmdVisible && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            size="large"
            onClick={provideImplicitConsentsAndNavigateToDestination}
          >
            {t(msgIds.MSG_CONTRACTS_UPDATE_WARNING_ACCESS_AND_MUTE_MSG)}
          </Button>
        )}

        {isShowNewContractVersionsCmdVisible && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            size="large"
            onClick={goToContractsVersionsViewer}
          >
            {t(msgIds.MSG_CONTRACTS_UPDATE_WARNING_SHOW_CONTRACTS)}
          </Button>
        )}
      </ViewContent>
    </StyledDialog>
  )
}
