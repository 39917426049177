import { Box, Stack } from '@mui/material'
import { TutorialActionBar } from '../tutorialActionBar/TutorialActionBar'
import MarkdownViewer from '../markdownEditor/MarkdownViewer'
import { ITutorialViewProps } from './TutorialView.types'

export function TutorialView(props: ITutorialViewProps): JSX.Element {
  const { tutorial, disabledActions, onAction, sx } = props
  const tutorialStep = tutorial.steps[tutorial.stepIndex]
  return (
    <Stack sx={sx}>
      <Box flex={1} p={2}>
        {tutorialStep && <MarkdownViewer initialValue={tutorialStep.markdown} />}
      </Box>
      <TutorialActionBar disabledActions={disabledActions} onAction={onAction} />
    </Stack>
  )
}
