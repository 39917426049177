import msgIds from '../locales/msgIds'

export const APP_ENV = import.meta.env.VITE_APP_ENV
export const PDX_API_URL = import.meta.env.VITE_APP_API_URL
export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY
export const AUTH_JWT_STORAGE_KEY = 'personal-dox-auth-jwt'
export const LOGIN_ENABLED = true

export type AppType_all = AppType.business | AppType.consumer | AppType.admin | AppType.tools
export enum AppType {
  business = 1,
  consumer = 2,
  admin = 3,
  tools = 4,
}

export type AccountType_all =
  | AccountType.none
  | AccountType.externalUser
  | AccountType.internalUser
  | AccountType.placeholderUser
  | AccountType.seperadmin
export enum AccountType {
  none = 0,
  externalUser = 1,
  internalUser = 2,
  placeholderUser = 98,
  seperadmin = 100,
}

export type Gender_all = Gender.none | Gender.male | Gender.female
export enum Gender {
  none = 0,
  male = 1,
  female = 2,
}
export function genderFromValue(value: string | Gender_all) {
  if (value === Gender.none || value === Gender.male || value === Gender.female) {
    return value
  } else {
    if (value === Gender.female.toString()) {
      return Gender.female
    } else if (value === Gender.male.toString()) {
      return Gender.male
    } else if (value === Gender.none.toString()) {
      return Gender.none
    }
  }
}

export type ProfileType_all =
  | ProfileType.none
  | ProfileType.customer
  | ProfileType.operatorAdmin
  | ProfileType.structure
  | ProfileType.tutor
  | ProfileType.operatorInt
  | ProfileType.operatorExt
export type ProfileType_notNone =
  | ProfileType.customer
  | ProfileType.operatorAdmin
  | ProfileType.structure
  | ProfileType.tutor
  | ProfileType.operatorInt
  | ProfileType.operatorExt
export type ProfileType_consumer = ProfileType.customer | ProfileType.tutor
export type ProfileType_business =
  | ProfileType.operatorAdmin
  | ProfileType.structure
  | ProfileType.operatorInt
  | ProfileType.operatorExt
export type ProfileType_operators = ProfileType.operatorAdmin | ProfileType.operatorInt | ProfileType.operatorExt
export type ProfileType_operatorAdmins = ProfileType.operatorAdmin
export type ProfileType_collaborators = ProfileType.operatorInt | ProfileType.operatorExt
export enum ProfileType {
  none = 0,
  customer = 1,
  operatorAdmin = 2,
  structure = 3,
  tutor = 4,
  operatorInt = 6,
  operatorExt = 7,
}
export function isConsumer(profileType?: ProfileType): profileType is ProfileType_consumer {
  return profileType ? profileType === ProfileType.customer || profileType === ProfileType.tutor : false
}
export function isOperator(profileType?: ProfileType): profileType is ProfileType_operators {
  return profileType
    ? profileType === ProfileType.operatorAdmin ||
        profileType === ProfileType.operatorInt ||
        profileType === ProfileType.operatorExt
    : false
}
export function isCollaborator(profileType?: ProfileType): profileType is ProfileType_collaborators {
  return profileType ? profileType === ProfileType.operatorInt || profileType === ProfileType.operatorExt : false
}
export function isStructure(profileType?: ProfileType): boolean {
  return profileType ? profileType === ProfileType.structure : false
}
export function isBusiness(profileType?: ProfileType): boolean {
  return (
    !!profileType &&
    [ProfileType.operatorAdmin, ProfileType.structure, ProfileType.operatorInt, ProfileType.operatorExt].includes(
      profileType
    )
  )
}
export const ProfileTypeTranslationMap = {
  [ProfileType.none]: msgIds.MSG_PROFILE_TYPE_NONE,
  [ProfileType.customer]: msgIds.MSG_PROFILE_TYPE_CUSTOMER,
  [ProfileType.operatorAdmin]: msgIds.MSG_PROFILE_TYPE_OPERATOR_ADMIN,
  [ProfileType.operatorExt]: msgIds.MSG_PROFILE_TYPE_OPERATOR_EXT,
  [ProfileType.operatorInt]: msgIds.MSG_PROFILE_TYPE_OPERATOR_INT,
  [ProfileType.structure]: msgIds.MSG_PROFILE_TYPE_STRUCTURE,
  [ProfileType.tutor]: msgIds.MSG_PROFILE_TYPE_TUTOR,
}

export type AccountSearchTypes = 'none' | 'structures' | 'operators' | 'collaborators' | 'customers'

export type ProfileVisibility_all = ProfileVisibility.none | ProfileVisibility.all | ProfileVisibility.collaborators
export enum ProfileVisibility {
  none = 0,
  all = 1,
  collaborators = 2,
}

// export enum ProfileTitles {
//   arch = 'arch'
// }

export enum ActionType {
  none = 'none',

  dataProcessing = 'data_processing',

  listUsers = 'list_users',
  viewUsers = 'view_users',
  createUsers = 'create_users',
  updateUsers = 'update_users',
  deleteUsers = 'delete_users',

  listProfiles = 'list_profiles',
  viewProfiles = 'view_profiles',
  createProfiles = 'create_profiles',
  updateProfiles = 'update_profiles',
  deleteProfiles = 'delete_profiles',
  inviteProfiles = 'invite_profiles',

  listTreatments = 'list_treatments',
  viewTreatments = 'view_treatments',
  createTreatments = 'create_treatments',
  updateTreatments = 'update_treatments',
  deleteTreatments = 'delete_treatments',

  listContracts = 'list_contracts',
  viewContracts = 'view_contracts',
  createContracts = 'create_contracts',
  updateContracts = 'update_contracts',
  deleteContracts = 'delete_contracts',
  downloadContracts = 'download_contracts',

  listConsents = 'list_consents',
  viewConsents = 'view_consents',
  createConsents = 'create_consents',
  updateConsents = 'update_consents',
  deleteConsents = 'delete_consents',

  listDocuments = 'list_documents',
  viewDocuments = 'view_documents',
  viewDocumentsStats = 'view_documents_stats',
  createDocuments = 'create_documents',
  updateDocuments = 'update_documents',
  updateDocumentsRetentions = 'update_documents_retentions',
  deleteDocuments = 'delete_documents',
  deleteDocumentsRetentions = 'delete_documents_retentions',
  downloadDocuments = 'download_documents',
  archiveDocuments = 'archive_documents',

  alwaysDownload = 'always_download',

  listDossiers = 'list_dossiers',
  viewDossiers = 'view_dossiers',
  createDossiers = 'create_dossiers',
  updateDossiers = 'update_dossiers',
  deleteDossiers = 'delete_dossiers',
  deliveryDossiers = 'delivery_dossiers',
  downloadDossiers = 'download_dossiers',
  archiveDossiers = 'archive_dossiers',

  listDox = 'list_dox',
  viewDox = 'view_dox',
  createDox = 'create_dox',
  updateDox = 'update_dox',
  updateDoxRetention = 'update_dox_retentions',
  deleteDox = 'delete_dox',
  deleteDoxRetention = 'delete_dox_retentions',
  downloadDox = 'download_dox',
  deliverDox = 'deliver_dox',

  listActivities = 'list_activities',
  createActivities = 'create_activities',
  viewActivities = 'view_activities',
  updateActivities = 'update_activities',
  deleteActivities = 'delete_activities',

  listNotifications = 'list_notifications',
  createNotifications = 'create_notifications',
  viewNotifications = 'view_notifications',
  updateNotifications = 'update_notifications',
  deleteNotifications = 'delete_notifications',

  listInvoices = 'list_invoices',
  listProducts = 'list_products',
  viewProducts = 'view_products',
  listPrices = 'list_prices',
  listTaxRates = 'list_tax_rates',
  listPaymentMethods = 'list_payment_methods',
  updatePaymentMethods = 'update_payment_methods',
  listPromotionCodes = 'list_promotion_codes',

  listSubscriptions = 'list_subscriptions',
  updateSubscriptions = 'update_subscriptions',

  listInvitations = 'list_invitations',
  createInvitations = 'create_invitations',
  updateInvitations = 'update_invitations',

  viewAllCustomers = 'view_all_customers',

  viewOperatorPermissions = 'view_operator_permissions',
  createOperatorPermissions = 'create_operator_permissions',
  deleteOperatorPermissions = 'delete_operator_permissions',
  updateCollaboratorProfiles = 'update_collaborator_profiles',

  viewAllTreatedData = 'view_all_treated_data',
  viewDataTreatedFor = 'view_data_treated_for',

  viewPrivacyPlant = 'view_privacy_plant',
  updatePrivacyPlant = 'update_privacy_plant',
}
export const ActionTypeTranslationMap = {
  [ActionType.viewDocuments]: msgIds.MSG_ACTION_TYPE_VIEW_DOCUMENTS,
  [ActionType.downloadDocuments]: msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOCUMENTS,
  [ActionType.createDocuments]: msgIds.MSG_ACTION_TYPE_CREATE_DOCUMENTS,
  [ActionType.updateDocuments]: msgIds.MSG_ACTION_TYPE_UPDATE_DOCUMENTS,
  [ActionType.archiveDocuments]: msgIds.MSG_ACTION_TYPE_ARCHIVE_DOCUMENTS,
  [ActionType.deleteDocuments]: msgIds.MSG_ACTION_TYPE_DELETE_DOCUMENTS,
  [ActionType.viewDox]: msgIds.MSG_ACTION_TYPE_VIEW_DOX,
  [ActionType.downloadDox]: msgIds.MSG_ACTION_TYPE_DOWNLOAD_DOX,
  [ActionType.createDox]: msgIds.MSG_ACTION_TYPE_CREATE_DOX,
  [ActionType.updateDox]: msgIds.MSG_ACTION_TYPE_UPDATE_DOX,
  [ActionType.deleteDox]: msgIds.MSG_ACTION_TYPE_DELETE_DOX,
}

export type ContractType_all =
  | ContractType.none
  | ContractType.privacyPolicy
  | ContractType.termsOfUse
  | ContractType.dataProcessor
export enum ContractType {
  none = 0,
  privacyPolicy = 1,
  termsOfUse = 2,
  dataProcessor = 3,
}
export const ContractTypeTranslationMap = {
  [ContractType.none]: msgIds.MSG_CONTRACT_TYPE_NONE,
  [ContractType.privacyPolicy]: msgIds.MSG_CONTRACT_TYPE_PRIVACY_INFO,
  [ContractType.termsOfUse]: msgIds.MSG_CONTRACT_TYPE_TERMS_OF_USE,
  [ContractType.dataProcessor]: msgIds.MSG_CONTRACT_TYPE_DATA_PROCESSOR,
}

export type ContractVersionState_all =
  | ContractVersionState.none
  | ContractVersionState.published
  | ContractVersionState.obsolete
  | ContractVersionState.draft
export enum ContractVersionState {
  none = 0,
  published = 1,
  obsolete = 2,
  draft = 3,
}
export const ContractVersionStateTranslationMap = {
  [ContractVersionState.none]: msgIds.MSG_CONTRACT_VERSION_STATE_NONE,
  [ContractVersionState.published]: msgIds.MSG_CONTRACT_VERSION_STATE_PUBLISHED,
  [ContractVersionState.obsolete]: msgIds.MSG_CONTRACT_VERSION_STATE_OBSOLETE,
  [ContractVersionState.draft]: msgIds.MSG_CONTRACT_VERSION_STATE_DRAFT,
}
export const ContractVersionStateColorMap = {
  [ContractVersionState.none]: 'orange',
  [ContractVersionState.published]: 'green',
  [ContractVersionState.obsolete]: 'red',
  [ContractVersionState.draft]: 'orange',
}

export type ContractAcceptanceStatus_all =
  | ContractAcceptanceStatus.none
  | ContractAcceptanceStatus.toAccept
  | ContractAcceptanceStatus.implicityAcceptedAndConfirmed
  | ContractAcceptanceStatus.implicityAcceptedButNotConfirmed
  | ContractAcceptanceStatus.implicityAcceptedOptionalConsentsRequiredButNotConfirmed
  | ContractAcceptanceStatus.accepted
export enum ContractAcceptanceStatus {
  none = 0,
  toAccept = 1,
  implicityAcceptedAndConfirmed = 2,
  implicityAcceptedButNotConfirmed = 3,
  implicityAcceptedOptionalConsentsRequiredButNotConfirmed = 4,
  accepted = 5,
}
export const ContractAcceptanceStatusTranslationMap = {
  [ContractAcceptanceStatus.none]: msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_NONE,
  [ContractAcceptanceStatus.toAccept]: msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_TO_ACCEPT,
  [ContractAcceptanceStatus.implicityAcceptedAndConfirmed]:
    msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_AND_CONFIRMED,
  [ContractAcceptanceStatus.implicityAcceptedButNotConfirmed]:
    msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_BUT_NOT_CONFIRMED,
  [ContractAcceptanceStatus.accepted]: msgIds.MSG_CONTRACT_ACCEPTANCE_STATUS_ACCEPTED,
}

export type ConsentMode_all = ConsentMode.none | ConsentMode.optional | ConsentMode.mandatory
export enum ConsentMode {
  none = 0,
  optional = 1,
  mandatory = 2,
}

export type ConsentStatus_all =
  | ConsentStatus.none
  | ConsentStatus.granted
  | ConsentStatus.revoked
  | ConsentStatus.expired
export enum ConsentStatus {
  none = 0,
  granted = 1,
  revoked = 2,
  expired = 2,
}

export type ConsentRevokeCause_all =
  | ConsentRevokeCause.none
  | ConsentRevokeCause.automatically
  | ConsentRevokeCause.manually
export enum ConsentRevokeCause {
  none = 0,
  automatically = 1, // automatically from BE when a new release with is_breaking_change = true is published
  manually = 2, // manually by the user
}

export type TreatmentLegalBase_all =
  | TreatmentLegalBase.explicitConsent
  | TreatmentLegalBase.contractualObligation
  | TreatmentLegalBase.legalObligation
  | TreatmentLegalBase.vitalInterest
  | TreatmentLegalBase.publicInterest
  | TreatmentLegalBase.legitimateInterest
export enum TreatmentLegalBase {
  explicitConsent = 1,
  contractualObligation = 2,
  legalObligation = 3,
  vitalInterest = 4,
  publicInterest = 5,
  legitimateInterest = 6,
}
export const TreatmentLegalBaseTranslationMap = {
  [TreatmentLegalBase.explicitConsent]: msgIds.MSG_LEGAL_BASE_EXPLICIT_CONSENT,
  [TreatmentLegalBase.contractualObligation]: msgIds.MSG_LEGAL_BASE_CONTRACTUAL_OBLIGATION,
  [TreatmentLegalBase.legalObligation]: msgIds.MSG_LEGAL_BASE_LEGAL_OBLIGATION,
  [TreatmentLegalBase.vitalInterest]: msgIds.MSG_LEGAL_BASE_VITAL_INTEREST,
  [TreatmentLegalBase.publicInterest]: msgIds.MSG_LEGAL_BASE_PUBLIC_INTEREST,
  [TreatmentLegalBase.legitimateInterest]: msgIds.MSG_LEGAL_BASE_LEGITIMATE_INTEREST,
}

export type RetentionDuration_all =
  | RetentionDuration.none
  | RetentionDuration.day
  | RetentionDuration.week
  | RetentionDuration.month
  | RetentionDuration.year
export enum RetentionDuration {
  none = 0,
  day = 1,
  week = 2,
  month = 3,
  year = 4,
}
export const RetentionDurationTranslationMap = {
  [RetentionDuration.none]: msgIds.MSG_RETENTION_DURATION_NONE,
  [RetentionDuration.day]: msgIds.MSG_RETENTION_DURATION_DAY,
  [RetentionDuration.week]: msgIds.MSG_RETENTION_DURATION_WEEK,
  [RetentionDuration.month]: msgIds.MSG_RETENTION_DURATION_MONTH,
  [RetentionDuration.year]: msgIds.MSG_RETENTION_DURATION_YEAR,
}
export const RetentionDurationTranslationMapPlural = {
  [RetentionDuration.none]: msgIds.MSG_RETENTION_DURATION_NONE,
  [RetentionDuration.day]: msgIds.MSG_RETENTION_DURATION_DAYS,
  [RetentionDuration.week]: msgIds.MSG_RETENTION_DURATION_WEEKS,
  [RetentionDuration.month]: msgIds.MSG_RETENTION_DURATION_MONTHS,
  [RetentionDuration.year]: msgIds.MSG_RETENTION_DURATION_YEARS,
}

export type RetentionEndBehavior_all =
  | RetentionEndBehavior.none
  | RetentionEndBehavior.terminate
  | RetentionEndBehavior.notify
  | RetentionEndBehavior.terminateAndNotify
export enum RetentionEndBehavior {
  none = 0,
  terminate = 1,
  notify = 2,
  terminateAndNotify = 3,
}
export const RetentionEndBehaviorTranslationMap = {
  [RetentionEndBehavior.none]: msgIds.MSG_RETENTION_END_BEHAVIOR_NONE,
  [RetentionEndBehavior.terminate]: msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE,
  [RetentionEndBehavior.notify]: msgIds.MSG_RETENTION_END_BEHAVIOR_NOTIFY,
  [RetentionEndBehavior.terminateAndNotify]: msgIds.MSG_RETENTION_END_BEHAVIOR_TERMINATE_AND_NOTIFY,
}

export type CustomerVisibilityRules_all =
  | CustomerVisibilityRules.none
  | CustomerVisibilityRules.documentsRetention
  | CustomerVisibilityRules.completeDuration
export enum CustomerVisibilityRules {
  none = 0,
  /**
   * i dati del cliente rimangono visibili nell'anagrafica della struttura fintanto che nell'archivio
   * è conservato almeno un documento per la finalità cui il trattamento si riferisce
   */
  documentsRetention = 1,
  /**
   * i dati del cliente rimangono visibili nell'anagrafica della struttura per tutto il tempo indicato
   * dalla durata conservazione. Questa regola è utilizzabile quando il trattamento riguarda dati che
   * non sono conservati in PersonalDOX.
   * Ad esempio: un trattamento di dati per finalità di comunicazioni marketing. In questo modo il
   * cliente è visibile in anagrafica per il tempo massimo indicato
   */
  completeDuration = 2,
}
export const CustomerVisibilityRulesTranslationMap = {
  [CustomerVisibilityRules.none]: msgIds.MSG_CUSTOMER_VISIBILITY_RULES_NONE,
  [CustomerVisibilityRules.documentsRetention]: msgIds.MSG_CUSTOMER_VISIBILITY_RULES_DOCUMENTS_RETENTION,
  [CustomerVisibilityRules.completeDuration]: msgIds.MSG_CUSTOMER_VISIBILITY_RULES_COMPLETE_DURATION,
}

export enum TreatedFieldName {
  lastname = 'lastname',
  name = 'name',
  gender = 'gender',
  birthdate = 'birth_date',
  birthplace = 'birth_place',
  fiscalCode = 'fiscal_code',

  avatar = 'avatar',

  phone = 'phone',
  mobilePhone = 'mobile_phone',
  email = 'email',

  residenceCountry = 'residence_country',
  residenceCity = 'residence_city',
  residenceProvince = 'residence_province',
  residenceStreet = 'residence_street',
  residenceStreetNumber = 'residence_street_number',
  residenceZip = 'residence_zip',
}

export const TreatedFields = [
  { group: 'identification_data', fields: ['lastname', 'name', 'gender', 'birth_date', 'birth_place', 'fiscal_code'] },
  { group: 'recognition_data', fields: ['avatar'] },
  { group: 'residence_data', fields: ['country', 'city', 'province', 'street', 'street_number', 'zip'] },
  { group: 'contact_data', fields: ['phone', 'mobile_phone', 'email'] },
]
export const TreatedFieldsTranslationMap: { groups: { [key: string]: string }; fields: { [key: string]: string } } = {
  groups: {
    identification_data: msgIds.MSG_TREATED_FIELDS_GROUP_IDENTIFICATION,
    recognition_data: msgIds.MSG_TREATED_FIELDS_GROUP_RECOGNITION,
    residence_data: msgIds.MSG_TREATED_FIELDS_GROUP_RESIDENCE,
    contact_data: msgIds.MSG_TREATED_FIELDS_GROUP_CONTACT,
  },
  fields: {
    lastname: msgIds.MSG_TREATED_FIELDS_LASTNAME,
    name: msgIds.MSG_TREATED_FIELDS_NAME,
    gender: msgIds.MSG_TREATED_FIELDS_GENDER,
    birth_date: msgIds.MSG_TREATED_FIELDS_BIRTH_DATE,
    birth_place: msgIds.MSG_TREATED_FIELDS_BIRTH_PLACE,
    fiscal_code: msgIds.MSG_TREATED_FIELDS_FISCAL_CODE,
    avatar: msgIds.MSG_TREATED_FIELDS_AVATAR,
    country: msgIds.MSG_TREATED_FIELDS_COUNTRY,
    city: msgIds.MSG_TREATED_FIELDS_CITY,
    province: msgIds.MSG_TREATED_FIELDS_PROVINCE,
    street: msgIds.MSG_TREATED_FIELDS_STREET,
    street_number: msgIds.MSG_TREATED_FIELDS_STREET_NUMBER,
    zip: msgIds.MSG_TREATED_FIELDS_ZIP,
    phone: msgIds.MSG_TREATED_FIELDS_PHONE,
    mobile_phone: msgIds.MSG_TREATED_FIELDS_MOBILE_PHONE,
    email: msgIds.MSG_TREATED_FIELDS_EMAIL,
  },
}

export enum DocumentState {
  draft,
  draftRevision,
  archived,
}

export type ArchiveItemSourceType_all =
  | ArchiveItemSourceType.none
  | ArchiveItemSourceType.internalSource
  | ArchiveItemSourceType.externalSource
export enum ArchiveItemSourceType {
  none = 0,
  internalSource = 1,
  externalSource = 2,
}

export type RetentionRules_all = RetentionRules.none | RetentionRules.centralized | RetentionRules.specific
export enum RetentionRules {
  none = 0,
  centralized = 1, // The criteria for storing documents are inherited from the dox
  specific = 2, // Document retention policies are specific to each document
}
export const RetentionRulesTranslationMap = {
  [RetentionRules.none]: msgIds.MSG_RETENTION_RULE_NONE,
  [RetentionRules.centralized]: msgIds.MSG_RETENTION_RULE_CENTRALIZED,
  [RetentionRules.specific]: msgIds.MSG_RETENTION_RULE_SPECIFIC,
}

export function isPersonalDoxMimetype(mimetype?: string): boolean | undefined {
  if (mimetype === undefined) return undefined
  return ['text/markdown:custom1'].includes(mimetype)
}

export type DialogResults = 'ok' | 'yes' | 'no' | 'abort'

export type NotificationsType_all =
  | NotificationsType.userPrivateMessage
  | NotificationsType.upcomingProfileSuspension
  | NotificationsType.profileSuspension
  | NotificationsType.upcomingProfileDeletion
  | NotificationsType.profileDeletionBySystem
  | NotificationsType.profileDeletionByOwner
  | NotificationsType.upcomingUserDeletion
  | NotificationsType.userDeletionBySystem
  | NotificationsType.userDeletionByOwner
  | NotificationsType.contractUpdated
  | NotificationsType.dossierDelivered
  | NotificationsType.documentRevisionCreated
  | NotificationsType.documentRevisionViewed
  | NotificationsType.documentRevisionDownloaded
  | NotificationsType.dataProcessingGranted
  | NotificationsType.dataProcessingUpdated
  | NotificationsType.dataProcessingSuspended
  | NotificationsType.dataProcessingRestored
  | NotificationsType.upcomingDataProcessingExpiration
  | NotificationsType.dataProcessingExpired
  | NotificationsType.privacyPolicyUpdated
  | NotificationsType.privacyPolicyConsentUpdated
  | NotificationsType.profileAttached
  | NotificationsType.profileDeattached
  | NotificationsType.upcomingSubscriptionTrialExpiration
  | NotificationsType.upcomingDocumentRetentionExpiration
  | NotificationsType.documentRetentionExpired
  | NotificationsType.upcomingDoxRetentionExpiration
  | NotificationsType.doxRetentionExpired
  | NotificationsType.doxDelivered
  | NotificationsType.doxReceived
  | NotificationsType.permissionViewUpdated
  | NotificationsType.invitationPermitted
  | NotificationsType.invitationReceived
  | NotificationsType.invitationAccepted
  | NotificationsType.profileDeletionByOperatorAdmin
export enum NotificationsType {
  userPrivateMessage = 0,
  upcomingProfileSuspension = 1,
  profileSuspension = 2,
  upcomingProfileDeletion = 3,
  profileDeletionBySystem = 4,
  profileDeletionByOwner = 5,
  upcomingUserDeletion = 6,
  userDeletionBySystem = 7,
  userDeletionByOwner = 8,
  contractUpdated = 9,
  dossierDelivered = 10,
  documentRevisionCreated = 11,
  documentRevisionViewed = 12,
  documentRevisionDownloaded = 13,
  dataProcessingGranted = 14,
  dataProcessingUpdated = 15,
  dataProcessingSuspended = 16,
  dataProcessingRestored = 17,
  upcomingDataProcessingExpiration = 18,
  dataProcessingExpired = 19,
  privacyPolicyUpdated = 20,
  privacyPolicyConsentUpdated = 21,
  profileAttached = 22,
  profileDeattached = 23,
  upcomingSubscriptionTrialExpiration = 24,
  upcomingDocumentRetentionExpiration = 25,
  documentRetentionExpired = 26,
  upcomingDoxRetentionExpiration = 27,
  doxRetentionExpired = 28,
  doxDelivered = 29,
  doxReceived = 30,
  permissionViewUpdated = 31, // attualmente non utilizzata
  invitationPermitted = 32,
  invitationReceived = 33,
  invitationAccepted = 34,
  profileDeletionByOperatorAdmin = 35,
}

export enum NotificationArgumentType {
  none = 0,
  message = 1,
  profileManagement = 2,
  accountManagement = 3,
  pdxContract = 4,
  dossier = 5,
  document = 6,
  privacySettings = 7,
  privacyPolicy = 8,
  archiveManagement = 9,
  invitation = 10,
}
export const NotificationArgumentTranslationMap = {
  [NotificationArgumentType.none]: msgIds.MSG_NOTIFICATION_ARGUMENT_NONE,
  [NotificationArgumentType.message]: msgIds.MSG_NOTIFICATION_ARGUMENT_MESSAGE,
  [NotificationArgumentType.profileManagement]: msgIds.MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT,
  [NotificationArgumentType.accountManagement]: msgIds.MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT,
  [NotificationArgumentType.pdxContract]: msgIds.MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS,
  [NotificationArgumentType.dossier]: msgIds.MSG_NOTIFICATION_ARGUMENT_DOSSIER,
  [NotificationArgumentType.document]: msgIds.MSG_NOTIFICATION_ARGUMENT_DOCUMENT,
  [NotificationArgumentType.privacySettings]: msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS,
  [NotificationArgumentType.privacyPolicy]: msgIds.MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY,
  [NotificationArgumentType.archiveManagement]: msgIds.MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT,
  [NotificationArgumentType.invitation]: msgIds.MSG_NOTIFICATION_ARGUMENT_INVITATION,
}

export type HistoryType = 'mop' | 'document' | 'dox' | 'account'

export enum HistoryEventType {
  EVT_USER_CREATED = 'EVT_USER_CREATED',
  EVT_USER_UPDATED = 'EVT_USER_UPDATED',
  EVT_USER_ACTIVATED = 'EVT_USER_ACTIVATED',
  EVT_USER_SUSPENDED = 'EVT_USER_SUSPENDED',
  EVT_USER_UPCOMING_DELETION = 'EVT_USER_UPCOMING_DELETION',
  EVT_USER_DELETED = 'EVT_USER_DELETED',

  EVT_PROFILE_CREATED = 'EVT_PROFILE_CREATED',
  EVT_PROFILE_ATTACHED = 'EVT_PROFILE_ATTACHED',
  EVT_PROFILE_DETACHED = 'EVT_PROFILE_DETACHED',
  EVT_PROFILE_UPCOMING_SUSPENSION = 'EVT_PROFILE_UPCOMING_SUSPENSION',
  EVT_PROFILE_SUSPENDED = 'EVT_PROFILE_SUSPENDED',
  EVT_PROFILE_UPCOMING_DELETION = 'EVT_PROFILE_UPCOMING_DELETION',
  EVT_PROFILE_DELETED = 'EVT_PROFILE_DELETED',

  EVT_INVITATION_CREATED = 'EVT_INVITATION_CREATED',
  EVT_INVITATION_ACCEPTED = 'EVT_INVITATION_ACCEPTED',
  EVT_INVITATION_REVOKED = 'EVT_INVITATION_REVOKED',

  EVT_PERMISSION_CREATED = 'EVT_PERMISSION_CREATED',
  EVT_PERMISSION_UPDATED = 'EVT_PERMISSION_UPDATED',
  EVT_PERMISSION_SUSPENDED = 'EVT_PERMISSION_SUSPENDED',
  EVT_PERMISSION_RESTORED = 'EVT_PERMISSION_RESTORED',
  EVT_PERMISSION_UPCOMING_EXPIRATION = 'EVT_PERMISSION_UPCOMING_EXPIRATION',
  EVT_PERMISSION_EXPIRED = 'EVT_PERMISSION_EXPIRED',
  EVT_PERMISSION_DELETED = 'EVT_PERMISSION_DELETED',
  EVT_PERMISSION_SENDED = 'EVT_PERMISSION_SENDED',

  EVT_TREATMENT_CREATED = 'EVT_TREATMENT_CREATED',
  EVT_TREATMENT_UPDATED = 'EVT_TREATMENT_UPDATED',
  EVT_TREATMENT_DELETED = 'EVT_TREATMENT_DELETED',
  EVT_TREATMENT_ENABLED = 'EVT_TREATMENT_ENABLED',
  EVT_TREATMENT_DISABLED = 'EVT_TREATMENT_DISABLED',

  EVT_CONTRACT_VERSION_CREATED = 'EVT_CONTRACT_VERSION_CREATED',
  EVT_CONTRACT_VERSION_UPDATED = 'EVT_CONTRACT_VERSION_UPDATED',
  EVT_CONTRACT_VERSION_PUBLISHED = 'EVT_CONTRACT_VERSION_PUBLISHED',
  EVT_CONTRACT_VERSION_DELETED = 'EVT_CONTRACT_VERSION_DELETED',

  EVT_CONSENT_GRANTED = 'EVT_CONSENT_GRANTED',
  EVT_CONSENT_REVOKED = 'EVT_CONSENT_REVOKED',

  EVT_DOCUMENT_REVISION_CREATED = 'EVT_DOCUMENT_REVISION_CREATED',
  EVT_DOCUMENT_REVISION_UPDATED = 'EVT_DOCUMENT_REVISION_UPDATED',
  EVT_DOCUMENT_REVISION_ARCHIVED = 'EVT_DOCUMENT_REVISION_ARCHIVED',
  EVT_DOCUMENT_REVISION_DELETED = 'EVT_DOCUMENT_REVISION_DELETED',
  EVT_DOCUMENT_REVISION_DOWNLOADED = 'EVT_DOCUMENT_REVISION_DOWNLOADED',

  EVT_DOCUMENT_RETENTION_CREATED = 'EVT_DOCUMENT_RETENTION_CREATED',
  EVT_DOCUMENT_RETENTION_UPDATED = 'EVT_DOCUMENT_RETENTION_UPDATED',
  EVT_DOCUMENT_RETENTION_UPCOMING_EXPIRATION = 'EVT_DOCUMENT_RETENTION_UPCOMING_EXPIRATION',
  EVT_DOCUMENT_RETENTION_EXPIRED = 'EVT_DOCUMENT_RETENTION_EXPIRED',

  EVT_DOCUMENT_REVISION_VIEWED = 'EVT_DOCUMENT_REVISION_VIEWED',
  EVT_DOCUMENT_CREATED = 'EVT_DOCUMENT_CREATED',
  EVT_DOCUMENT_RENAMED = 'EVT_DOCUMENT_RENAMED',
  EVT_DOCUMENT_DELETED = 'EVT_DOCUMENT_DELETED',
  EVT_DOCUMENT_DELIVERED = 'EVT_DOCUMENT_DELIVERED',
  EVT_DOCUMENT_RECEIVED = 'EVT_DOCUMENT_RECEIVED',
  EVT_DOCUMENT_MARKED_AS_OBSOLETE = 'EVT_DOCUMENT_MARKED_AS_OBSOLETE',
  EVT_DOCUMENT_MARKED_AS_IMPORTANT = 'EVT_DOCUMENT_MARKED_AS_IMPORTANT',

  EVT_DOSSIER_CREATED = 'EVT_DOSSIER_CREATED',
  EVT_DOSSIER_UPDATED = 'EVT_DOSSIER_UPDATED',
  EVT_DOSSIER_DELETED = 'EVT_DOSSIER_DELETED',
  EVT_DOSSIER_DELIVERED = 'EVT_DOSSIER_DELIVERED',

  EVT_DOX_CREATED = 'EVT_DOX_CREATED',
  EVT_DOX_UPDATED = 'EVT_DOX_UPDATED',
  EVT_DOX_DELETED = 'EVT_DOX_DELETED',
  EVT_DOX_DOWNLOADED = 'EVT_DOX_DOWNLOADED',
  EVT_DOX_VIEWED = 'EVT_DOX_VIEWED',
  EVT_DOX_RETENTION_UPCOMING_EXPIRATION = 'EVT_DOX_RETENTION_UPCOMING_EXPIRATION',
  EVT_DOX_RETENTION_EXPIRED = 'EVT_DOX_RETENTION_EXPIRED',
  EVT_DOX_DELIVERED = 'EVT_DOX_DELIVERED',
  EVT_DOX_RECEIVED = 'EVT_DOX_RECEIVED',

  EVT_ACTIVITY_CREATED = 'EVT_ACTIVITY_CREATED',

  EVT_NOTIFICATION_CREATED = 'EVT_NOTIFICATION_CREATED',

  EVT_INVOICE_FINALIZED = 'EVT_INVOICE_FINALIZED',
}

export type InvitationPurposeType_all = InvitationPurposeType.collaborate
export enum InvitationPurposeType {
  collaborate = 1,
}

export type DashboardItemType_all =
  | DashboardItemType.none
  | DashboardItemType.note
  | DashboardItemType.customer
  | DashboardItemType.internalDocument
export enum DashboardItemType {
  none = 0,
  note = 1,
  customer = 2,
  internalDocument = 3,
}

export enum LoginPurpose {
  none = 0,
  subscriptionChange = 1,
  profileActivation = 2,
  userJustActivated = 3,
}

export enum RegistrationPurpose {
  none = 0,
  contractRevision = 1,
  profileActivation = 2,
  userRegistration = 3,
  changePlan = 4,
  placeholderRegistration = 5,
}

export enum SignInStep {
  contractPrivacyPolicy = 1,
  contractTermsOfUse = 2,
  contractDataProcessor = 3,
  personAnagraphic = 4,
  structureAnagraphic = 5,
  structureBilling = 6,
  credentials = 7,
  summary = 8,
}

export enum SubscribingAction {
  none = 0,
  create = 1,
  verify = 2,
  complete = 3,
  update = 4,
  activateTrial = 5,
  updateToFree = 6,
}

export enum InvoiceStatus {
  pending = 0,
  uncollectable = 1,
  paid = 2,
}
export const InvoiceStatusTranslationMap = {
  [InvoiceStatus.pending]: msgIds.MSG_INVOICE_STATUS_PENDING,
  [InvoiceStatus.uncollectable]: msgIds.MSG_INVOICE_STATUS_UNCOLLECTABLE,
  [InvoiceStatus.paid]: msgIds.MSG_INVOICE_STATUS_PAID,
}
