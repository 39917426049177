import { useEffect, useState } from 'react'
import { useTheme, Button, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import * as dalAuth from '../../dal/DalAuth'
import * as dalAccount from '../../dal/DalAccount'
import log from '../../shared/Logger'

import { useUiContext, topbarHeight, leftbarWidth } from '../../contexts/UiContext'
import { useAuthContext } from '../../contexts/AuthContext'
import * as LeftbarData from './LeftbarData'
import LeftbarItemsArea from './LeftbarItemsArea'
import { useNavigate } from 'react-router'
import { IArchivePageInit } from '../../pages/ArchivePage/ArchivePage.types'

export default function Leftbar() {
  const uiContext = useUiContext()
  const theme = useTheme()
  const authContext = useAuthContext()
  const [leftbarData, setLeftbarData] = useState<LeftbarData.ILeftbarItemsArea[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const data = LeftbarData.getLeftbarData(authContext.loggedAccount, authContext.assistedAccount)
    setLeftbarData(data)
  }, [authContext.loggedAccount, authContext.assistedAccount])

  async function loginHykaOpeAdmin() {
    try {
      const email = '0025@demo.pdx'
      const password = '!1Qqcfzh'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)
      authContext.setLoggedAccount(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      const customerAccount = await dalAccount.getAccountFromProfileId(abortController.signal, 66, false, false)
      authContext.setAssistedAccount(customerAccount)
      navigate('/notice_board', { state: {}, replace: true })
    } catch (err) {
      log.debug('login ope error!!')
    }
  }

  async function loginAndEditCollaboratorAccount() {
    try {
      const email = '0025@demo.pdx'
      const password = '!1Qqcfzh'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)
      authContext.setLoggedAccount(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      navigate('/account_editor', { state: { profileId: 89, detailsOwnerProfileId: 89 }, replace: true })
    } catch (err) {
      log.debug('login ope error!!')
    }
  }

  async function loginAndEditCollaboratorAuthorizationsAccount() {
    try {
      const email = '0025@demo.pdx'
      const password = '!1Qqcfzh'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)
      authContext.setLoggedAccount(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      navigate('/account_authorizations', { state: { profileId: 89 }, replace: true })
    } catch (err) {
      log.debug('login ope error!!')
    }
  }

  async function loginAndEditCustomerAccount() {
    try {
      const email = '0025@demo.pdx'
      const password = '!1Qqcfzh'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)
      authContext.setLoggedAccount(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      navigate('/account_editor', { state: { profileId: 66 }, replace: true })
    } catch (err) {
      log.debug('login ope error!!')
    }
  }

  async function loginAndEditCustomerPlaceholderAccount() {
    try {
      const email = '0025@demo.pdx'
      const password = '!1Qqcfzh'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)
      authContext.setLoggedAccount(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      const customerAccount = await dalAccount.getAccountFromProfileId(abortController.signal, 7, false, false)
      authContext.setAssistedAccount(customerAccount)
      navigate('/notice_board', { state: {}, replace: true })

      // await new Promise((resolve) => setTimeout(resolve, 100))
      // navigate('/account_editor', { state: { profileId: 93 }, replace: true })
    } catch (err) {
      log.debug('login ope error!!')
    }
  }

  async function loginCossuCustomer() {
    try {
      const email = '0019@demo.pdx'
      const password = '!1Qqwrma'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, false)
      log.debug(reply)
      //await new Promise((resolve) => setTimeout(resolve, 100))
      //const reply2 = await dalAuth.checkTotp(abortController.signal, '288705')

      authContext.setLoggedAccount(reply)
      navigate('/notice_board', { state: {}, replace: true })
    } catch (err) {
      log.debug('login cus error!!')
    }
  }

  async function loginCossuCollaborator() {
    try {
      const email = 'democus1@demo.pdx'
      const password = '!1Qqwwww'
      authContext.resetJwt()

      await new Promise((resolve) => setTimeout(resolve, 100))
      const abortController = new AbortController()
      const reply = await dalAuth.doLogin(abortController.signal, email, password, true)
      log.debug(reply)

      await new Promise((resolve) => setTimeout(resolve, 100))
      const account = await dalAuth.changeActiveProfile(abortController.signal, 89)
      authContext.setLoggedAccount(account)
      navigate('/notice_board', { state: {}, replace: true })
    } catch (err) {
      log.debug('login cus error!!')
    }
  }

  async function goToMafaldaArchive() {
    navigate('/archive', {
      state: {
        isPerspective: true,
        onlyReceived: false,
        selectedDoxId: 12,
      } as IArchivePageInit,
    })
    // navigate('/archive', {
    //   state: {
    //     isPerspective: true,
    //     onlyReceived: false,
    //     grantorProfileId: 2,
    //   },
    // })
  }

  return (
    <MuiDrawer
      open={uiContext.isLeftbarOpened}
      variant="persistent"
      sx={{
        width: leftbarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: leftbarWidth,
          boxSizing: 'border-box',
        },
        [theme.breakpoints.up('md')]: {
          variant: 'temporary',
        },
      }}
      PaperProps={{
        sx: {
          height: `calc(100% - ${topbarHeight}px)`,
          top: topbarHeight,
        },
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        <Stack sx={{ alignItems: 'start' }}>
          {/* <p>
            <b>Hyka</b>
          </p>
          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => loginHykaOpeAdmin()}>
            Login OpeAdmin
          </Button>

          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => loginAndEditCollaboratorAccount()}>
            Login Edit collab. Rossi
          </Button>

          <Button
            sx={{ textTransform: 'none' }}
            color="inherit"
            onClick={() => loginAndEditCollaboratorAuthorizationsAccount()}
          >
            Login Edit auth. collab. Rossi
          </Button>

          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => loginAndEditCustomerAccount()}>
            Login Edit cust. Cossu
          </Button>

          <Button
            sx={{ textTransform: 'none' }}
            color="inherit"
            onClick={() => loginAndEditCustomerPlaceholderAccount()}
          >
            Login Edit cust. Napoleone
          </Button>

          <p>
            <b>Cossu</b>
          </p>
          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => loginCossuCustomer()}>
            Login Cossu Customer
          </Button>

          <p>
            <b>Rossi</b>
          </p>

          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => loginCossuCollaborator()}>
            Login Collaborator
          </Button> 

          <p>
            <b>Mafalda</b>
          </p>
          <Button sx={{ textTransform: 'none' }} color="inherit" onClick={() => goToMafaldaArchive()}>
            Go To Archive
          </Button>*/}
        </Stack>

        <List>
          {leftbarData?.map((area, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'flex' }}>
              <LeftbarItemsArea data={area} index={index} />
            </ListItem>
          ))}
        </List>
      </Box>
    </MuiDrawer>
  )
}
