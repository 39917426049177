import React, { useEffect, useState } from 'react'
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import msgIds from '../../locales/msgIds'
import { CollaboratorsIco, OperatorIco, StructureIco, UserIco } from '../icons'
import { Account } from '../../models/Account'
import { StyledToggleButton, StyledToggleButtonGroup } from '../../shared/StyledControls'
import { AccountSearchTypes, isOperator } from '../../shared/Constants'
import { IAccountSelectorDialogData } from '../../dialogs/accountSelectorDialog/AccountSelectorDialog.types'
import AccountSelectorDialog from '../../dialogs/accountSelectorDialog/AccountSelectorDialog'
import { useLayout } from '../../hooks/Layout'

export default function TopbarSearchSelector() {
  const { isPhone } = useLayout()
  const authContext = useAuthContext()
  const { t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>()
  const [accountSearchType, setAccountSearchType] = useState<AccountSearchTypes>('none')

  // dialogs
  const [accountsSelectorDialogData, setAccountsSelectorDialogData] = useState<IAccountSelectorDialogData>()
  const [accountsSelectorDialogOpen, setAccountsSelectorDialogOpen] = useState(false)

  const AccountSelectedAction = (account: Account) => {
    authContext.setAssistedAccount(account)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElUser(null)
  }

  function handleSearchAccountTypeSelected(searchType: AccountSearchTypes) {
    if (searchType !== accountSearchType) {
      setAccountSearchType(searchType)
    } else {
      onSearchAccount(accountSearchType)
    }
  }

  useEffect(() => {
    if (accountSearchType !== 'none') {
      onSearchAccount(accountSearchType)
    }
  }, [accountSearchType])

  function onSearchAccount(searchType: AccountSearchTypes) {
    if (isPhone) {
      handleClose()
    }
    setAccountsSelectorDialogOpen(true)
    setAccountsSelectorDialogData({
      searchOptions: searchType,
      goToAccountPageOption: true,
      showPrivacyPolicy: false,
      onResult: async (result) => {
        setAccountsSelectorDialogOpen(false)
        if (result.userChoice !== 'yes') return
        AccountSelectedAction(result.account)
      },
    })
  }

  return (
    <>
      {isPhone ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Tooltip title={t(msgIds.MSG_USER_MENU)}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <StructureIco />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => handleClose()}
          >
            <MenuItem
              onClick={() => {
                handleSearchAccountTypeSelected('structures')
              }}
            >
              <ListItemIcon>
                <StructureIco />
              </ListItemIcon>
              <ListItemText>{t(msgIds.MSG_STRUCTURES)}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSearchAccountTypeSelected('operators')
              }}
            >
              <ListItemIcon>
                <OperatorIco />
              </ListItemIcon>
              <ListItemText>{t(msgIds.MSG_OPERATORS)}</ListItemText>
            </MenuItem>
            {isOperator(authContext.loggedProfileType) && (
              <MenuItem onClick={() => handleSearchAccountTypeSelected('collaborators')}>
                <ListItemIcon>
                  <CollaboratorsIco />
                </ListItemIcon>
                <ListItemText>{t(msgIds.MSG_COLLABORATORS)}</ListItemText>
              </MenuItem>
            )}
            {isOperator(authContext.loggedProfileType) && (
              <MenuItem onClick={() => handleSearchAccountTypeSelected('customers')}>
                <ListItemIcon>
                  <UserIco />
                </ListItemIcon>
                <ListItemText>{t(msgIds.MSG_USERS)}</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <StyledToggleButtonGroup
            exclusive
            value={accountSearchType}
            onChange={(event, value) => {
              const newAccountSearchType = value ?? accountSearchType
              handleSearchAccountTypeSelected(newAccountSearchType)
              event.preventDefault()
            }}
          >
            <Tooltip title={t(msgIds.MSG_STRUCTURES)}>
              <StyledToggleButton value="structures" data-testid="structures-search-button">
                <StructureIco />
              </StyledToggleButton>
            </Tooltip>
            <Tooltip title={t(msgIds.MSG_OPERATORS)}>
              <StyledToggleButton value="operators" data-testid="operators-search-button">
                <OperatorIco />
              </StyledToggleButton>
            </Tooltip>
            {isOperator(authContext.loggedProfileType) && (
              <Tooltip title={t(msgIds.MSG_COLLABORATORS)}>
                <StyledToggleButton value="collaborators" data-testid="collaborators-search-button">
                  <CollaboratorsIco />
                </StyledToggleButton>
              </Tooltip>
            )}
            {isOperator(authContext.loggedProfileType) && (
              <Tooltip title={t(msgIds.MSG_USERS)}>
                <StyledToggleButton value="customers" data-testid="customers-search-button">
                  <UserIco />
                </StyledToggleButton>
              </Tooltip>
            )}
          </StyledToggleButtonGroup>
        </Box>
      )}

      {accountsSelectorDialogData && (
        <AccountSelectorDialog
          {...accountsSelectorDialogData}
          isOpen={accountsSelectorDialogOpen}
          onClose={() => setAccountsSelectorDialogOpen(false)}
        />
      )}
    </>
  )
}
