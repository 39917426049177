export default {
  MSG_PERSONALDOX: 'MSG_PERSONALDOX',
  MSG_OK: 'MSG_OK',
  MSG_YES: 'MSG_YES',
  MSG_ABORT: 'MSG_ABORT',
  MSG_NO: 'MSG_NO',
  MSG_CONFIRM: 'MSG_CONFIRM',
  MSG_CONTINUE: 'MSG_CONTINUE',
  MSG_DO_YOU_CONFIRM: 'MSG_DO_YOU_CONFIRM',
  MSG_ADD: 'MSG_ADD',
  MSG_DATE: 'MSG_DATE',
  MSG_DRAFT: 'MSG_DRAFT',
  MSG_DOX: 'MSG_DOX',
  MSG_DOCUMENT: 'MSG_DOCUMENT',
  MSG_AUTHORIZATIONS: 'MSG_AUTHORIZATIONS',
  MSG_DIMENSION: 'MSG_DIMENSION',
  MSG_MYSELF: 'MSG_MYSELF',
  MSG_FINALITY: 'MSG_FINALITY',
  MSG_RETENTION: 'MSG_RETENTION',
  MSG_DAY: 'MSG_DAY',
  MSG_DAYS: 'MSG_DAYS',
  MSG_WEEK: 'MSG_WEEK',
  MSG_WEEKS: 'MSG_WEEKS',
  MSG_MONTH: 'MSG_MONTH',
  MSG_MONTHS: 'MSG_MONTHS',
  MSG_YEAR: 'MSG_YEAR',
  MSG_YEARS: 'MSG_YEARS',
  MSG_LOADING_IN_PROGRESS: 'MSG_LOADING_IN_PROGRESS',
  MSG_NECESSARY: 'MSG_NECESSARY',
  MSG_OPTIONAL: 'MSG_OPTIONAL',
  MSG_BORN_ON: 'MSG_BORN_ON',
  MSG_NEW_TREATMENT_FIELD: 'MSG_NEW_TREATMENT_FIELD',
  MSG_MOVE_UP: 'MSG_MOVE_UP',
  MSG_MOVE_DOWN: 'MSG_MOVE_DOWN',
  MSG_DELETE: 'MSG_DELETE',
  MSG_MODIFY: 'MSG_MODIFY',
  MSG_FROM: 'MSG_FROM',
  MSG_TO: 'MSG_TO',
  MSG_DISABLED: 'MSG_DISABLED',
  MSG_PROFILE_INFORMATIONS: 'MSG_PROFILE_INFORMATIONS',
  MSG_MANDATORY: 'MSG_MANDATORY',
  MSG_OPTIONAL2: 'MSG_OPTIONAL2',
  MSG_OPERATION_EXECUTED_SUCCESSFULLY: 'MSG_OPERATION_EXECUTED_SUCCESSFULLY',
  MSG_LANGUAGE: 'MSG_LANGUAGE',
  MSG_LANGUAGE_ITALIAN: 'MSG_LANGUAGE_ITALIAN',
  MSG_LANGUAGE_ENGLISH: 'MSG_LANGUAGE_ENGLISH',
  MSG_FUNCTION_NOT_AVAILABLE: 'MSG_FUNCTION_NOT_AVAILABLE',
  MSG_SUBSCRIPTION: 'MSG_SUBSCRIPTION',
  MSG_LOGIN: 'MSG_LOGIN',
  MSG_LOGOUT: 'MSG_LOGOUT',
  MSG_PRODUCTS: 'MSG_PRODUCTS',
  MSG_INVOICES: 'MSG_INVOICES',
  MSG_VAT: 'MSG_VAT',
  MSG_FIELD: 'MSG_FIELD',
  MSG_PRIVATE: 'MSG_PRIVATE',
  MSG_SHARED: 'MSG_SHARED',
  MSG_CONTRACT: 'MSG_CONTRACT',
  // leftbar
  MSG_ARCHIVE_MANAGEMENT: 'MSG_ARCHIVE_MANAGEMENT',
  MSG_DESKTOP: 'MSG_DESKTOP',
  MSG_PERSONAL_ARCHIVE: 'MSG_PERSONAL_ARCHIVE',
  MSG_DOX_SHARED_BY_WITH: 'MSG_DOX_SHARED_BY_WITH',
  MSG_RECEIVED_DOX: 'MSG_RECEIVED_DOX',
  MSG_SETTINGS: 'MSG_SETTINGS',
  MSG_PERSONAL_SETTINGS: 'MSG_PERSONAL_SETTINGS',
  MSG_CONFIGURATIONS: 'MSG_CONFIGURATIONS',
  MSG_PERSONAL_INFORMATIONS: 'MSG_PERSONAL_INFORMATIONS',
  MSG_PRESENTATION: 'MSG_PRESENTATION',
  MSG_STRUCTURE_SETTINGS: 'MSG_STRUCTURE_SETTINGS',
  MSG_STRUCTURE_INFORMATIONS: 'MSG_STRUCTURE_INFORMATIONS',
  MSG_OPERATIVE_PRIVACY_MODEL: 'MSG_OPERATIVE_PRIVACY_MODEL',
  MSG_TREATMENT_ACTIVITIES: 'MSG_TREATMENT_ACTIVITIES',
  MSG_PRIVACY_POLICIES: 'MSG_PRIVACY_POLICIES',
  MSG_CHANGE_HISTORY: 'MSG_CHANGE_HISTORY',
  MSG_USER_MANAGEMENT: 'MSG_USER_MANAGEMENT',
  MSG_SHARED_ARCHIVE: 'MSG_SHARED_ARCHIVE',
  MSG_STRUCTURE_ARCHIVE: 'MSG_STRUCTURE_ARCHIVE',
  // users and profiles
  MSG_PRIVATE_USER: 'MSG_PRIVATE_USER',
  MSG_ORGANIZATION: 'MSG_ORGANIZATION',
  MSG_OPERATOR: 'MSG_OPERATOR',
  MSG_COLLABORATOR: 'MSG_COLLABORATOR',
  MSG_ADMIN_LO: 'MSG_ADMIN_LO',
  MSG_INTERNAL_LO: 'MSG_INTERNAL_LO',
  MSG_EXTERNAL_LO: 'MSG_EXTERNAL_LO',
  MSG_STRUCTURE: 'MSG_STRUCTURE',
  MSG_USER: 'MSG_USER',
  MSG_USER_DETAILS: 'MSG_USER_DETAILS',
  // topbar
  MSG_USER_MENU: 'MSG_USER_MENU',
  MSG_STRUCTURES: 'MSG_STRUCTURES',
  MSG_OPERATORS: 'MSG_OPERATORS',
  MSG_COLLABORATORS: 'MSG_COLLABORATORS',
  MSG_USERS: 'MSG_USERS',
  MSG_NOTICE_BOARD: 'MSG_NOTICE_BOARD',
  MSG_NOTICE_GUIDE: 'MSG_NOTICE_GUIDE',
  MSG_NOTIFICATIONS: 'MSG_NOTIFICATIONS',
  MSG_ACTIVATE_NEW_OPERATOR_PROFILE: 'MSG_ACTIVATE_NEW_OPERATOR_PROFILE',
  MSG_ACTIVATE_NEW_CONSUMER_PROFILE: 'MSG_ACTIVATE_NEW_CONSUMER_PROFILE',
  MSG_ACTIVATE_NEW_CONSUMER_PROFILE_TITLE: 'MSG_ACTIVATE_NEW_CONSUMER_PROFILE_TITLE',
  MSG_ACTIVATE_NEW_CONSUMER_PROFILE_BODY: 'MSG_ACTIVATE_NEW_CONSUMER_PROFILE_BODY',
  MSG_ACTIVATE_NEW_CONSUMER_PROFILE_EXPIRED_BODY: 'MSG_ACTIVATE_NEW_CONSUMER_PROFILE_EXPIRED_BODY',
  // search dialog
  MSG_SEARCH: 'MSG_SEARCH',
  MSG_SEARCH_FOR_STRUCTURES: 'MSG_SEARCH_FOR_STRUCTURES',
  MSG_SEARCH_FOR_OPERATORS: 'MSG_SEARCH_FOR_OPERATORS',
  MSG_SEARCH_FOR_COLLABORATORS: 'MSG_SEARCH_FOR_COLLABORATORS',
  MSG_SEARCH_FOR_CUSTOMERS: 'MSG_SEARCH_FOR_CUSTOMERS',
  MSG_LOAD_MORE_ELEMENTS: 'MSG_LOAD_MORE_ELEMENTS',
  MSG_CREATE_NEW_PLACEHOLDER_USER: 'MSG_CREATE_NEW_PLACEHOLDER_USER',
  MSG_CREATE_NEW_PLACEHOLDER_USER_TITLE: 'MSG_CREATE_NEW_PLACEHOLDER_USER_TITLE',
  MSG_CREATE_NEW_PLACEHOLDER_USER_BODY: 'MSG_CREATE_NEW_PLACEHOLDER_USER_BODY',
  // word confirm dialog
  MSG_WORD_CONFIRM_DIALOG_TITLE: 'MSG_WORD_CONFIRM_DIALOG_TITLE',
  MSG_WORD_CONFIRM_DIALOG_BODY: 'MSG_WORD_CONFIRM_DIALOG_BODY',
  MSG_WORD_CONFIRM_FIELD_LABEL: 'MSG_WORD_CONFIRM_FIELD_LABEL',
  MSG_WORD_CONFIRM_CLOSE: 'MSG_WORD_CONFIRM_CLOSE',
  MSG_WORD_CONFIRM_INVALID: 'MSG_WORD_CONFIRM_INVALID',
  // fields
  MSG_LASTNAME: 'MSG_LASTNAME',
  MSG_NAME: 'MSG_NAME',
  MSG_COMPANY_NAME: 'MSG_COMPANY_NAME',
  MSG_FULLNAME: 'MSG_FULLNAME',
  MSG_ADDRESS: 'MSG_ADDRESS',
  MSG_PHONE: 'MSG_PHONE',
  MSG_MOBILE1: 'MSG_MOBILE1',
  MSG_MOBILE2: 'MSG_MOBILE2',
  MSG_EMAIL: 'E-Mail',
  MSG_EMAIL1: 'MSG_EMAIL1',
  MSG_EMAIL2: 'MSG_EMAIL2',
  MSG_EMAIL3: 'MSG_EMAIL3',
  MSG_EMAIL4: 'MSG_EMAIL4',
  MSG_PEC: 'MSG_PEC',
  MSG_WEBSITE: 'MSG_WEBSITE',
  MSG_OTP: 'MSG_OTP',

  //
  MSG_DETAILS: 'MSG_DETAILS',
  MSG_DETAILS_OWNED_BY_STRUCTURE: 'MSG_DETAILS_OWNED_BY_STRUCTURE',
  MSG_DETAILS_OWNED_BY_CUSTOMER: 'MSG_DETAILS_OWNED_BY_CUSTOMER',
  MSG_CLEAR: 'MSG_CLEAR',
  MSG_CLOSE: 'MSG_CLOSE',
  MSG_SAVE: 'MSG_SAVE',
  MSG_CANCEL: 'MSG_CANCEL',
  MSG_REQUEST_MODIFICATION: 'MSG_REQUEST_MODIFICATION',
  MSG_DAYS_IN_ADVANCE: 'MSG_DAYS_IN_ADVANCE',
  MSG_QUANTITY: 'MSG_QUANTITY',
  MSG_SEND: 'MSG_SEND',
  MSG_COPY_LINK: 'MSG_COPY_LINK',
  MSG_UNSHARE: 'MSG_UNSHARE',

  // ProfileType
  MSG_PROFILE_TYPE_NONE: 'MSG_PROFILE_TYPE_NONE',
  MSG_PROFILE_TYPE_CUSTOMER: 'MSG_PROFILE_TYPE_CUSTOMER',
  MSG_PROFILE_TYPE_OPERATOR_ADMIN: 'MSG_PROFILE_TYPE_OPERATOR_ADMIN',
  MSG_PROFILE_TYPE_OPERATOR_EXT: 'MSG_PROFILE_TYPE_OPERATOR_EXT',
  MSG_PROFILE_TYPE_OPERATOR_INT: 'MSG_PROFILE_TYPE_OPERATOR_INT',
  MSG_PROFILE_TYPE_STRUCTURE: 'MSG_PROFILE_TYPE_STRUCTURE',
  MSG_PROFILE_TYPE_TUTOR: 'MSG_PROFILE_TYPE_TUTOR',

  // ActionType
  MSG_ACTION_TYPE_VIEW_DOCUMENTS: 'MSG_ACTION_TYPE_VIEW_DOCUMENTS',
  MSG_ACTION_TYPE_DOWNLOAD_DOCUMENTS: 'MSG_ACTION_TYPE_DOWNLOAD_DOCUMENTS',
  MSG_ACTION_TYPE_CREATE_DOCUMENTS: 'MSG_ACTION_TYPE_CREATE_DOCUMENTS',
  MSG_ACTION_TYPE_UPDATE_DOCUMENTS: 'MSG_ACTION_TYPE_UPDATE_DOCUMENTS',
  MSG_ACTION_TYPE_ARCHIVE_DOCUMENTS: 'MSG_ACTION_TYPE_ARCHIVE_DOCUMENTS',
  MSG_ACTION_TYPE_DELETE_DOCUMENTS: 'MSG_ACTION_TYPE_DELETE_DOCUMENTS',
  MSG_ACTION_TYPE_VIEW_DOX: 'MSG_ACTION_TYPE_VIEW_DOX',
  MSG_ACTION_TYPE_DOWNLOAD_DOX: 'MSG_ACTION_TYPE_DOWNLOAD_DOX',
  MSG_ACTION_TYPE_CREATE_DOX: 'MSG_ACTION_TYPE_CREATE_DOX',
  MSG_ACTION_TYPE_UPDATE_DOX: 'MSG_ACTION_TYPE_UPDATE_DOX',
  MSG_ACTION_TYPE_DELETE_DOX: 'MSG_ACTION_TYPE_DELETE_DOX',

  // abbreviations
  MSG_PROFILE_TITLE_ABBREVIATION_ARCH: 'MSG_PROFILE_TITLE_ABBREVIATION_ARCH',
  MSG_PROFILE_TITLE_ABBREVIATION_AVV: 'MSG_PROFILE_TITLE_ABBREVIATION_AVV',
  MSG_PROFILE_TITLE_ABBREVIATION_DR: 'MSG_PROFILE_TITLE_ABBREVIATION_DR',
  MSG_PROFILE_TITLE_ABBREVIATION_ING: 'MSG_PROFILE_TITLE_ABBREVIATION_ING',
  MSG_PROFILE_TITLE_ABBREVIATION_PROF: 'MSG_PROFILE_TITLE_ABBREVIATION_PROF',

  // rest errors
  MSG_ERR_FORBIDDEN: 'MSG_ERR_FORBIDDEN',
  MSG_AN_ERROR_HAS_OCCURRED: 'MSG_AN_ERROR_HAS_OCCURRED',
  MSG_ERR_INVALID_EMAIL_OR_PWD_DSC: 'MSG_ERR_INVALID_EMAIL_OR_PWD_DSC',
  MSG_ERR_TREATMENT_CODE_UNIQUE_VIOLATION_DSC: 'MSG_ERR_TREATMENT_CODE_UNIQUE_VIOLATION_DSC',
  MSG_ERR_TREATMENT_CUSTOM_FIELD_NOT_UNIQUE_NAME_DSC: 'MSG_ERR_TREATMENT_CUSTOM_FIELD_NOT_UNIQUE_NAME_DSC',
  MSG_ERR_MISSING_DOX_WITH_RETENTION_DSC: 'ERR_MISSING_DOX_WITH_RETENTION_DSC',
  MSG_ERR_DUPLICATED_PERMISSION_DSC: 'MSG_ERR_DUPLICATED_PERMISSION_DSC',
  MSG_ERR_TREATMENT_IN_USE_DSC: 'MSG_ERR_TREATMENT_IN_USE_DSC',
  MSG_ERR_INVALID_TOKEN_DSC: 'MSG_ERR_INVALID_TOKEN_DSC',
  MSG_ERR_INVALID_PIN_DSC: 'MSG_ERR_INVALID_PIN_DSC',
  MSG_ERR_FEATURE_SET_LIMITED_DSC: 'MSG_ERR_FEATURE_SET_LIMITED_DSC',
  MSG_ERR_INVITATION_NOT_ACCEPTABLE_DSC: 'MSG_ERR_INVITATION_NOT_ACCEPTABLE_DSC',
  MSG_ERR_INVITATION_NOT_REVOKABLE_DSC: 'MSG_ERR_INVITATION_NOT_REVOKABLE_DSC',
  MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_ACKNOWLEDGMENT_DSC:
    'MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_ACKNOWLEDGMENT_DSC',
  MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_CONSENTS_DSC: 'MSG_ERR_MISSING_EXPLANATION_TO_AVOID_NEW_CONSENTS_DSC',
  MSG_ERR_MISSING_COMPANY_NAME_DSC: 'MSG_ERR_MISSING_COMPANY_NAME_DSC',
  // billing errors
  MSG_ERR_INVALID_COUPON_DSC: 'MSG_ERR_INVALID_COUPON_DSC',
  MSG_ERR_INVALID_PROMOTION_CODE_DSC: 'MSG_ERR_INVALID_PROMOTION_CODE_DSC',
  MSG_ERR_STRIPE_INVALID_CUSTOMER_DSC: 'MSG_ERR_STRIPE_INVALID_CUSTOMER_DSC',
  MSG_ERR_EMAIL_ALREADY_USED_DSC: 'MSG_ERR_EMAIL_ALREADY_USED_DSC',
  MSG_ERR_INVALID_FISCAL_CODE_DSC: 'MSG_ERR_INVALID_FISCAL_CODE_DSC',
  MSG_ERR_DUPLICATED_FISCAL_CODE_DSC: 'MSG_ERR_DUPLICATED_FISCAL_CODE_DSC',
  MSG_ERR_DUPLICATED_VAT_NUMBER_DSC: 'MSG_ERR_DUPLICATED_VAT_NUMBER_DSC',
  MSG_ERR_MISSING_MANDATORY_AGREEMENT_DSC: 'MSG_ERR_MISSING_MANDATORY_AGREEMENT_DSC',
  MSG_ERR_MISSING_PROFILE_TYPE_DSC: 'MSG_ERR_MISSING_PROFILE_TYPE_DSC',
  // payment method card errors
  MSG_ERR_INSUFFICIENT_FUNDS_DSC: 'MSG_ERR_INSUFFICIENT_FUNDS_DSC',
  MSG_ERR_LOST_CARD_DSC: 'MSG_ERR_LOST_CARD_DSC',
  MSG_ERR_STOLEN_CARD_DSC: 'MSG_ERR_STOLEN_CARD_DSC',
  MSG_ERR_CARD_DECLINED_DSC: 'MSG_ERR_CARD_DECLINED_DSC',
  MSG_ERR_EXPIRED_CARD_DSC: 'MSG_ERR_EXPIRED_CARD_DSC',
  MSG_ERR_INCORRECT_CVC_CODE_DSC: 'MSG_ERR_INCORRECT_CVC_CODE_DSC',
  MSG_ERR_PROCESSING_ERROR_DSC: 'MSG_ERR_PROCESSING_ERROR_DSC',
  MSG_ERR_INCORRECT_CARD_NUMBER_DSC: 'MSG_ERR_INCORRECT_CARD_NUMBER_DSC',
  MSG_ERR_REQUIRED_PAYMENT_METHOD_DSC: 'MSG_ERR_REQUIRED_PAYMENT_METHOD_DSC',
  MSG_ERR_CANNOT_CONFIR_PAYMENT_METHOD_DSC: 'MSG_ERR_CANNOT_CONFIR_PAYMENT_METHOD_DSC',
  MSG_ERR_TRIAL_ALREADY_USED_DSC: 'MSG_ERR_TRIAL_ALREADY_USED_DSC',
  MSG_ERR_DATA_PROCESSING_SUSPENDED_DSC: 'MSG_ERR_DATA_PROCESSING_SUSPENDED_DSC',
  // validation errors
  MSG_VAL_ERR_THERE_ARE_FORM_ERRORS: 'MSG_VAL_ERR_THERE_ARE_FORM_ERRORS',
  MSG_VAL_ERR_SOME_MANDATORY_CONSENTS_ARE_MISSING: 'MSG_VAL_ERR_SOME_MANDATORY_CONSENTS_ARE_MISSING',
  MSG_VAL_ERR_REQUIRED_FIELD: 'MSG_VAL_ERR_REQUIRED_FIELD',
  MSG_VAL_ERR_WRONG_MAIL_FORMAT: 'MSG_VAL_ERR_WRONG_MAIL_FORMAT',
  MSG_VAL_ERR_WRONG_PHONE_FORMAT: 'MSG_VAL_ERR_WRONG_PHONE_FORMAT',
  MSG_VAL_ERR_WRONG_WEBSITE_FORMAT: 'MSG_VAL_ERR_WRONG_WEBSITE_FORMAT',
  MSG_VAL_ERR_WRONG_FISCAL_CODE_FORMAT: 'MSG_VAL_ERR_WRONG_FISCAL_CODE_FORMAT',
  MSG_VAL_ERR_WRONG_VAT_NUMBER_FORMAT: 'MSG_VAL_ERR_WRONG_VAT_NUMBER_FORMAT',
  MSG_VAL_ERR_WRONG_SDI_CODE_FORMAT: 'MSG_VAL_ERR_WRONG_SDI_CODE_FORMAT',
  MSG_VAL_ERR_TOO_SHORT: 'MSG_VAL_ERR_TOO_SHORT',
  MSG_VAL_ERR_LENGTH_MUST_BE: 'MSG_VAL_ERR_LENGTH_MUST_BE',
  MSG_VAL_ERR_MAX_LENGTH_MUST_BE: 'MSG_VAL_ERR_MAX_LENGTH_MUST_BE',
  MSG_VAL_ERR_MIN_LENGTH_MUST_BE: 'MSG_VAL_ERR_MIN_LENGTH_MUST_BE',
  MSG_VAL_ERR_MAX_MUST_BE: 'MSG_VAL_ERR_MAX_MUST_BE',
  MSG_VAL_ERR_MIN_MUST_BE: 'MSG_VAL_ERR_MIN_MUST_BE',
  MSG_VAL_ERR_MUST_CONTAIN_UPPERCASE: 'MSG_VAL_ERR_MUST_CONTAIN_UPPERCASE',
  MSG_VAL_ERR_MUST_CONTAIN_NUMBER: 'MSG_VAL_ERR_MUST_CONTAIN_NUMBER',
  MSG_VAL_ERR_CANNOT_CONTAIN_SPACES: 'MSG_VAL_ERR_CANNOT_CONTAIN_SPACES',
  MSG_VAL_ERR_WRONG_PASSWORD_FORMAT: 'MSG_VAL_ERR_WRONG_PASSWORD_FORMAT',
  MSG_VAL_ERR_INVALID_DATE: 'MSG_VAL_ERR_INVALID_DATE',
  MSG_VAL_ERR_DUPLICATED_NAME: 'MSG_VAL_ERR_DUPLICATED_NAME',
  MSG_VAL_ERR_PASSOWRD_NOT_CONFIRMED: 'MSG_VAL_ERR_PASSOWRD_NOT_CONFIRMED',
  MSG_VAL_ERR_WRONG_DATE_RANGE: 'MSG_VAL_ERR_WRONG_DATE_RANGE',
  // action type errors
  MSG_ERR_ACTION_TYPE_NOT_ALLOWED: 'MSG_ERR_ACTION_TYPE_NOT_ALLOWED',
  MSG_ERR_SUBSCRIPTION_NOT_FOUNDED_PLAN: 'MSG_ERR_SUBSCRIPTION_NOT_FOUNDED_PLAN',
  // generic errors
  MSG_ERR_LOADING_PAGE_ERROR: 'MSG_ERR_LOADING_PAGE_ERROR',

  // treatments
  MSG_TREATMENT_ACTIVITIES_CODE: 'MSG_TREATMENT_ACTIVITIES_CODE',
  MSG_TREATMENT_FINALITY: 'MSG_TREATMENT_FINALITY',
  MSG_TREATMENT_LEGAL_BASE: 'MSG_TREATMENT_LEGAL_BASE',
  MSG_TREATMENT_RETENTION: 'MSG_TREATMENT_RETENTION',
  MSG_TREATMENT_NOT_ACTIVE: 'MSG_TREATMENT_NOT_ACTIVE',
  MSG_TREATMENT_ACTIVITY_DETAILS: 'MSG_TREATMENT_ACTIVITY_DETAILS',
  MSG_NEW_TREATMENT: 'MSG_NEW_TREATMENT',
  MSG_NEW_TREATMENT_TOOLTIP_TEXT: 'MSG_NEW_TREATMENT_TOOLTIP_TEXT',
  MSG_SHOW_ONLY_ACTIVE_TREATMENTS: 'MSG_SHOW_ONLY_ACTIVE_TREATMENTS',
  MSG_TREATMENT_DISABLE: 'MSG_TREATMENT_DISABLE',
  MSG_TREATMENT_ENABLE: 'MSG_TREATMENT_ENABLE',
  MSG_TREATMENT_DELETE: 'MSG_TREATMENT_DELETE',
  MSG_TREATMENT_BASIC_FEATURES: 'MSG_TREATMENT_BASIC_FEATURES',
  MSG_TREATMENT_INTRO: 'MSG_TREATMENT_INTRO',
  MSG_TREATMENT_ATTENTION_WARNING: 'MSG_TREATMENT_ATTENTION_WARNING',
  MSG_TREATMENT_PRIVACY_EXPERT_RECOMMENDATION: 'MSG_TREATMENT_PRIVACY_EXPERT_RECOMMENDATION',
  MSG_TREATMENT_INTERNAL_CODE: 'MSG_TREATMENT_INTERNAL_CODE',
  MSG_TREATMENT_SHORT_DESCRIPTION: 'MSG_TREATMENT_SHORT_DESCRIPTION',
  MSG_TREATMENT_PURPOSE: 'MSG_TREATMENT_PURPOSE',
  MSG_TREATMENT_LEGAL_BASES_TITLE: 'MSG_TREATMENT_LEGAL_BASES_TITLE',
  MSG_TREATMENT_ATTENTION: 'MSG_TREATMENT_ATTENTION',
  MSG_TREATMENT_LEGAL_BASE_DESCRIPTION: 'MSG_TREATMENT_LEGAL_BASE_DESCRIPTION',
  MSG_TREATMENT_DATA_RETENTION: 'MSG_TREATMENT_DATA_RETENTION',
  MSG_TREATMENT_DATA_MAINTAINED: 'MSG_TREATMENT_DATA_MAINTAINED',
  MSG_TREATMENT_STORAGE_PERIOD: 'MSG_TREATMENT_STORAGE_PERIOD',
  MSG_TREATMENT_SETTINGS_AFFECT_DOX: 'MSG_TREATMENT_SETTINGS_AFFECT_DOX',
  MSG_TREATMENT_NOTIFY_END_OF_CONSERVATION: 'MSG_TREATMENT_NOTIFY_END_OF_CONSERVATION',
  MSG_TREATMENT_ACTION_AT_CONSERVATION_END: 'MSG_TREATMENT_ACTION_AT_CONSERVATION_END',
  MSG_TREATMENT_MANDATORY_FIELDS: 'MSG_TREATMENT_MANDATORY_FIELDS',
  MSG_TREATMENT_OPTIONAL_FIELDS: 'MSG_TREATMENT_OPTIONAL_FIELDS',
  MSG_TREATMENT_FIELD_NAME: 'MSG_TREATMENT_FIELD_NAME',
  MSG_TREATMENT_FIELD_DESCRIPTION: 'MSG_TREATMENT_FIELD_DESCRIPTION',
  MSG_TREATMENT_FIELD_MANDATORY: 'MSG_TREATMENT_FIELD_MANDATORY',
  MSG_TREATMENT_CONFIRM_DELETE_TITLE: 'MSG_TREATMENT_CONFIRM_DELETE_TITLE',
  MSG_TREATMENT_CONFIRM_DELETE_TEXT: 'MSG_TREATMENT_CONFIRM_DELETE_TEXT',
  MSG_TREATMENT_CONFIRM_DISABLE_TITLE: 'MSG_TREATMENT_CONFIRM_DISABLE_TITLE',
  MSG_TREATMENT_CONFIRM_DISABLE_TEXT: 'MSG_TREATMENT_CONFIRM_DISABLE_TEXT',
  MSG_TREATMENT_CONFIRM_ENABLE_TITLE: 'MSG_TREATMENT_CONFIRM_ENABLE_TITLE',
  MSG_TREATMENT_CONFIRM_ENABLE_TEXT: 'MSG_TREATMENT_CONFIRM_ENABLE_TEXT',
  MSG_TREATMENT_NO_SELECTABLE_ITEMS: 'MSG_TREATMENT_NO_SELECTABLE_ITEMS',
  MSG_TREATMENT_DELETE_CUSTOM_FIELD_TITLE: 'MSG_TREATMENT_DELETE_CUSTOM_FIELD_TITLE',
  MSG_TREATMENT_DELETE_CUSTOM_FIELD_TEXT: 'MSG_TREATMENT_DELETE_CUSTOM_FIELD_TEXT',

  // Legal base
  MSG_LEGAL_BASE_EXPLICIT_CONSENT: 'MSG_LEGAL_BASE_EXPLICIT_CONSENT',
  MSG_LEGAL_BASE_CONTRACTUAL_OBLIGATION: 'MSG_LEGAL_BASE_CONTRACTUAL_OBLIGATION',
  MSG_LEGAL_BASE_LEGAL_OBLIGATION: 'MSG_LEGAL_BASE_LEGAL_OBLIGATION',
  MSG_LEGAL_BASE_VITAL_INTEREST: 'MSG_LEGAL_BASE_VITAL_INTEREST',
  MSG_LEGAL_BASE_PUBLIC_INTEREST: 'MSG_LEGAL_BASE_PUBLIC_INTEREST',
  MSG_LEGAL_BASE_LEGITIMATE_INTEREST: 'MSG_LEGAL_BASE_LEGITIMATE_INTEREST',

  MSG_RETENTION_END_BEHAVIOR_NONE: 'MSG_RETENTION_END_BEHAVIOR_NONE',
  MSG_RETENTION_END_BEHAVIOR_TERMINATE: 'MSG_RETENTION_END_BEHAVIOR_TERMINATE',
  MSG_RETENTION_END_BEHAVIOR_NOTIFY: 'MSG_RETENTION_END_BEHAVIOR_NOTIFY',
  MSG_RETENTION_END_BEHAVIOR_TERMINATE_AND_NOTIFY: 'MSG_RETENTION_END_BEHAVIOR_TERMINATE_AND_NOTIFY',

  MSG_CUSTOMER_VISIBILITY_RULES: 'MSG_CUSTOMER_VISIBILITY_RULES',
  MSG_CUSTOMER_VISIBILITY_RULES_NONE: 'MSG_CUSTOMER_VISIBILITY_RULES_NONE',
  MSG_CUSTOMER_VISIBILITY_RULES_DOCUMENTS_RETENTION: 'MSG_CUSTOMER_VISIBILITY_RULES_DOCUMENTS_RETENTION',
  MSG_CUSTOMER_VISIBILITY_RULES_COMPLETE_DURATION: 'MSG_CUSTOMER_VISIBILITY_RULES_COMPLETE_DURATION',
  MSG_CUSTOMER_VISIBILITY_RULES_INFO: 'MSG_CUSTOMER_VISIBILITY_RULES_INFO',

  MSG_RETENTION_DURATION_NONE: 'MSG_RETENTION_DURATION_NONE',
  MSG_RETENTION_DURATION_DAY: 'MSG_RETENTION_DURATION_DAY',
  MSG_RETENTION_DURATION_WEEK: 'MSG_RETENTION_DURATION_WEEK',
  MSG_RETENTION_DURATION_MONTH: 'MSG_RETENTION_DURATION_MONTH',
  MSG_RETENTION_DURATION_YEAR: 'MSG_RETENTION_DURATION_YEAR',

  MSG_RETENTION_DURATION_DAYS: 'MSG_RETENTION_DURATION_DAYS',
  MSG_RETENTION_DURATION_WEEKS: 'MSG_RETENTION_DURATION_WEEKS',
  MSG_RETENTION_DURATION_MONTHS: 'MSG_RETENTION_DURATION_MONTHS',
  MSG_RETENTION_DURATION_YEARS: 'MSG_RETENTION_DURATION_YEARS',

  MSG_CONTRACT_ACCEPTANCE_STATUS_NONE: 'MSG_CONTRACT_ACCEPTANCE_STATUS_NONE',
  MSG_CONTRACT_ACCEPTANCE_STATUS_TO_ACCEPT: 'MSG_CONTRACT_ACCEPTANCE_STATUS_TO_ACCEPT',
  MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_AND_CONFIRMED:
    'MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_AND_CONFIRMED',
  MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_BUT_NOT_CONFIRMED:
    'MSG_CONTRACT_ACCEPTANCE_STATUS_IMPLICITY_ACCEPTED_BUT_NOT_CONFIRMED',
  MSG_CONTRACT_ACCEPTANCE_STATUS_ACCEPTED: 'MSG_CONTRACT_ACCEPTANCE_STATUS_ACCEPTED',

  MSG_CONTRACT_PRIVACY_POLICY_NOT_AVAILABLE: 'MSG_CONTRACT_PRIVACY_POLICY_NOT_AVAILABLE',
  MSG_CONTRACT_TERMS_OF_USE_NOT_AVAILABLE: 'MSG_CONTRACT_TERMS_OF_USE_NOT_AVAILABLE',
  MSG_CONTRACT_DATA_PROCESSOR_NOT_AVAILABLE: 'MSG_CONTRACT_DATA_PROCESSOR_NOT_AVAILABLE',
  MSG_CONTRACT_NOT_AVAILABLE: 'MSG_CONTRACT_NOT_AVAILABLE',
  MSG_CONTRACT_PRIVACY_POLICY_FOR_CONSUMER_TITLE: 'MSG_CONTRACT_PRIVACY_POLICY_FOR_CONSUMER_TITLE',
  MSG_CONTRACT_TERMS_OF_USE_FOR_CONSUMER_TITLE: 'MSG_CONTRACT_TERMS_OF_USE_FOR_CONSUMER_TITLE',
  MSG_CONTRACT_PRIVACY_POLICY_FOR_OPERATOR_TITLE: 'MSG_CONTRACT_PRIVACY_POLICY_FOR_OPERATOR_TITLE',
  MSG_CONTRACT_TERMS_OF_USE_FOR_OPERATOR_TITLE: 'MSG_CONTRACT_TERMS_OF_USE_FOR_OPERATOR_TITLE',
  MSG_CONTRACT_DATA_PROCESSOR_FOR_OPERATOR_TITLE: 'MSG_CONTRACT_DATA_PROCESSOR_FOR_OPERATOR_TITLE',

  MSG_TREATED_FIELDS_GROUP_IDENTIFICATION: 'MSG_TREATED_FIELDS_GROUP_IDENTIFICATION',
  MSG_TREATED_FIELDS_GROUP_RECOGNITION: 'MSG_TREATED_FIELDS_GROUP_RECOGNITION',
  MSG_TREATED_FIELDS_GROUP_RESIDENCE: 'MSG_TREATED_FIELDS_GROUP_RESIDENCE',
  MSG_TREATED_FIELDS_GROUP_CONTACT: 'MSG_TREATED_FIELDS_GROUP_CONTACT',

  MSG_TREATED_FIELDS_LASTNAME: 'MSG_TREATED_FIELDS_LASTNAME',
  MSG_TREATED_FIELDS_NAME: 'MSG_TREATED_FIELDS_NAME',
  MSG_TREATED_FIELDS_GENDER: 'MSG_TREATED_FIELDS_GENDER',
  MSG_TREATED_FIELDS_BIRTH_DATE: 'MSG_TREATED_FIELDS_BIRTH_DATE',
  MSG_TREATED_FIELDS_BIRTH_COUNTRY: 'MSG_TREATED_FIELDS_BIRTH_COUNTRY',
  MSG_TREATED_FIELDS_BIRTH_PLACE: 'MSG_TREATED_FIELDS_BIRTH_PLACE',
  MSG_TREATED_FIELDS_FISCAL_CODE: 'MSG_TREATED_FIELDS_FISCAL_CODE',
  MSG_TREATED_FIELDS_AVATAR: 'MSG_TREATED_FIELDS_AVATAR',
  MSG_TREATED_FIELDS_COUNTRY: 'MSG_TREATED_FIELDS_COUNTRY',
  MSG_TREATED_FIELDS_CITY: 'MSG_TREATED_FIELDS_CITY',
  MSG_TREATED_FIELDS_PROVINCE: 'MSG_TREATED_FIELDS_PROVINCE',
  MSG_TREATED_FIELDS_STREET: 'MSG_TREATED_FIELDS_STREET',
  MSG_TREATED_FIELDS_STREET_NUMBER: 'MSG_TREATED_FIELDS_STREET_NUMBER',
  MSG_TREATED_FIELDS_ZIP: 'MSG_TREATED_FIELDS_ZIP',
  MSG_TREATED_FIELDS_PHONE: 'MSG_TREATED_FIELDS_PHONE',
  MSG_TREATED_FIELDS_MOBILE_PHONE: 'MSG_TREATED_FIELDS_MOBILE_PHONE',
  MSG_TREATED_FIELDS_EMAIL: 'MSG_TREATED_FIELDS_EMAIL',

  MSG_TREATED_FIELDS_TEXT1: 'MSG_TREATED_FIELDS_TEXT1',
  MSG_TREATED_FIELDS_TEXT2: 'MSG_TREATED_FIELDS_TEXT2',

  MSG_TREATED_CUSTOM_FIELD_TITLE: 'MSG_TREATED_CUSTOM_FIELD_TITLE',

  MSG_FIELDNAME_AVATAR: 'MSG_FIELDNAME_AVATAR',
  MSG_FIELDNAME_CITY: 'MSG_FIELDNAME_CITY',
  MSG_FIELDNAME_COUNTRY: 'MSG_FIELDNAME_COUNTRY',
  MSG_FIELDNAME_PROVINCE: 'MSG_FIELDNAME_PROVINCE',
  MSG_FIELDNAME_STREET: 'MSG_FIELDNAME_STREET',
  MSG_FIELDNAME_STREET_NUMBER: 'MSG_FIELDNAME_STREET_NUMBER',
  MSG_FIELDNAME_ZIP: 'MSG_FIELDNAME_ZIP',
  MSG_FIELDNAME_EMAIL1: 'MSG_FIELDNAME_EMAIL1',
  MSG_FIELDNAME_EMAIL2: 'MSG_FIELDNAME_EMAIL2',
  MSG_FIELDNAME_EMAIL3: 'MSG_FIELDNAME_EMAIL3',
  MSG_FIELDNAME_EMAIL4: 'MSG_FIELDNAME_EMAIL4',
  MSG_FIELDNAME_MOBILE_PHONE1: 'MSG_FIELDNAME_MOBILE_PHONE1',
  MSG_FIELDNAME_MOBILE_PHONE2: 'MSG_FIELDNAME_MOBILE_PHONE2',
  MSG_FIELDNAME_PHONE: 'MSG_FIELDNAME_PHONE',

  MSG_ACCOUNT_ATTACH_DIALOG_TITLE: 'MSG_ACCOUNT_ATTACH_DIALOG_TITLE',
  MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_TITLE: 'MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_TITLE',
  MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_BODY: 'MSG_ACCOUNT_ATTACH_DIALOG_IMPOSSIBLE_BODY',

  MSG_CONTRACT_TYPE_NONE: 'MSG_CONTRACT_TYPE_NONE',
  MSG_CONTRACT_TYPE_PRIVACY_INFO: 'MSG_CONTRACT_TYPE_PRIVACY_INFO',
  MSG_CONTRACT_TYPE_TERMS_OF_USE: 'MSG_CONTRACT_TYPE_TERMS_OF_USE',
  MSG_CONTRACT_TYPE_DATA_PROCESSOR: 'MSG_CONTRACT_TYPE_DATA_PROCESSOR',

  MSG_CONTRACT_VERSION_STATE_NONE: 'MSG_CONTRACT_VERSION_STATE_NONE',
  MSG_CONTRACT_VERSION_STATE_PUBLISHED: 'MSG_CONTRACT_VERSION_STATE_PUBLISHED',
  MSG_CONTRACT_VERSION_STATE_OBSOLETE: 'MSG_CONTRACT_VERSION_STATE_OBSOLETE',
  MSG_CONTRACT_VERSION_STATE_DRAFT: 'MSG_CONTRACT_VERSION_STATE_DRAFT',

  MSG_CONTRACT_VERSION_VIEWER_PAGE_DOWNLOAD_COMMAND: 'MSG_CONTRACT_VERSION_VIEWER_PAGE_DOWNLOAD_COMMAND',
  MSG_CONTRACT_VERSION_VIEWER_PAGE_SHOW_OBSOLETE_COMMAND: 'MSG_CONTRACT_VERSION_VIEWER_PAGE_SHOW_OBSOLETE_COMMAND',
  MSG_CONTRACT_VERSION_VIEWER_PAGE_SUSPEND_DATA_PROCESSING: 'MSG_CONTRACT_VERSION_VIEWER_PAGE_SUSPEND_DATA_PROCESSING',
  MSG_CONTRACT_VERSION_VIEWER_PAGE_ACTIVATE_DATA_PROCESSING:
    'MSG_CONTRACT_VERSION_VIEWER_PAGE_ACTIVATE_DATA_PROCESSING',
  MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE: 'MSG_CONTRACT_VERSION_VIEWER_CONSENT_DATE',
  MSG_CONTRACT_VERSION_VIEWER_CHANGES: 'MSG_CONTRACT_VERSION_VIEWER_CONSENT_CHANGES',
  MSG_CONTRACT_VERSION_VIEWER_NEW_ACKNOWLEDGMENT_REQUIRED: 'MSG_CONTRACT_VERSION_VIEWER_NEW_ACKNOWLEDGMENT_REQUIRED',
  MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE: 'MSG_CONTRACT_VERSION_LIST_DIALOG_TITLE',
  MSG_CONTRACT_VERSION_ADD_MISSING_INFO_BUTTON: 'MSG_CONTRACT_VERSION_ADD_MISSING_INFO_BUTTON',

  MSG_CONSENT_CHANGE: 'MSG_CONSENT_CHANGE',
  MSG_CONSENT_CHANGE_REVOKE_ALL_PRIVACY_POLICY_CONSENTS_CONFIRM:
    'MSG_CONSENT_CHANGE_REVOKE_ALL_PRIVACY_POLICY_CONSENTS_CONFIRM',
  MSG_CONSENT_CHANGE_REVOKE_ALL_CONTRACT_CONSENTS_CONFIRM: 'MSG_CONSENT_CHANGE_REVOKE_ALL_CONTRACT_CONSENTS_CONFIRM',
  MSG_CONSENT_CHANGE_ALL_MANDATORY_CONSENTS_NEEDED: 'MSG_CONSENT_CHANGE_ALL_MANDATORY_CONSENTS_NEEDED',

  MSG_CONTRACT_NEW: 'MSG_CONTRACT_NEW',
  MSG_CONTRACT_TITLE: 'MSG_CONTRACT_TITLE',
  MSG_CONTRACT_ID: 'MSG_CONTRACT_ID',
  MSG_CONTRACT_NAME: 'MSG_CONTRACT_NAME',
  MSG_CONTRACT_TYPE: 'MSG_CONTRACT_TYPE',
  MSG_CONTRACT_VERSION: 'MSG_CONTRACT_VERSION',
  MSG_CONTRACT_DATE: 'MSG_CONTRACT_DATE',
  MSG_CONTRACT_DOWNLOAD: 'MSG_CONTRACT_DOWNLOAD',

  MSG_CONTRACT_NEW_VERSION: 'MSG_CONTRACT_NEW_VERSION',
  MSG_CONTRACT_PRIVACY_POLICY: 'MSG_CONTRACT_PRIVACY_POLICY',
  MSG_CONTRACT_TERMS_OF_USE: 'MSG_CONTRACT_TERMS_OF_USE',
  MSG_CONTRACT_DATA_PROCESSOR: 'MSG_CONTRACT_DATA_PROCESSOR',
  MSG_CONTRACT_STATE: 'MSG_CONTRACT_STATE',

  MSG_CONTRACT_ADD_SECTION: 'MSG_CONTRACT_ADD_SECTION',
  MSG_CONTRACT_PUBLISH: 'MSG_CONTRACT_PUBLISH',
  MSG_CONTRACT_DELETE_VERSION: 'MSG_CONTRACT_DELETE_VERSION',
  MSG_CONTRACT_DELETE_SECTION: 'MSG_CONTRACT_DELETE_SECTION',
  MSG_CONTRACT_EDIT_VERSION_TITLE: 'MSG_CONTRACT_EDIT_VERSION_TITLE',
  MSG_CONTRACT_SECTION_TITLE: 'MSG_CONTRACT_SECTION_TITLE',
  MSG_CONTRACT_INFO_TITLE: 'MSG_CONTRACT_INFO_TITLE',
  MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO: 'MSG_CONTRACT_MAIN_PRIVACY_POLICY_INFO',
  MSG_CONTRACT_MAIN_PRIVACY_POLICY_TEXT: 'MSG_CONTRACT_MAIN_PRIVACY_POLICY_TEXT',
  MSG_CONTRACT_TREATMENTS_LIST: 'MSG_CONTRACT_TREATMENTS_LIST',
  MSG_CONTRACT_ADD_DATA_TREATMENT: 'MSG_CONTRACT_ADD_DATA_TREATMENT',

  MSG_CONTRACT_ANNOTATIONS_VISIBLE: 'MSG_CONTRACT_ANNOTATIONS_VISIBLE',
  MSG_CONTRACT_NOTES_VERSION: 'MSG_CONTRACT_NOTES_VERSION',
  MSG_CONTRACT_INTERNAL_ANNOTATIONS: 'MSG_CONTRACT_INTERNAL_ANNOTATIONS',
  MSG_CONTRACT_NEW_ACK_REQUIRED: 'MSG_CONTRACT_NEW_ACK_REQUIRED',
  MSG_CONTRACT_NEW_ACK_REQUIRED_INFO: 'MSG_CONTRACT_NEW_ACK_REQUIRED_INFO',
  MSG_CONTRACT_WHY_NO_NEW_ACK: 'MSG_CONTRACT_WHY_NO_NEW_ACK_INFO',
  MSG_CONTRACT_NEW_CONSENTS_REQUIRED: 'MSG_CONTRACT_NEW_CONSENTS_REQUIRED',
  MSG_CONTRACT_NEW_CONSENTS_REQUIRED_INFO: 'MSG_CONTRACT_NEW_CONSENTS_REQUIRED_INFO',
  MSG_CONTRACT_WHY_NO_NEW_CONSENTS: 'MSG_CONTRACT_WHY_NO_NEW_CONSENTS',

  MSG_CONSENT_ACCEPTANCE_MODE_MANDATORY_EXPLANATION_PRIVACY_POLICY:
    'MSG_CONSENT_ACCEPTANCE_MODE_MANDATORY_EXPLANATION_PRIVACY_POLICY',
  MSG_CONSENT_ACCEPTANCE_MODE_MANDATOTY_EXPLANATION_OTHER: 'MSG_CONSENT_ACCEPTANCE_MODE_MANDATOTY_EXPLANATION_OTHER',
  MSG_CONSENT_I_HAVE_READ: 'MSG_CONSENT_I_HAVE_READ',
  MSG_CONSENT_AGREE: 'MSG_CONSENT_AGREE',
  MSG_CONSENT_NOT_AGREE: 'MSG_CONSENT_NOT_AGREE',
  MSG_CONSENT_ACCEPT: 'MSG_CONSENT_ACCEPT',
  MSG_CONSENT_NOT_ACCEPT: 'MSG_CONSENT_NOT_ACCEPT',
  MSG_CONSENT_MISSING_INFORMATIONS_TITLE: 'MSG_CONSENT_MISSING_INFORMATIONS_TITLE',
  MSG_CONSENT_PUSH_TO_ADD_MISSING_INFORMATIONS: 'MSG_CONSENT_PUSH_TO_ADD_MISSING_INFORMATIONS',

  MSG_CONTRACT_DELETE_VERSION_CONFIRM: 'MSG_CONTRACT_DELETE_VERSION_CONFIRM',
  MSG_CONTRACT_DELETE_SECTION_CONFIRM: 'MSG_CONTRACT_DELETE_SECTION_CONFIRM',
  MSG_CONTRACT_DATA_PROCESSING_SECTION_ABSENT: 'MSG_CONTRACT_DATA_PROCESSING_SECTION_ABSENT',
  MSG_CONTRACT_PUBLICATION: 'MSG_CONTRACT_PUBLICATION',
  MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION: 'MSG_ARE_YOU_SURE_TO_PUBLISH_VERSION',
  MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITH_NEW_CONSENTS_REQUIRED:
    'MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITH_NEW_CONSENTS_REQUIRED',
  MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED:
    'MSG_ARE_YOU_SURE_TO_PUBLISH_PRIVACY_POLICY_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED',
  MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITH_NEW_CONSENTS_REQUIRED:
    'MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITH_NEW_CONSENTS_REQUIRED',
  MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED:
    'MSG_ARE_YOU_SURE_TO_PUBLISH_GENERIC_VERSION_WITHOUT_NEW_CONSENTS_REQUIRED',
  // Archive
  MSG_PERSONAL_ARCHIVE_ROOT_NAME: 'MSG_PERSONAL_ARCHIVE_ROOT_NAME',
  MSG_COMPANY_ARCHIVE_ROOT_NAME: 'MSG_COMPANY_ARCHIVE_ROOT_NAME',
  MSG_CUSTOMER_ARCHIVE_ROOT_NAME: 'MSG_CUSTOMER_ARCHIVE_ROOT_NAME',

  // Doxes menu
  MSG_DOXES_MENU_RELOAD_BUTTON: 'MSG_DOXES_MENU_RELOAD_BUTTON',
  MSG_DOXES_MENU_PIN_BUTTON: 'MSG_DOXES_MENU_PIN_BUTTON',
  MSG_DOXES_MENU_SHOW_BUTTON: 'MSG_DOXES_MENU_SHOW_BUTTON',
  MSG_DOXES_SELECTION_MODE_LABEL: 'MSG_DOXES_SELECTION_MODE_LABEL',
  MSG_DOXES_SELECTION_MODE_SINGLE: 'MSG_DOXES_SELECTION_MODE_SINGLE',
  MSG_DOXES_SELECTION_MODE_MULTIPLE: 'MSG_DOXES_SELECTION_MODE_MULTIPLE',
  MSG_DOXES_SELECTION_MODE_HIERARCHICAL: 'MSG_DOXES_SELECTION_MODE_HIERARCHICAL',

  // Dox commands
  MSG_DOX_COMMAND_SHOW_AUTHORIZATIONS: 'MSG_DOX_COMMAND_SHOW_AUTHORIZATIONS',
  MSG_DOX_COMMAND_CREATE_NEW_DOX: 'MSG_DOX_COMMAND_CREATE_NEW_DOX',
  MSG_DOX_COMMAND_CREATE_NEW_ORGANIZED_DOX: 'MSG_DOX_COMMAND_CREATE_NEW_ORGANIZED_DOX',
  MSG_DOX_COMMAND_MODIFY_DOX: 'MSG_DOX_COMMAND_MODIFY_DOX',
  MSG_DOX_COMMAND_MOVE_DOX: 'MSG_DOX_COMMAND_MOVE_DOX',
  MSG_DOX_COMMAND_DELETE_DOX: 'MSG_DOX_COMMAND_DELETE_DOX',
  MSG_DOX_COMMAND_SHOW_DOX_CHRONOLOGY: 'MSG_DOX_COMMAND_SHOW_DOX_CHRONOLOGY',
  MSG_DOX_COMMAND_SHOW_DOX_DETAILS: 'MSG_DOX_COMMAND_SHOW_DOX_DETAILS',
  MSG_DOX_COMMAND_DOWNLOAD_DOX: 'MSG_DOX_COMMAND_DOWNLOAD_DOX',
  MSG_DOX_COMMAND_TERMINATE_RETENTION: 'MSG_DOX_COMMAND_TERMINATE_RETENTION',
  MSG_DOX_COMMAND_DELIVER_DOX: 'MSG_DOX_COMMAND_DELIVER_DOX',
  MSG_DOX_COMMAND_SHARE_DOX: 'MSG_DOX_COMMAND_SHARE_DOX',

  // DocumentsTableFiltersBar
  MSG_DOCS_FILTERS_BAR_BUTTON: 'MSG_DOCS_FILTERS_BAR_BUTTON',
  MSG_DOCS_FILTERS_BAR_PLACEHOLDER: 'MSG_DOCS_FILTERS_BAR_PLACEHOLDER',
  MSG_DOCS_FILTER_EDITED_FROM_KEY: 'MSG_DOCS_FILTER_EDITED_FROM_KEY',
  MSG_DOCS_FILTER_EDITED_TO_KEY: 'MSG_DOCS_FILTER_EDITED_TO_KEY',
  MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY: 'MSG_DOCS_FILTER_STRUCTURE_OWNER_KEY',
  MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY: 'MSG_DOCS_FILTER_OPERATOR_AUTHOR_KEY',
  MSG_DOCS_FILTER_ONLY_DRAFTS_KEY: 'MSG_DOCS_FILTER_ONLY_DRAFTS_KEY',
  MSG_DOCS_FILTER_CREATED_BY_ME_KEY: 'MSG_DOCS_FILTER_CREATED_BY_ME_KEY',
  MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY: 'MSG_DOCS_FILTER_SHOW_OBSOLETE_KEY',

  // DocumentsFiltersDialog
  MSG_DOCS_FILTER_DIALOG_TITLE: 'MSG_DOCS_FILTER_DIALOG_TITLE',

  // Document table
  MSG_DOCS_TABLE_HEADER_NAME: 'MSG_DOCS_TABLE_HEADER_NAME',
  MSG_DOCS_TABLE_HEADER_AUTHOR: 'MSG_DOCS_TABLE_HEADER_AUTHOR',
  MSG_DOCS_TABLE_HEADER_DATE: 'MSG_DOCS_TABLE_HEADER_DATE',

  // Archive Page - command bar
  MSG_ARCHIVE_PAGE_SHARED_ARCHIVE_TITLE: 'MSG_ARCHIVE_PAGE_SHARED_ARCHIVE_TITLE',
  MSG_ARCHIVE_PAGE_ARCHIVE_FOR_CUSTOMER_TITLE: 'MSG_ARCHIVE_PAGE_ARCHIVE_FOR_CUSTOMER_TITLE',
  MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE: 'MSG_COMMAND_CREATE_DOCUMENT_FROM_TEMPLATE',
  MSG_COMMAND_CREATE_DOCUMENT: 'MSG_COMMAND_CREATE_DOCUMENT',
  MSG_COMMAND_UPLOAD_DOCUMENT: 'MSG_COMMAND_UPLOAD_DOCUMENT',

  // Document commands
  MSG_DOC_COMMAND_ASSOCIATE_TO_DOX: 'MSG_DOC_COMMAND_ASSOCIATE_TO_DOX',
  MSG_DOC_COMMAND_SHOW_AUTHORIZATIONS: 'MSG_DOC_COMMAND_SHOW_AUTHORIZATIONS',
  MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE: 'MSG_DOC_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE',
  MSG_DOC_COMMAND_DOWNLOAD: 'MSG_DOC_COMMAND_DOWNLOAD',
  MSG_DOC_COMMAND_MARK_AS_OBSOLETE: 'MSG_DOC_COMMAND_MARK_AS_OBSOLETE',
  MSG_DOC_COMMAND_SHOW_CHRONOLOGY: 'MSG_DOC_COMMAND_SHOW_CHRONOLOGY',
  MSG_DOC_COMMAND_MODIFY: 'MSG_DOC_COMMAND_MODIFY',
  MSG_DOC_COMMAND_DELETE: 'MSG_DOC_COMMAND_DELETE',
  MSG_DOC_COMMAND_ARCHIVE: 'MSG_DOC_COMMAND_ARCHIVE',
  MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP: 'MSG_DOC_COMMAND_ADD_DOCUMENT_TO_DESKTOP',
  MSG_DOC_COMMAND_SET_ANONYMOUS_STATE: 'MSG_DOC_COMMAND_SET_ANONYMOUS_STATE',
  MSG_DOC_COMMAND_UNSET_ANONYMOUS_STATE: 'MSG_DOC_COMMAND_UNSET_ANONYMOUS_STATE',

  // Document command confirms
  MSG_DOX_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE_CONFIRM: 'MSG_DOX_COMMAND_REMOVE_FROM_STRUCTURE_ARCHIVE_CONFIRM',
  MSG_DOCUMENT_DELETE_CONFIRM: 'MSG_DOCUMENT_DELETE_CONFIRM',
  MSG_DOCUMENT_MARK_AS_OBSOLETE_CONFIRM: 'MSG_DOCUMENT_MARK_AS_OBSOLETE_CONFIRM',
  MSG_DOX_COMMAND_TERMINATE_RETENTION_CONFIRM: 'MSG_DOX_COMMAND_TERMINATE_RETENTION_CONFIRM',
  MSG_DOX_COMMAND_DELIVER_DOX_CONFIRM: 'MSG_DOX_COMMAND_DELIVER_DOX_CONFIRM',

  // Document commands results
  MSG_DOCUMENT_DELETED_SUCCESSFULLY: 'MSG_DOCUMENT_DELETED_SUCCESSFULLY',
  MSG_DOCUMENT_ARCHIVED_SUCCESSFULLY: 'MSG_DOCUMENT_ARCHIVED_SUCCESSFULLY',
  MSG_DOCUMENT_SAVED_SUCCESSFULLY: 'MSG_DOCUMENT_SAVED_SUCCESSFULLY',
  MSG_DOCUMENT_NOW_IS_ANONYMOUS: 'MSG_DOCUMENT_NOW_IS_ANONYMOUS',
  MSG_DOCUMENT_NOW_IS_NOT_ANONYMOUS: 'MSG_DOCUMENT_NOW_IS_NOT_ANONYMOUS',
  MSG_DOCUMENT_MARKED_AS_OBSOLETE_SUCCESSFULLY: 'MSG_DOCUMENT_MARKED_AS_OBSOLETE_SUCCESSFULLY',
  MSG_RETENTION_TERMINATED_SUCCESFULLY: 'MSG_RETENTION_TERMINATED_SUCCESFULLY',
  MSG_SPECIFIC_RETENTION_TERMINATION_NOT_POSSIBLE_FROM_DOX: 'MSG_SPECIFIC_RETENTION_TERMINATION_NOT_POSSIBLE_FROM_DOX',
  MSG_DOX_DELIVERED_SUCCESSFULLY: 'MSG_DOX_DELIVERED_SUCCESSFULLY',
  MSG_DOX_CREATED_SUCCESSFULLY: 'MSG_DOX_CREATED_SUCCESSFULLY',
  MSG_DOX_UPDATED_SUCCESSFULLY: 'MSG_DOX_UPDATED_SUCCESSFULLY',
  MSG_DOX_DELETED_SUCCESSFULLY: 'MSG_DOX_DELETED_SUCCESSFULLY',

  // Document info
  MSG_DOCUMENT_SOURCE_PDX_EDITOR: 'MSG_DOCUMENT_SOURCE_PDX_EDITOR',
  MSG_DOCUMENT_SOURCE_LOADED_FILE: 'MSG_DOCUMENT_SOURCE_LOADED_FILE',
  MSG_DOCUMENT_ORIGINAL_FILE_NAME: 'MSG_DOCUMENT_ORIGINAL_FILE_NAME',

  // DocumentEditor commands
  MSG_DOCUMENT_EDITOR_COMMAND_INFO: 'MSG_DOCUMENT_EDITOR_COMMAND_INFO',
  MSG_DOCUMENT_EDITOR_COMMAND_DOX: 'MSG_DOCUMENT_EDITOR_COMMAND_DOX',
  MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT: 'MSG_DOCUMENT_EDITOR_COMMAND_CREATE_DRAFT',
  MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT: 'MSG_DOCUMENT_EDITOR_COMMAND_EDIT_DRAFT',
  MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT: 'MSG_DOCUMENT_EDITOR_COMMAND_CLOSE_DRAFT',
  MSG_DOCUMENT_EDITOR_COMMAND_CONTENT: 'MSG_DOCUMENT_EDITOR_COMMAND_CONTENT',
  MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS: 'MSG_DOCUMENT_EDITOR_COMMAND_REVISIONS',
  MSG_DOCUMENT_EDITOR_COMMAND_RETENTION: 'MSG_DOCUMENT_EDITOR_COMMAND_RETENTION',
  MSG_DOCUMENT_EDITOR_COMMAND_UNDO: 'MSG_DOCUMENT_EDITOR_COMMAND_UNDO',
  MSG_DOCUMENT_EDITOR_COMMAND_REDO: 'MSG_DOCUMENT_EDITOR_COMMAND_REDO',
  MSG_DOCUMENT_EDITOR_COMMAND_SAVE: 'MSG_DOCUMENT_EDITOR_COMMAND_SAVE',
  MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE: 'MSG_DOCUMENT_EDITOR_COMMAND_ARCHIVE',

  // DocumentEditor revision labels
  MSG_DOCUMENT_EDITOR_REVISION_NAME_LABEL: 'MSG_DOCUMENT_REVISION_NAME_LABEL',
  MSG_DOCUMENT_EDITOR_REVISION_EDITED_AT_LABEL: 'MSG_DOCUMENT_REVISION_EDITED_AT_LABEL',
  MSG_DOCUMENT_EDITOR_REVISION_CREATOR_IDENTITY_LABEL: 'MSG_DOCUMENT_REVISION_CREATOR_IDENTITY_LABEL',
  MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_LABEL: 'MSG_DOCUMENT_REVISION_MIMETYPE_LABEL',
  MSG_DOCUMENT_EDITOR_REVISION_ARCHIVED_AT_LABEL: 'MSG_DOCUMENT_EDITOR_REVISION_ARCHIVED_AT_LABEL',

  // DocumentEditor revision values
  MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_UNKNOWN: 'MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_UNKNOWN',
  MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_PERSONALDOX: 'MSG_DOCUMENT_EDITOR_REVISION_MIMETYPE_PERSONALDOX',

  // DocumentEditor dox
  MSG_DOCUMENT_EDITOR_DOX_OF: 'MSG_DOCUMENT_EDITOR_REVISION_DOX_OF',
  MSG_DOCUMENT_EDITOR_NO_DOX: 'MSG_DOCUMENT_EDITOR_REVISION_NO_DOX',

  // DocumentEditor content
  MSG_DOCUMENT_EDITOR_NO_REVISION_SELECTED: 'MSG_DOCUMENT_EDITOR_NO_REVISION_SELECTED',
  MSG_DOCUMENT_EDITOR_DOWNLOAD_ERROR: 'MSG_DOCUMENT_EDITOR_DOWNLOAD_ERROR',
  MSG_DOCUMENT_EDITOR_NOT_AVAILABLE_VIEWER: 'MSG_DOCUMENT_EDITOR_NOT_AVAILABLE_VIEWER',
  MSG_DOCUMENT_EDITOR_INFO: 'MSG_DOCUMENT_EDITOR_INFO',
  MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME: 'MSG_DOCUMENT_EDITOR_NEW_INTERNAL_DOCUMENT_NAME',
  MSG_DOCUMENT_EDITOR_TRANSMISSION_IN_PROGRESS: 'MSG_DOCUMENT_EDITOR_TRANSMITTING',
  MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS: 'MSG_DOCUMENT_EDITOR_DRAFT_PREPARATION_IN_PROGRESS',

  // DocumentEditor retention
  MSG_DOCUMENT_EDITOR_RETENTION_FINALITY_LABEL: 'MSG_DOCUMENT_EDITOR_RETENTION_FINALITY_LABEL',
  MSG_DOCUMENT_EDITOR_RETENTION_START_AT_LABEL: 'MSG_DOCUMENT_EDITOR_RETENTION_START_AT_LABEL',
  MSG_DOCUMENT_EDITOR_RETENTION_END_AT_LABEL: 'MSG_DOCUMENT_EDITOR_RETENTION_END_AT_LABEL',
  MSG_DOCUMENT_EDITOR_RETENTION_INTERVAL_DIALOG_TITLE: 'MSG_DOCUMENT_EDITOR_RETENTION_INTERVAL_DIALOG_TITLE',

  // DocumentEditor upload
  MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL: 'MSG_DOCUMENT_UPLOAD_INPUT_FILE_LABEL',
  MSG_DOCUMENT_UPLOAD_FILE_NAME_LABEL: 'MSG_DOCUMENT_UPLOAD_FILE_NAME_LABEL',
  MSG_DOCUMENT_UPLOAD_FILE_SIZE_LABEL: 'MSG_DOCUMENT_UPLOAD_FILE_SIZE_LABEL',
  MSG_DOCUMENT_CHANGES_WILL_BE_LOST: 'MSG_DOCUMENT_CHANGES_WILL_BE_LOST',

  // Document Dialogs
  MSG_DOCUMENT_RETENTION_DIALOG_TITLE: 'MSG_DOCUMENT_RETENTION_DIALOG_TITLE',
  MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DETACHED_BUT_VISIBLE_BODY:
    'MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DETACHED_BUT_VISIBLE_BODY',
  MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_OWNER_BODY:
    'MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_OWNER_BODY',
  MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_TARGET_BY_DELIVER_BODY:
    'MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_VISIBLE_TO_TARGET_BY_DELIVER_BODY',
  MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DELETED_BODY: 'MSG_DOCUMENT_RETENTION_DIALOG_TERMINATE_DELETED_BODY',
  MSG_DOCUMENT_SHOW_TITLE: 'MSG_DOCUMENT_SHOW_TITLE',
  MSG_DOCUMENT_NOT_AVAILABLE: 'MSG_DOCUMENT_NOT_AVAILABLE',
  MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER: 'MSG_DOCUMENT_WRONG_ASSISTED_CUSTUMER',

  // Dox Show Dialogs
  MSG_DOX_SHOW_TITLE: 'MSG_DOX_SHOW_TITLE',
  MSG_DOX_NOT_AVAILABLE: 'MSG_DOX_NOT_AVAILABLE',
  MSG_DOX_WRONG_ASSISTED_CUSTUMER: 'MSG_DOX_WRONG_ASSISTED_CUSTUMER',

  // DoxDetails
  MSG_DOX_DETAILS_CONTENT_LABEL: 'MSG_DOX_DETAILS_CONTENT_LABEL',
  MSG_DOX_DETAILS_DELIVER_SECTION_LABEL: 'MSG_DOX_DETAILS_DELIVER_SECTION_LABEL',
  MSG_DOX_DETAILS_RETENTION_SECTION_LABEL: 'MSG_DOX_DETAILS_RETENTION_SECTION_LABEL',
  MSG_DOX_DETAILS_PATH_LABEL: 'MSG_DOX_DETAILS_PATH_LABEL',
  MSG_DOX_DETAILS_NAME_LABEL: 'MSG_DOX_DETAILS_NAME_LABEL',
  MSG_DOX_DETAILS_NOTES_LABEL: 'MSG_DOX_DETAILS_NOTES_LABEL',
  MSG_DOX_DETAILS_CONTENT_START_AT_LABEL: 'MSG_DOX_DETAILS_CONTENT_START_AT_LABEL',
  MSG_DOX_DETAILS_CONTENT_END_AT_LABEL: 'MSG_DOX_DETAILS_CONTENT_END_AT_LABEL',
  MSG_DOX_DETAILS_CONTENT_DOX_COUNT_LABEL: 'MSG_DOX_DETAILS_CONTENT_DOX_COUNT_LABEL',
  MSG_DOX_DETAILS_CONTENT_DOCUMENT_COUNT_LABEL: 'MSG_DOX_DETAILS_CONTENT_DOCUMENT_COUNT_LABEL',
  MSG_DOX_DETAILS_CONTENT_DRAFT_DOCUMENT_COUNT_LABEL: 'MSG_DOX_DETAILS_CONTENT_DRAFT_DOCUMENT_COUNT_LABEL',
  MSG_DOX_DETAILS_DELIVERED_AT_LABEL: 'MSG_DOX_DETAILS_DELIVERED_AT_LABEL',
  MSG_DOX_DETAILS_FINALITY_LABEL: 'MSG_DOX_DETAILS_FINALITY_LABEL',
  MSG_DOX_DETAILS_RETENTION_RULES_LABEL: 'MSG_DOX_DETAILS_RETENTION_RULES_LABEL',
  MSG_DOX_DETAILS_RETENTION_START_AT_LABEL: 'MSG_DOX_DETAILS_RETENTION_START_AT_LABEL',
  MSG_DOX_DETAILS_RETENTION_END_AT_LABEL: 'MSG_DOX_DETAILS_RETENTION_END_AT_LABEL',
  MSG_DOX_DETAILS_RETENTION_NOTICE_AT_LABEL: 'MSG_DOX_DETAILS_RETENTION_NOTICE_AT_LABEL',
  MSG_DOX_DETAILS_RETENTION_END_TERMINATE_LABEL: 'MSG_DOX_DETAILS_RETENTION_END_TERMINATE_LABEL',

  // DoxDetailsDialog
  MSG_DOX_DETAILS_DIALOG_TITLE: 'MSG_DOX_DETAILS_DIALOG_TITLE',

  // DoxSelectorDialog
  MSG_DOX_SELECTOR_DIALOG_TITLE: 'MSG_DOX_SELECTOR_DIALOG_TITLE',
  MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM: 'MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENTS_CONFIRM',
  MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM: 'MSG_DOX_SELECTOR_DIALOG_REMOVING_DOCUMENT_CONFIRM',
  MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY: 'MSG_DOX_SELECTOR_DIALOG_CHANGES_MADE_SUCCESSFULLY',

  // DoxEditorDialog
  MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE: 'MSG_DOX_EDITOR_DIALOG_NEW_DOX_TITLE',
  MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE: 'MSG_DOX_EDITOR_DIALOG_MODIFY_DOX_TITLE',

  // DoxEditor
  MSG_DOX_EDITOR_NEW_DOX_NAME: 'MSG_DOX_EDITOR_NEW_DOX_NAME',
  MSG_DOX_EDITOR_PARENT_LABEL: 'MSG_DOX_EDITOR_PARENT_LABEL',
  MSG_DOX_EDITOR_NAME_LABEL: 'MSG_DOX_EDITOR_NAME_LABEL',
  MSG_DOX_EDITOR_NOTES_LABEL: 'MSG_DOX_EDITOR_NOTES_LABEL',
  MSG_DOX_EDITOR_RETENTION_PLACEHOLDER: 'MSG_DOX_EDITOR_RETENTION_PLACEHOLDER',
  MSG_DOX_EDITOR_RETENTION_TITLE: 'MSG_DOX_EDITOR_RETENTION_TITLE',
  MSG_DOX_EDITOR_RETENTION_DESCRIPTION: 'MSG_DOX_EDITOR_RETENTION_DESCRIPTION',
  MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL: 'MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL',
  MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL: 'MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL',
  MSG_DOX_EDITOR_RETENTION_END_AT_INFO: 'MSG_DOX_EDITOR_RETENTION_END_AT_INFO',

  // DoxShare
  MSG_DOX_SHARE_DIALOG_TITLE: 'MSG_DOX_SHARE_DIALOG_TITLE',
  MSG_DOX_SHARE_EMAIL: 'MSG_DOX_SHARE_EMAIL',
  MSG_DOX_SHARE_PIN: 'MSG_DOX_SHARE_PIN',
  MSG_DOX_SHARE_PIN_DISABLED: 'MSG_DOX_SHARE_PIN_DISABLED',
  MSG_DOX_SHARE_GENERATE_PIN: 'MSG_DOX_SHARE_GENERATE_PIN',
  MSG_DOX_SHARE_REMOVE_PIN: 'MSG_DOX_SHARE_REMOVE_PIN',
  MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_TITLE: 'MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_TITLE',
  MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_BODY: 'MSG_DOX_SHARE_REMOVE_PIN_CONFIRM_BODY',
  MSG_DOX_SHARE_USE_PIN: 'MSG_DOX_SHARE_USE_PIN',
  MSG_DOX_SHARE_PIN_INFO: 'MSG_DOX_SHARE_PIN_INFO',
  MSG_DOX_SHARE_UNSHARE_CONFIRM_TITLE: 'MSG_DOX_SHARE_UNSHARE_CONFIRM_TITLE',
  MSG_DOX_SHARE_UNSHARE_CONFIRM_BODY: 'MSG_DOX_SHARE_UNSHARE_CONFIRM_BODY',
  MSG_DOX_SHARE_INVITATION_SENDED: 'MSG_DOX_SHARE_INVITATION_SENDED',
  MSG_DOX_SHARE_PERMISSION_NOT_ACTIVE: 'MSG_DOX_SHARE_PERMISSION_NOT_ACTIVE',
  MSG_DOX_SHARE_PERMISSION_ACTIVE_FOR_DAYS: 'MSG_DOX_SHARE_PERMISSION_ACTIVE_FOR_DAYS',
  MSG_DOX_SHARE_PERMISSION_FUTURE_FOR_DAYS: 'MSG_DOX_SHARE_PERMISSION_FUTURE_FOR_DAYS',
  MSG_DOX_SHARE_AUTHORIZATIONS_TITLE: 'MSG_DOX_SHARE_AUTHORIZATIONS_TITLE',
  MSG_DOX_SHARE_AUTHORIZATIONS_VIEW: 'MSG_DOX_SHARE_AUTHORIZATIONS_VIEW',
  MSG_DOX_SHARE_AUTHORIZATIONS_VIEW_INFO: 'MSG_DOX_SHARE_AUTHORIZATIONS_VIEW_INFO',
  MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD: 'MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD',
  MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD_INFO: 'MSG_DOX_SHARE_AUTHORIZATIONS_DOWNLOAD_INFO',
  MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS: 'MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS',
  MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS_INFO: 'MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOCUMENTS_INFO',
  MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX: 'MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX',
  MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX_INFO: 'MSG_DOX_SHARE_AUTHORIZATIONS_EDIT_DOX_INFO',

  // DoxDeleteConfirm
  MSG_DOX_DELETE_CONFIRM_TITLE: 'MSG_DOX_DELETE_CONFIRM_TITLE',
  MSG_DOX_DELETE_CONFIRM_WORD_LABEL: 'MSG_DOX_DELETE_CONFIRM_WORD_LABEL',
  MSG_DOX_DELETE_CONFIRM_WORD: 'MSG_DOX_DELETE_CONFIRM_WORD',
  MSG_DOX_DELETE_CONFIRM_ALERT: 'MSG_DOX_DELETE_CONFIRM_ALERT',
  MSG_DOX_DELETE_CONFIRM_ALERT2: 'MSG_DOX_DELETE_CONFIRM_ALERT2',

  // AuthorizationsChangeDialog
  MSG_DOCUMENTS_VISIBILITY_CHANGES: 'MSG_DOCUMENTS_VISIBILITY_CHANGES',

  // RetentionRules
  MSG_RETENTION_RULE_NONE: 'MSG_RETENTION_RULE_NONE',
  MSG_RETENTION_RULE_CENTRALIZED: 'MSG_RETENTION_RULE_CENTRALIZED',
  MSG_RETENTION_RULE_SPECIFIC: 'MSG_RETENTION_RULE_SPECIFIC',

  // Authorizations
  MSG_AUTHORIZATIONS_VISUALIZATION_ALLOWED: 'MSG_AUTHORIZATIONS_VISUALIZATION_ALLOWED',
  MSG_AUTHORIZATIONS_VISUALIZATION_EXPIRED: 'MSG_AUTHORIZATIONS_VISUALIZATION_EXPIRED',
  MSG_AUTHORIZATIONS_VISUALIZATION_REVOKED: 'MSG_AUTHORIZATIONS_VISUALIZATION_REVOKED',
  MSG_AUTHORIZATIONS_VISUALIZATION_SUSPENDED: 'MSG_AUTHORIZATIONS_VISUALIZATION_SUSPENDED',
  MSG_AUTHORIZATIONS_VISUALIZATION_UNKNOWN: 'MSG_AUTHORIZATIONS_VISUALIZATION_UNKNOWN',

  MSG_AUTHORIZATIONS_ADD_STRUCT_LABEL: 'MSG_AUTHORIZATIONS_ADD_STRUCT_LABEL',
  MSG_AUTHORIZATIONS_ADD_STRUCT_BUTTON: 'MSG_AUTHORIZATIONS_ADD_STRUCT_BUTTON',

  MSG_AUTHORIZATION_ADDED_MESSAGE: 'MSG_AUTHORIZATION_ADDED_MESSAGE',

  // AuthorizationsDialog
  MSG_AUTHORIZATION_DELETE_DIALOG_TITLE: 'MSG_AUTHORIZATION_DELETE_DIALOG_TITLE',
  MSG_AUTHORIZATION_DELETE_DIALOG_CONTENT: 'MSG_AUTHORIZATION_DELETE_DIALOG_CONTENT',

  MSG_AUTHORIZATION_DELETED_MESSAGE: 'MSG_AUTHORIZATION_DELETED_MESSAGE',

  // AuthorizationDetails dialog
  MSG_AUTHORIZATION_DETAILS_DIALOG_TITLE: 'MSG_AUTHORIZATION_DETAILS_DIALOG_TITLE',
  MSG_AUTHORIZATION_DETAILS_DIALOG_MESSAGE: 'MSG_AUTHORIZATION_DETAILS_DIALOG_MESSAGE',

  // AuthorizationsChangeDialog
  MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_LABEL: 'MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_LABEL',
  MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_LABEL: 'MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_LABEL',
  MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_INFO: 'MSG_AUTHORIZATIONS_CHANGE_LOST_AUTH_INFO',
  MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_INFO: 'MSG_AUTHORIZATIONS_CHANGE_GAINED_AUTH_INFO',
  MSG_AUTHORIZATION_RENEW_CONFIRM_TITLE: 'MSG_AUTHORIZATION_RENEW_CONFIRM_TITLE',
  MSG_AUTHORIZATION_RENEW_CONFIRM_BODY: 'MSG_AUTHORIZATION_RENEW_CONFIRM_BODY',
  MSG_AUTHORIZATION_RENEWAL_CONFIRM_TITLE: 'MSG_AUTHORIZATION_RENEWAL_CONFIRM_TITLE',
  MSG_AUTHORIZATION_RENEWAL_CONFIRM_BODY: 'MSG_AUTHORIZATION_RENEWAL_CONFIRM_BODY',

  // LoadMore component
  MSG_LOAD_MORE_NO_MORE_TO_LOAD: 'MSG_LOAD_MORE_NO_MORE_TO_LOAD',
  MSG_LOAD_MORE_LOADING_MORE: 'MSG_LOAD_MORE_LOADING_MORE',

  // Account info details
  MSG_ACCOUNT_DETAILS_TITLE: 'MSG_ACCOUNT_DETAILS_TITLE',

  // Notifications
  MSG_NOTIFICATIONS_TITLE: 'MSG_NOTIFICATIONS_TITLE',
  MSG_NOTIFICATIONS_ARCHIVE_TITLE: 'MSG_NOTIFICATIONS_ARCHIVE_TITLE',
  MSG_NOTIFICATIONS_ARCHIVE_LINK: 'MSG_NOTIFICATIONS_ARCHIVE_LINK',
  MSG_NOTIFICATIONS_SETTINGS_LINK: 'MSG_NOTIFICATIONS_SETTINGS_LINK',
  MSG_NOTIFICATIONS_ARCHIVE_ALL_BUTTON: 'MSG_NOTIFICATIONS_ARCHIVE_ALL_BUTTON',
  // NotificationsFilters keys
  MSG_NOTIFICATIONS_FILTER_FROM_KEY: 'MSG_NOTIFICATIONS_FILTER_FROM_KEY',
  MSG_NOTIFICATIONS_FILTER_TO_KEY: 'MSG_NOTIFICATIONS_FILTER_TO_KEY',
  MSG_NOTIFICATIONS_FILTER_STRUCTURE_KEY: 'MSG_NOTIFICATIONS_FILTER_STRUCTURE_KEY',
  MSG_NOTIFICATIONS_FILTER_OPERATOR_KEY: 'MSG_NOTIFICATIONS_FILTER_OPERATOR_KEY',
  MSG_NOTIFICATIONS_FILTER_ARGUMENT: 'MSG_NOTIFICATIONS_FILTER_ARGUMENT',
  // Notifications tooltip
  MSG_NOTIFICATION_TOOLTIP_SHOW_DOSSIER: 'MSG_NOTIFICATION_TOOLTIP_SHOW_DOSSIER',
  MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT: 'MSG_NOTIFICATION_TOOLTIP_SHOW_DOCUMENT',
  MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS: 'MSG_NOTIFICATION_TOOLTIP_SHOW_DATA_PROCESSING_SETTINGS',
  MSG_NOTIFICATION_TOOLTIP_SHOW_CONTRACT: 'MSG_NOTIFICATION_TOOLTIP_SHOW_CONTRACT',
  MSG_NOTIFICATION_TOOLTIP_SHOW_CONSENTS: 'MSG_NOTIFICATION_TOOLTIP_SHOW_CONSENTS',
  MSG_NOTIFICATION_TOOLTIP_SHOW_DOX: 'MSG_NOTIFICATION_TOOLTIP_SHOW_DOX',
  MSG_NOTIFICATION_TOOLTIP_SEND_INVITATION: 'MSG_NOTIFICATION_TOOLTIP_SEND_INVITATION',
  MSG_NOTIFICATION_TOOLTIP_ACCEPT_INVITATION: 'MSG_NOTIFICATION_TOOLTIP_ACCEPT_INVITATION',
  MSG_NOTIFICATION_TOOLTIP_SHOW_COLLABORATOR_CARD: 'MSG_NOTIFICATION_TOOLTIP_SHOW_COLLABORATOR_CARD',
  // Notifications argument
  MSG_NOTIFICATION_ARGUMENT_LABEL: 'MSG_NOTIFICATION_ARGUMENT_LABEL',
  MSG_NOTIFICATION_ARGUMENT_NONE: 'MSG_NOTIFICATION_ARGUMENT_NONE',
  MSG_NOTIFICATION_ARGUMENT_MESSAGE: 'MSG_NOTIFICATION_ARGUMENT_MESSAGE',
  MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT: 'MSG_NOTIFICATION_ARGUMENT_PROFILE_MANAGEMENT',
  MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT: 'MSG_NOTIFICATION_ARGUMENT_ACCOUNT_MANAGEMENT',
  MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS: 'MSG_NOTIFICATION_ARGUMENT_PDX_CONTRACTS',
  MSG_NOTIFICATION_ARGUMENT_DOSSIER: 'MSG_NOTIFICATION_ARGUMENT_DOSSIER',
  MSG_NOTIFICATION_ARGUMENT_DOCUMENT: 'MSG_NOTIFICATION_ARGUMENT_DOCUMENT',
  MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS: 'MSG_NOTIFICATION_ARGUMENT_PRIVACY_SETTINGS',
  MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY: 'MSG_NOTIFICATION_ARGUMENT_PRIVACY_POLICY',
  MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT: 'MSG_NOTIFICATION_ARGUMENT_ARCHIVE_MANAGEMENT',
  MSG_NOTIFICATION_ARGUMENT_INVITATION: 'MSG_NOTIFICATION_ARGUMENT_INVITATION',
  // Notifications body
  MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION:
    'MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_SUSPENSION',
  MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION:
    'MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_SUSPENSION',
  MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_SUSPENSION: 'MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_SUSPENSION',
  MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION: 'MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_SUSPENSION',
  MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION: 'MSG_NOTIFICATION_BODY_UPCOMING_CUSTOMER_PROFILE_DELETION',
  MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION:
    'MSG_NOTIFICATION_BODY_UPCOMING_OPERATOR_AUTO_PROFILE_DELETION',
  MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM:
    'MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_SYSTEM',
  MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM:
    'MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_SYSTEM',
  MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER: 'MSG_NOTIFICATION_BODY_CUSTOMER_PROFILE_DELETION_BY_OWNER',
  MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER:
    'MSG_NOTIFICATION_BODY_OPERATOR_AUTO_PROFILE_DELETION_BY_OWNER',
  MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER:
    'MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OWNER',
  MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN:
    'MSG_NOTIFICATION_BODY_COLLABORATOR_PROFILE_DELETION_BY_OPERATOR_ADMIN',
  MSG_NOTIFICATION_BODY_UPCOMING_USER_DELETION: 'MSG_NOTIFICATION_BODY_UPCOMING_USER_DELETION',
  MSG_NOTIFICATION_BODY_USER_DELETION_BY_SYSTEM: 'MSG_NOTIFICATION_BODY_USER_DELETION_BY_SYSTEM',
  MSG_NOTIFICATION_BODY_USER_DELETION_BY_OWNER: 'MSG_NOTIFICATION_BODY_USER_DELETION_BY_OWNER',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_TERMS_OF_USE_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_DATA_PROCESSOR_UPDATED_BY_EXIDEA_WITH_NO_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_DOX_DELIVERED: 'MSG_NOTIFICATION_BODY_DOX_DELIVERED',
  MSG_NOTIFICATION_BODY_DOX_RECEIVED: 'MSG_NOTIFICATION_BODY_DOX_RECEIVED',
  MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER:
    'MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_CUSTOMER',
  MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE:
    'MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_CREATED_BY_STRUCTURE',
  MSG_NOTIFICATION_BODY_DOCUMENT_VIEWED: 'MSG_NOTIFICATION_BODY_DOCUMENT_VIEWED',
  MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_DOWNLOADED: 'MSG_NOTIFICATION_BODY_DOCUMENT_REVISION_DOWNLOADED',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_GRANTED: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_GRANTED',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_UPDATED: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_UPDATED',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_DELETED: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_DELETED',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_SUSPENDED: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_SUSPENDED',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_RESTORED: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_RESTORED',
  MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER:
    'MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_CUSTOMER',
  MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR:
    'MSG_NOTIFICATION_BODY_UPCOMING_DATA_PROCESSING_EXPIRATION_OPERATOR',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_CUSTOMER',
  MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_OPERATOR: 'MSG_NOTIFICATION_BODY_DATA_PROCESSING_EXPIRED_OPERATOR',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE1:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE1',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE2:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_BREAKING_CHANGE2',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT:
    'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_UPDATED_BY_STRUCTURE_WITH_NO_BREAKING_CHANGE_AND_NEW_SECTIONS_WITH_CONSENT',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED1: 'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED1',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED2: 'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED2',
  MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED3: 'MSG_NOTIFICATION_BODY_PRIVACY_POLICY_CONSENT_UPDATED3',
  MSG_NOTIFICATION_BODY_PROFILE_ATTACHED: 'MSG_NOTIFICATION_BODY_PROFILE_ATTACHED',
  MSG_NOTIFICATION_BODY_PROFILE_DETACHED: 'MSG_NOTIFICATION_BODY_PROFILE_DETACHED',
  MSG_NOTIFICATION_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION:
    'MSG_NOTIFICATION_BODY_SUBSCRIPTION_TRIAL_UPCOMING_EXPIRATION',
  MSG_NOTIFICATION_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION:
    'MSG_NOTIFICATION_BODY_UPCOMING_DOCUMENT_RETENTION_EXPIRATION',
  MSG_NOTIFICATION_BODY_DOCUMENT_RETENTION_EXPIRED: 'MSG_NOTIFICATION_BODY_DOCUMENT_RETENTION_EXPIRED',
  MSG_NOTIFICATION_BODY_UPCOMING_DOX_RETENTION_EXPIRATION: 'MSG_NOTIFICATION_BODY_UPCOMING_DOX_RETENTION_EXPIRATION',
  MSG_NOTIFICATION_BODY_DOX_RETENTION_EXPIRED: 'MSG_NOTIFICATION_BODY_DOX_RETENTION_EXPIRED',
  MSG_NOTIFICATION_BODY_INVITATION_PERMITTED: 'MSG_NOTIFICATION_BODY_INVITATION_PERMITTED',
  MSG_NOTIFICATION_BODY_INVITATION_RECEIVED_COLLABORATE: 'MSG_NOTIFICATION_BODY_INVITATION_RECEIVED_COLLABORATE',
  MSG_NOTIFICATION_BODY_INVITATION_ACCEPTED_COLLABORATE: 'MSG_NOTIFICATION_BODY_INVITATION_ACCEPTED_COLLABORATE',
  // Notifications show resources dialogs
  MSG_NOTIFICATION_SHOW_DOX_TITLE: 'MSG_NOTIFICATION_SHOW_DOX_TITLE',
  MSG_NOTIFICATION_SHOW_DOX_NOT_AVAILABLE: 'MSG_NOTIFICATION_SHOW_DOX_NOT_AVAILABLE',
  MSG_NOTIFICATION_SHOW_DOX_WRONG_ASSISTED_CUSTUMER: 'MSG_NOTIFICATION_SHOW_DOX_WRONG_ASSISTED_CUSTUMER',

  // Profile details editor
  MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT: 'MSG_PROFILE_EDITOR_NOT_AVAILABLE_ACCOUNT',
  MSG_PROFILE_EDITOR_CONSUMER_TITLE: 'MSG_PROFILE_EDITOR_CONSUMER_TITLE',
  MSG_PROFILE_EDITOR_ABOUT_CONSUMER_TITLE: 'MSG_PROFILE_EDITOR_ABOUT_CONSUMER_TITLE',
  MSG_PROFILE_EDITOR_BUSINESS_TITLE: 'MSG_PROFILE_EDITOR_BUSINESS_TITLE',
  MSG_PROFILE_EDITOR_STRUCTURE_TITLE: 'MSG_PROFILE_EDITOR_STRUCTURE_TITLE',
  MSG_PROFILE_EDITOR_COLLABORATOR_TITLE: 'MSG_PROFILE_EDITOR_COLLABORATOR_TITLE',
  MSG_PROFILE_EDITOR_ABOUT_COLLABORATOR_TITLE: 'MSG_PROFILE_EDITOR_ABOUT_COLLABORATOR_TITLE',
  MSG_PROFILE_EDITOR_PRESENTATION_TITLE: 'MSG_PROFILE_EDITOR_PRESENTATION_TITLE',
  MSG_PROFILE_EDITOR_PRESENTATION_SELECT_IMAGE_BUTTON: 'MSG_PROFILE_EDITOR_PRESENTATION_SELECT_IMAGE_BUTTON',
  MSG_PROFILE_EDITOR_PRESENTATION_REMOVE_IMAGE_BUTTON: 'MSG_PROFILE_EDITOR_PRESENTATION_REMOVE_IMAGE_BUTTON',
  MSG_PROFILE_EDITOR_PRESENTATION_DEFAULT_PROFILE_LABEL: 'MSG_PROFILE_EDITOR_PRESENTATION_DEFAULT_PROFILE_LABEL',
  MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL: 'MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_LABEL',
  MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_NONE: 'MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_NONE',
  MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_COLLABORATORS:
    'MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_COLLABORATORS',
  MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_ALL: 'MSG_PROFILE_EDITOR_PRESENTATION_PROFILE_VISIBILITY_ALL',
  MSG_PROFILE_EDITOR_PRESENTATION_ALIAS_LABEL: 'MSG_PROFILE_EDITOR_PRESENTATION_ALIAS_LABEL',
  MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL: 'MSG_PROFILE_EDITOR_PRESENTATION_TITLE_LABEL',
  MSG_PROFILE_EDITOR_IDENTITY_TITLE: 'MSG_PROFILE_EDITOR_IDENTITY_TITLE',
  MSG_PROFILE_EDITOR_IDENTITY_WARNING: 'MSG_PROFILE_EDITOR_IDENTITY_WARNING',
  MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY: 'MSG_PROFILE_EDITOR_IDENTITY_BIRTH_COUNTRY',
  MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY: 'MSG_PROFILE_EDITOR_IDENTITY_BIRTH_CITY',
  MSG_PROFILE_EDITOR_IDENTITY_VAT: 'MSG_PROFILE_EDITOR_IDENTITY_VAT',
  MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_TITLE: 'MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_TITLE',
  MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_BODY: 'MSG_PROFILE_EDITOR_IDENTITY_CONFIRM_BODY',
  MSG_PROFILE_EDITOR_RESIDENCE_TITLE: 'MSG_PROFILE_EDITOR_RESIDENCE_TITLE',
  MSG_PROFILE_EDITOR_RESIDENCE_STRUCTURE_TITLE: 'MSG_PROFILE_EDITOR_RESIDENCE_STRUCTURE_TITLE',
  MSG_PROFILE_EDITOR_CONTACTS_TITLE: 'MSG_PROFILE_EDITOR_CONTACTS_TITLE',
  MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_1: 'MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_1',
  MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_2: 'MSG_PROFILE_EDITOR_CONTACTS_MOBILE_PHONE_2',
  MSG_PROFILE_EDITOR_CONTACTS_EMAIL_1: 'MSG_PROFILE_EDITOR_CONTACTS_EMAIL_1',
  MSG_PROFILE_EDITOR_CONTACTS_EMAIL_2: 'MSG_PROFILE_EDITOR_CONTACTS_EMAIL_2',
  MSG_PROFILE_EDITOR_CONTACTS_EMAIL_3: 'MSG_PROFILE_EDITOR_CONTACTS_EMAIL_3',
  MSG_PROFILE_EDITOR_CONTACTS_EMAIL_4: 'MSG_PROFILE_EDITOR_CONTACTS_EMAIL_4',
  MSG_PROFILE_EDITOR_CONTACTS_WEBSITE: 'MSG_PROFILE_EDITOR_CONTACTS_WEBSITE',
  MSG_PROFILE_EDITOR_BILLING_TITLE: 'MSG_PROFILE_EDITOR_BILLING_TITLE',
  MSG_PROFILE_EDITOR_BILLING_INFO: 'MSG_PROFILE_EDITOR_BILLING_INFO',
  MSG_PROFILE_EDITOR_BILLING_SDI: 'MSG_PROFILE_EDITOR_BILLING_SDI',
  MSG_PROFILE_EDITOR_BILLING_PEC: 'MSG_PROFILE_EDITOR_BILLING_PEC',
  MSG_PROFILE_EDITOR_OPERATOR_CLOSE_PLACEHOLDER_ACCOUNT: 'MSG_PROFILE_EDITOR_OPERATOR_CLOSE_PLACEHOLDER_ACCOUNT',
  MSG_PROFILE_EDITOR_OPERATOR_CLOSE_COLLABORATOR_PROFILE: 'MSG_PROFILE_EDITOR_OPERATOR_CLOSE_COLLABORATOR_PROFILE',
  MSG_PROFILE_EDITOR_CLOSE_DIALOG_ADVERTISING: 'MSG_PROFILE_EDITOR_CLOSE_DIALOG_ADVERTISING',

  // PersonConfigurations
  MSG_ACCOUNT_CONFIGURATIONS_PAGE_TITLE: 'MSG_ACCOUNT_CONFIGURATIONS_PAGE_TITLE',
  MSG_PASSWORD_EDITOR_TITLE: 'MSG_PASSWORD_EDITOR_TITLE',
  MSG_PASSWORD_EDITOR_INFO: 'MSG_PASSWORD_EDITOR_INFO',
  MSG_PASSWORD_EDITOR_PASSWORD: 'MSG_PASSWORD_EDITOR_PASSWORD',
  MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION: 'MSG_PASSWORD_EDITOR_PASSWORD_CONFIRMATION',

  // TOTP
  MSG_TOTP_EDITOR_TITLE: 'MSG_TOTP_EDITOR_TITLE',
  MSG_TOTP_EDITOR_ENABLE_LABEL: 'MSG_TOTP_EDITOR_ENABLE_LABEL',
  MSG_TOTP_EDITOR_INFO: 'MSG_TOTP_EDITOR_INFO',
  MSG_TOTP_EDITOR_GUIDE_LINK: 'MSG_TOTP_EDITOR_GUIDE_LINK',
  MSG_TOTP_EDITOR_CODE_INPUT_INFO: 'MSG_TOTP_EDITOR_CODE_INPUT_INFO',
  MSG_TOTP_EDITOR_CODE_INPUT_LABEL: 'MSG_TOTP_EDITOR_CODE_INPUT_LABEL',
  MSG_TOTP_EDITOR_CODE_TRY_BUTTON: 'MSG_TOTP_EDITOR_CODE_TRY_BUTTON',
  MSG_TOTP_EDITOR_QRCODE_INFO: 'MSG_TOTP_EDITOR_QRCODE_INFO',
  MSG_TOTP_EDITOR_QRCODE_WARNING: 'MSG_TOTP_EDITOR_QRCODE_WARNING',
  MSG_TOTP_EDITOR_QRCODE_CODE_LABEL: 'MSG_TOTP_EDITOR_QRCODE_CODE_LABEL',
  MSG_TOTP_EDITOR_DIALOG_DISABLE_TITLE: 'MSG_TOTP_EDITOR_DIALOG_DISABLE_TITLE',
  MSG_TOTP_EDITOR_DIALOG_DISABLE_INFO: 'MSG_TOTP_EDITOR_DIALOG_DISABLE_INFO',
  MSG_TOTP_EDITOR_VALID_CODE: 'MSG_TOTP_EDITOR_VALID_CODE',
  MSG_TOTP_EDITOR_SUCCESSFULLY_ENABLED: 'MSG_TOTP_EDITOR_SUCCESSFULLY_ENABLED',
  MSG_TOTP_EDITOR_SUCCESSFULLY_DISABLED: 'MSG_TOTP_EDITOR_SUCCESSFULLY_DISABLED',

  // configurations authorizations
  MSG_CONFIGURATIONS_AUTH_EDITOR_TITLE: 'MSG_CONFIGURATIONS_AUTH_EDITOR_TITLE',
  MSG_CONFIGURATIONS_AUTH_EDITOR_INFO: 'MSG_CONFIGURATIONS_AUTH_EDITOR_INFO',
  MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_LABEL: 'MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_LABEL',
  MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_ERROR: 'MSG_CONFIGURATIONS_AUTH_EDITOR_INPUT_ERROR',

  // configurations notifications
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TITLE: 'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TITLE',
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOX_RECEIVED_LABEL:
    'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOX_RECEIVED_LABEL',
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_REVISION_CREATED_LABEL:
    'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_REVISION_CREATED_LABEL',
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_VIEWED_LABEL:
    'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_VIEWED_LABEL',
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_DOWNLOADED_LABEL:
    'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_DOCUMENT_DOWNLOADED_LABEL',
  MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_PRIVACY_POLICY_UPDATED_LABEL:
    'MSG_NOTIFICATIONS_SETTINGS_EDITOR_TYPE_PRIVACY_POLICY_UPDATED_LABEL',

  // General settings
  MSG_GENERAL_SETTINGS_TITLE: 'MSG_GENERAL_SETTINGS_TITLE',
  MSG_GENERAL_SETTINGS_CHECKBOX_LABEL: 'MSG_GENERAL_SETTINGS_CHECKBOX_LABEL',
  MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INFO: 'MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INFO',
  MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INPUT_LABEL: 'MSG_GENERAL_SETTINGS_AUTOSAVE_TIME_INPUT_LABEL',

  // Documents Templates
  MSG_DOCUMENTS_TEMPLATES_TITLE: 'MSG_DOCUMENTS_TEMPLATES_TITLE',
  MSG_DOCUMENTS_TEMPLATES_LIST_LABEL: 'MSG_DOCUMENTS_TEMPLATES_LIST_LABEL',
  MSG_DOCUMENTS_TEMPLATES_LIST_ADD_BUTTON: 'MSG_DOCUMENTS_TEMPLATES_LIST_ADD_BUTTON',
  MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_LABEL: 'MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_LABEL',
  MSG_DOCUMENTS_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL: 'MSG_DOCUMENTS_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL',
  MSG_DOCUMENTS_TEMPLATES_TEMPLATE_CONTENT_LABEL: 'MSG_DOCUMENTS_TEMPLATES_TEMPLATE_CONTENT_LABEL',
  MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_DEFAULT: 'MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_DEFAULT',
  MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_TITLE: 'MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_TITLE',
  MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_BODY: 'MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_BODY',

  // Document templates list dialog
  MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE: 'MSG_DOCUMENTS_TEMPLATES_DIALOG_TITLE',

  // Document templates
  MSG_DOCUMENTS_TEMPLATES_ERROR_DIALOG_TITLE: 'MSG_DOCUMENTS_TEMPLATES_ERROR_DIALOG_TITLE',
  MSG_DOCUMENTS_TEMPLATES_ERROR_DIALOG_BODY: 'MSG_DOCUMENTS_TEMPLATES_ERROR_DIALOG_BODY',
  MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY: 'MSG_DOCUMENTS_TEMPLATES_TEMPLATES_LIST_EMPTY',

  // Document dialogs
  MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY: 'MSG_DOCUMENT_NOT_SAVED_CHANGED_DIALOG_BODY',

  // Doxes Templates
  MSG_DOXES_TEMPLATES_TITLE: 'MSG_DOXES_TEMPLATES_TITLE',
  MSG_DOXES_TEMPLATES_LIST_LABEL: 'MSG_DOXES_TEMPLATES_LIST_LABEL',
  MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON: 'MSG_DOXES_TEMPLATES_LIST_ADD_BUTTON',
  MSG_DOXES_TEMPLATES_TEMPLATE_NAME_LABEL: 'MSG_DOXES_TEMPLATES_TEMPLATE_NAME_LABEL',
  MSG_DOXES_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL: 'MSG_DOXES_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL',
  MSG_DOXES_TEMPLATES_DOXES_LABEL: 'MSG_DOXES_TEMPLATES_DOXES_LABEL',
  MSG_DOXES_TEMPLATES_DOX_ADD_BUTTON: 'MSG_DOXES_TEMPLATES_DOX_ADD_BUTTON',
  MSG_DOXES_TEMPLATES_DOX_NAME_DEFAULT: 'MSG_DOXES_TEMPLATES_DOX_NAME_DEFAULT',
  MSG_DOXES_TEMPLATES_TEMPLATE_NAME_DEFAULT: 'MSG_DOXES_TEMPLATES_TEMPLATE_NAME_DEFAULT',
  MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_TITLE: 'MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_TITLE',
  MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_BODY: 'MSG_DOXES_TEMPLATES_DELETE_MODEL_DIALOG_CONFIRM_BODY',
  MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE: 'MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE',
  MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_BODY: 'MSG_DOXES_TEMPLATES_DELETE_ELEMENT_DIALOG_CONFIRM_BODY',

  // Doxes Templates
  MSG_DOX_TEMPLATE_EDITOR_DIALOG_TITLE: 'MSG_DOX_TEMPLATE_EDITOR_DIALOG_TITLE',
  MSG_DOX_TEMPLATE_EDITOR_AUTO_RETENTION_DATE_LABEL: 'MSG_DOX_TEMPLATE_EDITOR_AUTO_RETENTION_DATE_LABEL',

  // Dox templates list dialog
  MSG_DOX_TEMPLATES_DIALOG_TITLE: 'MSG_DOX_TEMPLATES_DIALOG_TITLE',

  // Organized doxes
  MSG_ORGANIZED_DOX_ERROR_DIALOG_TITLE: 'MSG_ORGANIZED_DOX_ERROR_DIALOG_TITLE',
  MSG_ORGANIZED_DOX_ERROR_DIALOG_BODY: 'MSG_ORGANIZED_DOX_ERROR_DIALOG_BODY',
  MSG_ORGANIZED_DOX_TEMPLATES_LIST_EMPTY: 'MSG_ORGANIZED_DOX_TEMPLATES_LIST_EMPTY',

  // Users data form
  MSG_USERS_DATA_TITLE: 'MSG_USERS_DATA_TITLE',
  MSG_USERS_DATA_VISIBILITY_ALL_LABEL: 'MSG_USERS_DATA_VISIBILITY_ALL_LABEL',
  MSG_USERS_DATA_VISIBILITY_BY_FINALITY_LABEL: 'MSG_USERS_DATA_VISIBILITY_BY_FINALITY_LABEL',

  // Users auth form
  MSG_USERS_AUTH_TITLE: 'MSG_USERS_AUTH_TITLE',
  MSG_USERS_AUTH_VISIBILITY_ALL_LABEL: 'MSG_USERS_AUTH_VISIBILITY_ALL_LABEL',
  MSG_USERS_AUTH_VISIBILITY_BY_USERS_LABEL: 'MSG_USERS_AUTH_VISIBILITY_BY_USERS_LABEL',

  // Privacy management
  MSG_PRIVACY_MANAGEMENT_TITLE: 'MSG_PRIVACY_MANAGEMENT_TITLE',
  MSG_PRIVACY_MANAGEMENT_VIEW_LABEL: 'MSG_PRIVACY_MANAGEMENT_VIEW_LABEL',
  MSG_PRIVACY_MANAGEMENT_EDIT_LABEL: 'MSG_PRIVACY_MANAGEMENT_EDIT_LABEL',

  // Invitations management
  MSG_INVITATIONS_MANAGEMENT_TITLE: 'MSG_INVITATIONS_MANAGEMENT_TITLE',
  MSG_INVITATIONS_MANAGEMENT_ENABLED_LABEL: 'MSG_INVITATIONS_MANAGEMENT_ENABLED_LABEL',

  // Collaborators management
  MSG_COLLABORATORS_MANAGEMENT_TITLE: 'MSG_COLLABORATORS_MANAGEMENT_TITLE',
  MSG_COLLABORATORS_MANAGEMENT_VIEW_AUTH_LABEL: 'MSG_COLLABORATORS_MANAGEMENT_VIEW_AUTH_LABEL',
  MSG_COLLABORATORS_MANAGEMENT_ADD_AUTH_LABEL: 'MSG_COLLABORATORS_MANAGEMENT_ADD_AUTH_LABEL',
  MSG_COLLABORATORS_MANAGEMENT_REMOVE_AUTH_LABEL: 'MSG_COLLABORATORS_MANAGEMENT_REMOVE_AUTH_LABEL',
  MSG_COLLABORATORS_MANAGEMENT_EDIT_PROFILE_LABEL: 'MSG_COLLABORATORS_MANAGEMENT_EDIT_PROFILE_LABEL',

  // Communes
  MSG_COMMUNE_AUTOCOMPLETE_TIP: 'MSG_COMMUNE_AUTOCOMPLETE_TIP',

  // History
  MSG_HISTORY_EVENT_SUBTYPE_DSC_DATA_PROCESSING: 'MSG_HISTORY_EVENT_SUBTYPE_DSC_DATA_PROCESSING',
  MSG_HISTORY_EVENT_SUBTYPE_DSC_ALWAYS_DOWNLOAD: 'MSG_HISTORY_EVENT_SUBTYPE_DSC_ALWAYS_DOWNLOAD',
  MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOX: 'MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOX',
  MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOCUMENTS: 'MSG_HISTORY_EVENT_SUBTYPE_DSC_VIEW_DOCUMENTS',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_ACTIVATED: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_ACTIVATED',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_SUSPENDED: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_SUSPENDED',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_UPCOMING_DELETION: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_UPCOMING_DELETION',
  MSG_HISTORY_EVENT_TYPE_DSC_USER_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_USER_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_ATTACHED: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_ATTACHED',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DETACHED: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DETACHED',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_SUSPENSION: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_SUSPENSION',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_SUSPENDED: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_SUSPENDED',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_DELETION: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_UPCOMING_DELETION',
  MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_PROFILE_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_REVOKED: 'MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_REVOKED',
  MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_ACCEPTED: 'MSG_HISTORY_EVENT_TYPE_DSC_INVITATION_ACCEPTED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SUSPENDED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SUSPENDED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_RESTORED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_RESTORED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPCOMING_EXPIRATION:
    'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_UPCOMING_EXPIRATION',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_EXPIRED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_EXPIRED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SENDED: 'MSG_HISTORY_EVENT_TYPE_DSC_PERMISSION_SENDED',
  MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_ENABLED: 'MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_ENABLED',
  MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DISABLED: 'MSG_HISTORY_EVENT_TYPE_DSC_TREATMENT_DISABLED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_PUBLISHED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_PUBLISHED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONTRACT_VERSION_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_DATA_PROCESSING_SECTION_VIEWED:
    'MSG_HISTORY_EVENT_TYPE_DSC_DATA_PROCESSING_SECTION_VIEWED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_GRANTED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_GRANTED',
  MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_REVOKED: 'MSG_HISTORY_EVENT_TYPE_DSC_CONSENT_REVOKED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_ARCHIVED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_ARCHIVED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DOWNLOADED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_DOWNLOADED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_VIEWED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_REVISION_VIEWED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_RENAMED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_RENAMED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELIVERED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_DELIVERED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_OBSOLETE: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_OBSOLETE',
  MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_IMPORTANT: 'MSG_HISTORY_EVENT_TYPE_DSC_DOCUMENT_MARKED_AS_IMPORTANT',
  MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER: 'MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_DEADLINE_REMINDER',
  MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED: 'MSG_HISTORY_EVENT_TYPE_DSC_RETENTION_EXPIRED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_UPDATED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_UPDATED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELETED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELETED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_DOWNLOADED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_DOWNLOADED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_VIEWED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_VIEWED',
  MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED: 'MSG_HISTORY_EVENT_TYPE_DSC_DOX_DELIVERED',
  MSG_HISTORY_EVENT_TYPE_DSC_INVOICE_FINALIZED: 'MSG_HISTORY_EVENT_TYPE_DSC_INVOICE_FINALIZED',
  MSG_HISTORY_EVENT_TYPE_DSC_ACTIVITY_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_ACTIVITY_CREATED',
  MSG_HISTORY_EVENT_TYPE_DSC_NOTIFICATION_CREATED: 'MSG_HISTORY_EVENT_TYPE_DSC_NOTIFICATION_CREATED',
  MSG_HISTORY_BODY_TREATMENT_OF_DATA: 'MSG_HISTORY_BODY_TREATMENT_OF_DATA',
  MSG_HISTORY_BODY_DOCUMENT_VISUALIZATION: 'MSG_HISTORY_BODY_DOCUMENT_VISUALIZATION',
  MSG_HISTORY_BODY_DOX_VISUALIZATION: 'MSG_HISTORY_BODY_DOX_VISUALIZATION',
  MSG_HISTORY_BODY_SEND_OF_INVITATION: 'MSG_HISTORY_BODY_SEND_OF_INVITATION',
  MSG_HISTORY_BODY_PERMISSION_CREATED: 'MSG_HISTORY_BODY_PERMISSION_CREATED',
  MSG_HISTORY_BODY_PERMISSION_DATA_PROCESSING: 'MSG_HISTORY_BODY_PERMISSION_DATA_PROCESSING',
  MSG_HISTORY_BODY_PERMISSION_VIEW_DOCUMENTS: 'MSG_HISTORY_BODY_PERMISSION_VIEW_DOCUMENTS',
  MSG_HISTORY_BODY_PERMISSION_VIEW_DOX: 'MSG_HISTORY_BODY_PERMISSION_VIEW_DOX',
  MSG_HISTORY_BODY_PERMISSION_INVITE_PROFILES: 'MSG_HISTORY_BODY_PERMISSION_INVITE_PROFILES',
  MSG_HISTORY_BODY_PERMISSION_VIEW_PROFILE: 'MSG_HISTORY_BODY_PERMISSION_VIEW_PROFILE',
  MSG_HISTORY_BODY_PERMISSION_ALLOWED_ACTIONS: 'MSG_HISTORY_BODY_PERMISSION_ALLOWED_ACTIONS',
  MSG_HISTORY_BODY_PERMISSION_PIN_SECURITY: 'MSG_HISTORY_BODY_PERMISSION_PIN_SECURITY',
  MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION: 'MSG_HISTORY_BODY_RETENTION_UPCOMING_EXPIRATION',
  MSG_HISTORY_BODY_RETENTION_EXPIRED: 'MSG_HISTORY_BODY_RETENTION_EXPIRED',
  MSG_HISTORY_BODY_DOX_DELIVERED: 'MSG_HISTORY_BODY_DOX_DELIVERED',
  MSG_HISTORY_BODY_DOX_RECEIVED: 'MSG_HISTORY_BODY_DOX_RECEIVED',
  MSG_HISTORY_BODY_DOX_RENAMED: 'MSG_HISTORY_BODY_DOX_RENAMED',
  MSG_HISTORY_BODY_DOX_NAME: 'MSG_HISTORY_BODY_DOX_NAME',
  MSG_HISTORY_BODY_DOCUMENT_NAME: 'MSG_HISTORY_BODY_DOCUMENT_NAME',
  MSG_HISTORY_BODY_DOCUMENT_PREVIOUS_NAME: 'MSG_HISTORY_BODY_DOCUMENT_PREVIOUS_NAME',
  MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT: 'MSG_HISTORY_BODY_DOCUMENT_NOT_AVAILABLE_DRAFT',
  MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_YES:
    'MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_YES',
  MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_NO:
    'MSG_HISTORY_BODY_TREATMENT_RETENTION_NOTICE_DAYS_BEFORE_EXPIRATION_NO',
  MSG_HISTORY_BODY_TREATMENT_RETENTION_END_BEHAVIOR: 'MSG_HISTORY_BODY_TREATMENT_RETENTION_END_BEHAVIOR',
  MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_REQUIRED: 'MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_REQUIRED',
  MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_NOT_REQUIRED: 'MSG_HISTORY_BODY_NEW_ACKNOWLEDGMENT_NOT_REQUIRED',
  MSG_HISTORY_BODY_NEW_CONSENTS_REQUIRED: 'MSG_HISTORY_BODY_NEW_CONSENTS_REQUIRED',
  MSG_HISTORY_BODY_NEW_CONSENTS_NOT_REQUIRED: 'MSG_HISTORY_BODY_NEW_CONSENTS_NOT_REQUIRED',
  MSG_HISTORY_BODY_CONTRACT_PUBLISHED_AT: 'MSG_HISTORY_BODY_CONTRACT_PUBLISHED_AT',
  MSG_HISTORY_BODY_CONSENT_RENEWED_AUTOMATICALLY: 'MSG_HISTORY_BODY_CONSENT_RENEWED_AUTOMATICALLY',
  MSG_HISTORY_BODY_CONSENT_RENEWED_FOR: 'MSG_HISTORY_BODY_CONSENT_RENEWED_FOR',
  MSG_HISTORY_BODY_CONSENT_GRANTED_FOR: 'MSG_HISTORY_BODY_CONSENT_GRANTED_FOR',
  MSG_HISTORY_BODY_CONSENT_REVOKED_FOR: 'MSG_HISTORY_BODY_CONSENT_REVOKED_FOR',
  MSG_HISTORY_BODY_INVITATION_PURPOSE_COLLABORATE: 'MSG_HISTORY_BODY_INVITATION_PURPOSE_COLLABORATE',

  // Dialog
  MSG_HISTORY_DIALOG_DETAILS_TITLE: 'MSG_HISTORY_DIALOG_DETAILS_TITLE',
  MSG_HISTORY_SHOW_DOCUMENT_TITLE: 'MSG_HISTORY_SHOW_DOCUMENT_TITLE',
  MSG_HISTORY_SHOW_DOCUMENT_NOT_AVAILABLE: 'MSG_HISTORY_SHOW_DOCUMENT_NOT_AVAILABLE',
  MSG_HISTORY_SHOW_DOX_TITLE: 'MSG_HISTORY_SHOW_DOX_TITLE',
  MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE: 'MSG_HISTORY_SHOW_DOX_NOT_AVAILABLE',
  MSG_HISTORY_SHOW_CONTRACT_TITLE: 'MSG_HISTORY_SHOW_CONTRACT_TITLE',
  MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE: 'MSG_HISTORY_SHOW_CONTRACT_NOT_AVAILABLE',

  // Collaborator authorizations
  MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM: 'MSG_COLLABORATOR_AUTHORIZATION_DELETE_CONFIRM',

  // AccountCardPage
  MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_PRIVACY_POLICY',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_OPERATORS',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_HISTORY',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_MODIFY',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_SEND_INVITATION',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_REVOKE_INVITATION: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_ROVOKE_INVITATION',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_ACCEPT_INVITATION',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZE_INVITATION: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZE_INVITATION',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_STRUCTURE: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_LINKED_ATRUCTURE',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_AUTHORIZATIONS',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_SHARED_DOCUMENTS',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_ATTACH_TO_REAL_USER',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_CLOSE_PROFILE',
  MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE: 'MSG_ACCOUNT_CARD_PAGE_COMMAND_ADD_OPERATOR_PROFILE',

  // AccountAuthorizationsPage
  MSG_ACCOUNT_AUTHORIZATIONS_PAGE_TITLE: 'MSG_ACCOUNT_AUTHORIZATIONS_PAGE_TITLE',

  // NotFoundPage
  MSG_NOT_FOUND_PAGE_404_ERROR: 'MSG_NOT_FOUND_PAGE_404_ERROR',
  MSG_NOT_FOUND_PAGE_ALERT: 'MSG_NOT_FOUND_PAGE_ALERT',
  MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_OPERATORS: 'MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_OPERATORS',
  MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_CUSTOMERS: 'MSG_NOT_FOUND_PAGE_GO_TO_LOGIN_FOR_CUSTOMERS',

  // Invitations
  MSG_INVITATION_SEND_TITLE: 'MSG_INVITATION_SEND_TITLE',
  MSG_INVITATION_SEND_CONFIRM: 'MSG_INVITATION_SEND_CONFIRM',
  MSG_INVITATION_REVOKE_TITLE: 'MSG_INVITATION_REVOKE_TITLE',
  MSG_INVITATION_REVOKE_CONFIRM: 'MSG_INVITATION_REVOKE_CONFIRM',
  MSG_INVITATION_ACCEPTANCE_TITLE: 'MSG_INVITATION_ACCEPTANCE_TITLE',
  MSG_INVITATION_ACCEPTANCE_CONFIRM: 'MSG_INVITATION_ACCEPTANCE_CONFIRM',
  MSG_INVITATION_AUTHORIZE_TITLE: 'MSG_INVITATION_AUTHORIZE_TITLE',
  MSG_INVITATION_AUTHORIZE_CONFIRM: 'MSG_INVITATION_AUTHORIZE_CONFIRM',
  MSG_INVITATION_AUTHORIZATION_IMPOSSIBLE: 'MSG_INVITATION_AUTHORIZATION_IMPOSSIBLE',
  MSG_INVITATION_AUTHORIZATION_EXECUTED: 'MSG_INVITATION_AUTHORIZATION_EXECUTED',
  MSG_INVITATION_SENDED_SUCCESSFULLY: 'MSG_INVITATION_SENDED_SUCCESSFULLY',

  // ContractVersionViewer
  MSG_CONSENTS_UPDATED_SUCCESSFULLY: 'MSG_CONSENTS_UPDATED_SUCCESSFULLY',

  // EventHistoryPage
  MSG_EVENT_HISTORY_PAGE_TITLE: 'MSG_EVENT_HISTORY_PAGE_TITLE',

  // EventHistoryTable
  MSG_EVENT_HISTORY_TABLE_DATE_HEADER: 'MSG_EVENT_HISTORY_TABLE_DATE_HEADER',
  MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER: 'MSG_EVENT_HISTORY_TABLE_AUTHOR_HEADER',
  MSG_EVENT_HISTORY_TABLE_ACTION_HEADER: 'MSG_EVENT_HISTORY_TABLE_ACTION_HEADER',
  MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER: 'MSG_EVENT_HISTORY_TABLE_DESCRIPTION_HEADER',

  // EventHistoryFiltersForm
  MSG_EVENT_HISTORY_FILTERS_FORM_TITLE: 'MSG_EVENT_HISTORY_FILTERS_FORM_TITLE',
  MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_STRUCTURE_PLACEHOLDER:
    'MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_STRUCTURE_PLACEHOLDER',
  MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_OPERATOR_PLACEHOLDER:
    'MSG_EVENT_HISTORY_FILTERS_FORM_SEARCH_OPERATOR_PLACEHOLDER',

  // EventHistoryFilters keys
  MSG_EVENT_HISTORY_FILTER_FROM_KEY: 'MSG_EVENT_HISTORY_FILTER_FILTER_FROM_KEY',
  MSG_EVENT_HISTORY_FILTER_TO_KEY: 'MSG_EVENT_HISTORY_FILTER_FILTER_TO_KEY',
  MSG_EVENT_HISTORY_FILTER_STRUCTURE_KEY: 'MSG_EVENT_HISTORY_FILTER_FILTER_STRUCTURE_KEY',
  MSG_EVENT_HISTORY_FILTER_OPERATOR_KEY: 'MSG_EVENT_HISTORY_FILTER_FILTER_OPERATOR_KEY',
  MSG_EVENT_HISTORY_FILTER_TREATMENT: 'MSG_EVENT_HISTORY_FILTER_TREATMENT',

  // Desktop
  MSG_DESKTOP_PAGE_TITLE: 'MSG_DESKTOP_PAGE_TITLE',
  MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP:
    'MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_FROM_TEMPLATE_TOOLTIP',
  MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_TOOLTIP: 'MSG_DESKTOP_CREATE_INTERNAL_DOCUMENT_TOOLTIP',
  MSG_DESKTOP_UPLOAD_DOCUMENT_TOOLTIP: 'MSG_DESKTOP_UPLOAD_DOCUMENT_TOOLTIP',
  MSG_DESKTOP_CLEAR_TOOLTIP: 'MSG_DESKTOP_CLEAR_TOOLTIP',
  MSG_DOCUMENTS_NOT_SAVED_DIALOG_TITLE: 'MSG_DOCUMENTS_NOT_SAVED_DIALOG_TITLE',
  MSG_DOCUMENTS_NOT_SAVED_DIALOG_CONTENT: 'MSG_DOCUMENTS_NOT_SAVED_DIALOG_CONTENT',

  // Profile selector Dialog
  MSG_PROFILE_SELECTOR_DIALOG_TITLE: 'MSG_PROFILE_SELECTOR_DIALOG_TITLE',

  // Notice board
  MSG_NOTICE_BOARD_TITLE: 'MSG_NOTICE_BOARD_TITLE',
  MSG_NOTICE_BOARD_ADD_ELEMENT: 'MSG_NOTICE_BOARD_ADD_ELEMENT',
  MSG_NOTICE_BOARD_EDIT_ELEMENT: 'MSG_NOTICE_BOARD_EDIT_ELEMENT',
  MSG_NOTICE_BOARD_DELETE_ELEMENT: 'MSG_NOTICE_BOARD_DELETE_ELEMENT',
  MSG_NOTICE_BOARD_EDIT_ELEMENT_DIALOG_TITLE: 'MSG_NOTICE_BOARD_EDIT_ELEMENT_DIALOG_TITLE',
  MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE: 'MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_TITLE',
  MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_BODY: 'MSG_NOTICE_BOARD_DELETE_ELEMENT_DIALOG_CONFIRM_BODY',

  // Login
  MSG_LOGIN_FOR_OPERATORS: 'MSG_LOGIN_FOR_OPERATORS',
  MSG_LOGIN_FOR_CUSTOMERS: 'MSG_LOGIN_FOR_CUSTOMERS',
  MSG_LOGIN_FOR_GUEST_OPERATORS: 'MSG_LOGIN_FOR_GUEST_OPERATORS',
  MSG_LOGIN_FOR_GUEST_CUSTOMERS: 'MSG_LOGIN_FOR_GUEST_CUSTOMERS',
  MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_IN_PROGRESS: 'MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_IN_PROGRESS',
  MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_FAILED: 'MSG_LOGIN_FOR_GUEST_AUTHORIZATION_CHECK_FAILED',
  MSG_LOGIN_BUTTON_TITLE: 'MSG_LOGIN_BUTTON_TITLE',
  MSG_LOGIN_FORGOT_PASSWORD_LINK: 'MSG_LOGIN_FORGOT_PASSWORD_LINK',
  MSG_LOGIN_REGISTER_LINK: 'MSG_LOGIN_REGISTER_LINK',
  MSG_LOGIN_CREATE_NEW_PROFILE_LINK: 'MSG_LOGIN_CREATE_NEW_PROFILE_LINK',
  MSG_LOGIN_2FA_PROBLEM_QUESTION: 'MSG_LOGIN_2FA_PROBLEM_QUESTION',
  MSG_LOGIN_2FA_EMERGENCY_LINK: 'MSG_LOGIN_2FA_EMERGENCY_LINK',
  MSG_LOGIN_2FA_INFO: 'MSG_LOGIN_2FA_INFO',
  MSG_LOGIN_PASSWORD_RESET: 'MSG_LOGIN_PASSWORD_RESET',
  MSG_LOGIN_FAILED_DIALOG_TITLE: 'MSG_LOGIN_FAILED_DIALOG_TITLE',
  MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_BUSINESS_PROFILES: 'MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_BUSINESS_PROFILES',
  MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_CONSUMER_PROFILES: 'MSG_LOGIN_FAILED_DIALOG_NO_AVAILABLE_CONSUMER_PROFILES',
  MSG_LOGIN_AS_BUSINESS_USER: 'MSG_LOGIN_AS_BUSINESS_USER',
  MSG_LOGIN_AS_CONSUMER_USER: 'MSG_LOGIN_AS_CONSUMER_USER',
  MSG_LOGIN_GUEST_2FA_INFO: 'MSG_LOGIN_GUEST_2FA_INFO',
  MSG_LOGIN_GUEST_PIN_LABEL: 'MSG_LOGIN_GUEST_PIN_LABEL',
  MSG_OTP_RECOVERY_INFO: 'MSG_OTP_RECOVERY_INFO',
  MSG_PASSWORD_RECOVERY_INFO: 'MSG_PASSWORD_RECOVERY_INFO',
  MSG_PASSWORD_RECOVERY_SEND_EMAIL_BUTTON: 'MSG_PASSWORD_RECOVERY_SEND_EMAIL_BUTTON',
  MSG_PASSWORD_RECOVERY_LOGIN_LINK: 'MSG_PASSWORD_RECOVERY_LOGIN_LINK',
  MSG_CONTRACTS_UPDATE_WARNING_DIALOG_TITLE: 'MSG_CONTRACTS_UPDATE_WARNING_DIALOG_TITLE',
  MSG_CONTRACTS_UPDATE_WARNING_ACCESS: 'MSG_CONTRACTS_UPDATE_WARNING_ACCESS',
  MSG_CONTRACTS_UPDATE_WARNING_ACCESS_AND_MUTE_MSG: 'MSG_CONTRACTS_UPDATE_WARNING_ACCESS_AND_MUTE_MSG',
  MSG_CONTRACTS_UPDATE_WARNING_SHOW_CONTRACTS: 'MSG_CONTRACTS_UPDATE_WARNING_SHOW_CONTRACTS',
  MSG_PASSWORD_RECOVERY_MAIL_SUCCESSFULLY: 'MSG_PASSWORD_RECOVERY_MAIL_SUCCESSFULLY',
  MSG_NO_OTHER_AVAILABLE_ACCOUNTS: 'MSG_NO_OTHER_AVAILABLE_ACCOUNTS',

  // Registration
  MSG_PRIVATE_AREA_SELECTION_TITLE: 'MSG_PRIVATE_AREA_SELECTION_TITLE',

  MSG_USER_BUSINESS: 'MSG_USER_BUSINESS',
  MSG_USER_PRIVATE: 'MSG_USER_PRIVATE',
  MSG_REGISTER_BUTTON_TITLE: 'MSG_REGISTER_BUTTON_TITLE',

  MSG_REGISTRATION_PAGE_TITLE_CONTRACT_REVISION: 'MSG_REGISTRATION_PAGE_TITLE_CONTRACT_REVISION',
  MSG_REGISTRATION_PAGE_TITLE_PROFILE_CREATION: 'MSG_REGISTRATION_PAGE_TITLE_PROFILE_CREATION',
  MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION: 'MSG_REGISTRATION_PAGE_TITLE_USER_REGISTRATION',
  MSG_REGISTRATION_PAGE_TITLE_CHANGE_PLAN: 'MSG_REGISTRATION_PAGE_TITLE_CHANGE_PLAN',
  MSG_REGISTRATION_CONTRACT_PRIVACY_POLICY_TITLE: 'MSG_REGISTRATION_CONTRACT_PRIVACY_POLICY_TITLE',
  MSG_REGISTRATION_CONTRACT_TERMS_POLICY_TITLE: 'MSG_REGISTRATION_CONTRACT_TERMS_POLICY_TITLE',
  MSG_REGISTRATION_CONTRACT_DATA_PROCESSOR: 'MSG_REGISTRATION_CONTRACT_DATA_PROCESSOR',
  MSG_REGISTRATION_PERSON_IDENTITY_TITLE: 'MSG_REGISTRATION_PERSON_IDENTITY_TITLE',
  MSG_REGISTRATION_BILLING_TITLE: 'MSG_REGISTRATION_BILLING_TITLE',
  MSG_REGISTRATION_COMPANY_IDENTITY_TITLE: 'MSG_REGISTRATION_COMPANY_IDENTITY_TITLE',
  MSG_REGISTRATION_CREDENTIALS_TITLE: 'MSG_REGISTRATION_CREDENTIALS_TITLE',
  MSG_REGISTRATION_SUMMARY_TITLE: 'MSG_REGISTRATION_SUMMARY_TITLE',
  MSG_REGISTRATION_CONFIRM_DIALOG_TITLE: 'MSG_REGISTRATION_CONFIRM_DIALOG_TITLE',
  MSG_REGISTRATION_CONFIRM_DIALOG_BODY: 'MSG_REGISTRATION_CONFIRM_DIALOG_BODY',
  MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_CONSENTS: 'MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_CONSENTS',
  MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_PROFILE: 'MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_PROFILE',
  MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_ACCOUNT: 'MSG_REGISTRATION_SUCCESS_MESSAGE_NEW_ACCOUNT',
  MSG_REGISTRATION_SUCCESS_MESSAGE_BILLING_DATA_UPDATED: 'MSG_REGISTRATION_SUCCESS_MESSAGE_BILLING_DATA_UPDATED',

  MSG_REGISTRATION_SUMMARY_CONTACT_EMAIL_SECTION_TITLE: 'MSG_REGISTRATION_SUMMARY_CONTACT_EMAIL_SECTION_TITLE',
  MSG_REGISTRATION_SUMMARY_PERSONAL_IDENTITY_SECTION_TITLE: 'MSG_REGISTRATION_SUMMARY_PERSONAL_IDENTITY_SECTION_TITLE',
  MSG_REGISTRATION_SUMMARY_COMPANY_SECTION_TITLE: 'MSG_REGISTRATION_SUMMARY_COMPANY_SECTION_TITLE',
  MSG_REGISTRATION_SUMMARY_BILLING_TITLE: 'MSG_REGISTRATION_SUMMARY_BILLING_TITLE',
  MSG_REGISTRATION_SUMMARY_CONFIRM_FOR_CONSENTS: 'MSG_REGISTRATION_SUMMARY_CONFIRM_FOR_CONSENTS',

  // user activation
  MSG_USER_ACTIVATION_TITLE: 'MSG_USER_ACTIVATION_TITLE',
  MSG_USER_ACTIVATION_IN_PROGRESS: 'MSG_USER_ACTIVATION_IN_PROGRESS',
  MSG_USER_ACTIVATION_WAIT: 'MSG_USER_ACTIVATION_WAIT',
  MSG_USER_ACTIVATION_INFO1: 'MSG_USER_ACTIVATION_INFO1',
  MSG_USER_ACTIVATION_DONE: 'MSG_USER_ACTIVATION_DONE',
  MSG_USER_ACTIVATION_FAILED: 'MSG_USER_ACTIVATION_FAILED',

  MSG_NEXT_BUTTON_TITLE: 'MSG_NEXT_BUTTON_TITLE',
  MSG_PREVIOUS_BUTTON_TITLE: 'MSG_PREVIOUS_BUTTON_TITLE',

  // Billing
  MSG_BILLING_PRODUCT_FEATURE_PRF_SHOWCASE_DSC: 'MSG_BILLING_PRODUCT_FEATURE_PRF_SHOWCASE_DSC',
  MSG_BILLING_PRODUCT_FEATURE_TREATMENTS_CONF_DSC: 'MSG_BILLING_PRODUCT_FEATURE_TREATMENTS_CONF_DSC',
  MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_EDITOR_DSC: 'MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_EDITOR_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_MAX_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_MAX_DSC',
  MSG_BILLING_PRODUCT_FEATURE_PRV_PLANT_HIST_DSC: 'MSG_BILLING_PRODUCT_FEATURE_PRV_PLANT_HIST_DSC',
  MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_CONSENTS_DSC: 'MSG_BILLING_PRODUCT_FEATURE_PRV_POLICY_CONSENTS_DSC',
  MSG_BILLING_PRODUCT_FEATURE_MIN_ANAGRAPHIC_DSC: 'MSG_BILLING_PRODUCT_FEATURE_MIN_ANAGRAPHIC_DSC',
  MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_READ_DSC: 'MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_READ_DSC',
  MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_DWN_DSC: 'MSG_BILLING_PRODUCT_FEATURE_SHARED_DOCS_DWN_DSC',
  MSG_BILLING_PRODUCT_FEATURE_COMPANY_ARCHIVES_DSC: 'MSG_BILLING_PRODUCT_FEATURE_COMPANY_ARCHIVES_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DATA_RETENTION_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DATA_RETENTION_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOC_HIST_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOC_HIST_DSC',
  MSG_BILLING_PRODUCT_FEATURE_USERS_HIST_DSC: 'MSG_BILLING_PRODUCT_FEATURE_USERS_HIST_DSC',
  MSG_BILLING_PRODUCT_FEATURE_INT_DOCS_EDITOR_DSC: 'MSG_BILLING_PRODUCT_FEATURE_INT_DOCS_EDITOR_DSC',
  MSG_BILLING_PRODUCT_FEATURE_EXT_DOCS_EDITOR_DSC: 'MSG_BILLING_PRODUCT_FEATURE_EXT_DOCS_EDITOR_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DOX_DELIVERY_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DOX_DELIVERY_DSC',
  MSG_BILLING_PRODUCT_FEATURE_SEARCH_ENGINE_DSC: 'MSG_BILLING_PRODUCT_FEATURE_SEARCH_ENGINE_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DATA_PROCESSING_DSC',
  MSG_BILLING_PRODUCT_FEATURE_ARCHIVE_DSC: 'MSG_BILLING_PRODUCT_FEATURE_ARCHIVE_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_SHARING_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_SHARING_DSC',
  MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_RECEINVING_DSC: 'MSG_BILLING_PRODUCT_FEATURE_DOX_AND_DOCS_RECEINVING_DSC',
  MSG_BILLING_PRODUCT_FEATURE_OPERATOR_HIST_DSC: 'MSG_BILLING_PRODUCT_FEATURE_OPERATOR_HIST_DSC',

  MSG_BILLING_PRODUCT_FEATURE_UNLIMITED: 'MSG_BILLING_PRODUCT_FEATURE_UNLIMITED',
  MSG_BILLING_PRODUCTS_FOR_OPERATOR: 'MSG_BILLING_PRODUCTS_FOR_OPERATOR',
  MSG_BILLING_PRODUCTS_FOR_USER: 'MSG_BILLING_PRODUCTS_FOR_USER',
  MSG_BILLING_PRODUCTS: 'MSG_BILLING_PRODUCTS',
  MSG_BILLING_TRIAL_FOR_DAYS: 'MSG_BILLING_TRIAL_FOR_DAYS',
  MSG_BILLING_PLAN_ACTIVATE: 'MSG_BILLING_PLAN_ACTIVATE',
  MSG_BILLING_PLAN_VERIFY: 'MSG_BILLING_PLAN_VERIFY',
  MSG_BILLING_PRICE_FOR_USER: 'MSG_BILLING_PRICE_FOR_USER',
  MSG_BILLING_PRICE_VAT_EXCLUDED: 'MSG_BILLING_PRICE_VAT_EXCLUDED',
  MSG_BILLING_TRIAL_STARTED_SUCCESSFULLY: 'MSG_BILLING_TRIAL_STARTED_SUCCESSFULLY',

  // Subscriptions
  MSG_SUBSCRIPTION_STATE_ACTIVE: 'MSG_SUBSCRIPTION_STATE_ACTIVE',
  MSG_SUBSCRIPTION_STATE_NOT_ACTIVE: 'MSG_SUBSCRIPTION_STATE_NOT_ACTIVE',
  MSG_SUBSCRIPTION_STATE_TRIALING: 'MSG_SUBSCRIPTION_STATE_TRIALING',
  MSG_SUBSCRIPTION_USE_PLAN_NOT_ACTIVE: 'MSG_SUBSCRIPTION_USE_PLAN_NOT_ACTIVE',
  MSG_SUBSCRIPTION_USE_PLAN_IN_TRIAL: 'MSG_SUBSCRIPTION_USE_PLAN_IN_TRIAL',
  MSG_SUBSCRIPTION_USE_PLAN_IN_USE: 'MSG_SUBSCRIPTION_USE_PLAN_IN_USE',
  MSG_SUBSCRIPTION_ACTIVATION_TITLE: 'MSG_SUBSCRIPTION_ACTIVATION_TITLE',
  MSG_SUBSCRIPTION_ACTIVATION_BODY1: 'MSG_SUBSCRIPTION_ACTIVATION_BODY1',
  MSG_SUBSCRIPTION_ACTIVATION_BODY2: 'MSG_SUBSCRIPTION_ACTIVATION_BODY2',
  MSG_SUBSCRIPTION_ACTIVATION_ACTIVATE_NEW_PROFILE: 'MSG_SUBSCRIPTION_ACTIVATION_ACTIVATE_NEW_PROFILE',
  MSG_SUBSCRIPTION_ACTIVATION_CHANGE_PROFILE_PLAN: 'MSG_SUBSCRIPTION_ACTIVATION_CHANGE_PROFILE_PLAN',
  MSG_SUBSCRIPTION_ACTIVATION_CREATE_NEW_ACCOUNT: 'MSG_SUBSCRIPTION_ACTIVATION_CREATE_NEW_ACCOUNT',
  MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_ACTIVATE_NEW_PROFILE:
    'MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_ACTIVATE_NEW_PROFILE',
  MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_CHANGE_PROFILE_PLAN:
    'MSG_SUBSCRIPTION_ACTIVATION_REGISTERED_CHANGE_PROFILE_PLAN',

  // Subscriptions
  MSG_SUBSCRIPTION_INFO_STATUS_TITLE: 'MSG_SUBSCRIPTION_INFO_STATUS_TITLE',
  MSG_SUBSCRIPTION_INFO_START_AT_TITLE: 'MSG_SUBSCRIPTION_INFO_START_AT_TITLE',
  MSG_SUBSCRIPTION_INFO_END_AT_TITLE: 'MSG_SUBSCRIPTION_INFO_END_AT_TITLE',
  MSG_SUBSCRIPTION_INFO_AMOUNT_TITLE: 'MSG_SUBSCRIPTION_INFO_AMOUNT_TITLE',
  MSG_SUBSCRIPTION_INFO_DISCOUNT_TITLE: 'MSG_SUBSCRIPTION_INFO_DISCOUNT_TITLE',
  MSG_SUBSCRIPTION_INFO_STATUS_ACTIVE: 'MSG_SUBSCRIPTION_INFO_STATUS_ACTIVE',
  MSG_SUBSCRIPTION_INFO_STATUS_PAST_DUE: 'MSG_SUBSCRIPTION_INFO_STATUS_PAST_DUE',
  MSG_SUBSCRIPTION_INFO_STATUS_UNPAID: 'MSG_SUBSCRIPTION_INFO_STATUS_UNPAID',
  MSG_SUBSCRIPTION_INFO_STATUS_CANCELED: 'MSG_SUBSCRIPTION_INFO_STATUS_CANCELED',
  MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE: 'MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE',
  MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE_EXPIRED: 'MSG_SUBSCRIPTION_INFO_STATUS_INCOMPLETE_EXPIRED',
  MSG_SUBSCRIPTION_INFO_STATUS_TRIALING: 'MSG_SUBSCRIPTION_INFO_STATUS_TRIALING',
  MSG_SUBSCRIPTION_INFO_COMMAND_INVOICES: 'MSG_SUBSCRIPTION_INFO_COMMAND_INVOICE',
  MSG_SUBSCRIPTION_INFO_COMMAND_PAYMENT_METHOD: 'MSG_SUBSCRIPTION_INFO_COMMAND_PAYMENT_METHOD',
  MSG_SUBSCRIPTION_INFO_COMMAND_CHANGE_PLAN: 'MSG_SUBSCRIPTION_INFO_COMMAND_CHANGE_PLAN',
  MSG_SUBSCRIPTION_CHANGE_ALREADY_IN_USE: 'MSG_SUBSCRIPTION_CHANGE_ALREADY_IN_USE',
  MSG_SUBSCRIPTION_USER_ALREADY_REGISTERED: 'MSG_SUBSCRIPTION_USER_ALREADY_REGISTERED',
  MSG_SUBSCRIPTION_USER_NOT_REGISTERED: 'MSG_SUBSCRIPTION_USER_NOT_REGISTERED',

  // Check Legal Status
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_HEAVY_CHANGES: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_HEAVY_CHANGES',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_OPTIONAL_CONSENTS_REQUESTED:
    'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_OPTIONAL_CONSENTS_REQUESTED',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_LIGHT_CHANGES: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_LIGHT_CHANGES',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_PRIVACY_POLICY: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_PRIVACY_POLICY',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_TERMS_OF_USE: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_TERMS_OF_USE',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_DATA_PROCESSOR: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_DATA_PROCESSOR',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_REDIRECT_TO_CONTRACTS:
    'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_REDIRECT_TO_CONTRACTS',
  MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_CONSENTS_VALID: 'MSG_CHECL_LEGAL_STATUS_DIALOG_INTRO_CONSENTS_VALID',

  // Payment method
  MSG_PAYMENT_METHOD_PAGE_TITLE: 'MSG_PAYMENT_METHOD_PAGE_TITLE',
  MSG_PAYMENT_METHOD_PAGE_INFO1: 'MSG_PAYMENT_METHOD_PAGE_INFO1',
  MSG_PAYMENT_METHOD_PAGE_INFO2: 'MSG_PAYMENT_METHOD_PAGE_INFO2',
  MSG_PAYMENT_METHOD_CURRENT_PLAN: 'MSG_PAYMENT_METHOD_CURRENT_PLAN',
  MSG_PAYMENT_METHOD_NEW_PLAN: 'MSG_PAYMENT_METHOD_NEW_PLAN',
  MSG_PAYMENT_METHOD_DISCOUNT_CODE: 'MSG_PAYMENT_METHOD_DISCOUNT_CODE',
  MSG_PAYMENT_METHOD_INSERT_DISCOUNT_CODE: 'MSG_PAYMENT_METHOD_INSERT_DISCOUNT_CODE',
  MSG_PAYMENT_METHOD_CHOOSE_PAYMENT_METHOD: 'MSG_PAYMENT_METHOD_CHOOSE_PAYMENT_METHOD',
  MSG_PAYMENT_METHOD_YOUR_CARDS: 'MSG_PAYMENT_METHOD_YOUR_CARDS',
  MSG_PAYMENT_METHOD_ADD_NEW_CARD: 'MSG_PAYMENT_METHOD_ADD_NEW_CARD',
  MSG_PAYMENT_METHOD_SUBSCRIPTION_TO_PRODUCT: 'MSG_PAYMENT_METHOD_SUBSCRIPTION_TO_PRODUCT',
  MSG_PAYMENT_METHOD_PAYMENT_INTERVAL: 'MSG_PAYMENT_METHOD_PAYMENT_INTERVAL',
  MSG_PAYMENT_METHOD_AMOUNT: 'MSG_PAYMENT_METHOD_AMOUNT',
  MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_TITLE: 'MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_TITLE',
  MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_BODY: 'MSG_PAYMENT_METHOD_CARD_REMOVE_DIALOG_BODY',

  // Invoices
  MSG_INVOICE_DATA: 'MSG_INVOICE_DATA',
  MSG_INVOICE_AMOUNT: 'MSG_INVOICE_AMOUNT',
  MSG_INVOICE_STATE: 'MSG_INVOICE_STATE',
  MSG_INVOICE_STATUS_PENDING: 'MSG_INVOICE_STATUS_PENDING',
  MSG_INVOICE_STATUS_UNCOLLECTABLE: 'MSG_INVOICE_STATUS_UNCOLLECTABLE',
  MSG_INVOICE_STATUS_PAID: 'MSG_INVOICE_STATUS_PAID',
  MSG_INVOICES_SDI_INFO: 'MSG_INVOICES_SDI_INFO',

  // Contract editor
  MSG_CONTRACT_EDITOR_DIALOG_TITLE: 'MSG_CONTRACT_EDITOR_DIALOG_TITLE',
}
