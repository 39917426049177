import { Divider, Stack, StackProps } from '@mui/material'
import { useStackState } from '../../hooks/Stack'
import { PageStepper } from '../pageStepper/PageStepper'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { PageStep } from '../pageStepper/PageStep'
import { NotificationsCommandBar } from '../notificationsCommandsBar/NotificationsCommandsBar'
import { Notifications } from '../notifications/Notifications'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { useEffect, useState } from 'react'
import { Account } from '../../models/Account'
import AccountInfoStructure from '../accountInfoStructure/AccountInfoStructure'
import { ContractType, ProfileType, ProfileTypeTranslationMap } from '../../shared/Constants'
import AccountInfoCustomer from '../accountInfoCustomer/AccountInfoCustomer'
import AccountInfoOperator from '../accountInfoOperator/AccountInfoOperator'
import { INotificationsStepperProps } from './NotificationsStepper.types'
import { ViewContent } from '../viewContent/ViewContent'
import { DoxDetails } from '../doxDetails/DoxDetails'
import DocumentEditor from '../documentEditor/DocumentEditor'
import ContractVersionViewer from '../contractVersionViewer/ContractVersionViewer'
import { Dox } from '../../models/Dox'
import { Treatment } from '../../models/Treatment'
import { ContractVersion } from '../../models/ContractVersion'
import { Document } from '../../models/Document'
import { useAuthContext } from '../../contexts/AuthContext'
import { AccountEditorContextProvider } from '../../contexts/AccountEditorContext'
import { ProfileInformations } from '../profileInformations/ProfileInformations'

export type NotificationStepperState = {
  step: any
  history?: History
  account?: Account
  dox?: Dox
  treatment?: Treatment
  document?: Document
  revisionId?: number
  informationsProfileId?: number
  informationsOwnerProfileId?: number
  mandatoryFieldsNames?: string[]
  optionalFieldsNames?: string[]
  contractVersion?: ContractVersion
  contractOwnerProfileId?: number
  contractType?: ContractType
  targetProfileType?: ProfileType
}

export function NotificationsStepper(props: INotificationsStepperProps & StackProps): JSX.Element {
  const { onGoTo, onClickExit, setMaxWidth, notificationStepperState, ...rest } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const stack = useStackState<NotificationStepperState>([])
  const [sectionIndex, setSectionIndex] = useState(0)
  const [title, setTitle] = useState(t(msgIds.MSG_NOTIFICATIONS_TITLE))

  useEffect(() => {
    if (notificationStepperState) {
      setStep(notificationStepperState, true)
    } else {
      setStep({ step: 'notifications' }, true)
    }
  }, [notificationStepperState])

  function onClickBack() {
    setMaxWidth('sm')
    stack.pop()
  }

  function onClickAvatar(account: Account) {
    setStep({ step: 'account', account })
  }

  function onShowDoxDetails(dox: Dox, treatment?: Treatment) {
    setStep({ step: 'dox_details', dox, treatment })
  }

  function onShowDocumentDetails(document: Document, revisionId?: number) {
    setStep({ step: 'document_details', document, revisionId })
  }

  function onChangeDocumentRevisionId(revisionId: number) {
    stack.push({ ...stack.head, revisionId } as NotificationStepperState)
  }

  function onShowContractVersion(
    contractOwnerProfileId: number,
    contractType: ContractType,
    targetProfileType: ProfileType,
    contractVersion: ContractVersion
  ) {
    setStep({
      step: 'contract_version',
      contractOwnerProfileId,
      contractType,
      targetProfileType,
      contractVersion,
    })
  }

  function onAddMissingInfo(ownerProfileId: number, mandatoryFieldsNames: string[], optionalFieldsNames: string[]) {
    setStep({
      step: 'profile_informations',
      informationsProfileId: authContext.loggedAccount?.profile?.profileId,
      informationsOwnerProfileId: ownerProfileId,
      mandatoryFieldsNames: mandatoryFieldsNames,
      optionalFieldsNames: optionalFieldsNames,
    })
  }

  function setStep(state: NotificationStepperState, initialize: boolean = false) {
    switch (state.step) {
      case 'notifications':
        setMaxWidth('sm')
        setTitle(t(msgIds.MSG_NOTIFICATIONS_TITLE))
        break
      case 'account':
        setMaxWidth('sm')
        setTitle(t(ProfileTypeTranslationMap[state.account?.profile?.type ?? ProfileType.none]))
        break
      case 'dox_details':
        setMaxWidth('sm')
        setTitle(t(msgIds.MSG_DOX))
        break
      case 'document_details':
        setMaxWidth('xl')
        setTitle(t(msgIds.MSG_DOCUMENT))
        break
      case 'contract_version':
        setMaxWidth('md')
        setTitle(t(msgIds.MSG_CONTRACT))
        break
      case 'profile_informations':
        setTitle(`${t(msgIds.MSG_DETAILS)}`)
        setMaxWidth('sm')
        break
    }
    initialize ? stack.setItems([state]) : stack.push(state)
  }

  return (
    <PageStepper {...rest} activeStep={stack.head?.step}>
      <ViewHeader
        title={title ?? ''}
        backButtonVisible={stack.length > 1}
        onClickBack={onClickBack}
        exitButtonVisible={true}
        onClickExit={onClickExit}
      />
      <PageStep step="notifications">
        <Stack overflow="hidden">
          <NotificationsCommandBar onGoTo={onGoTo} />
          <Divider />
          <Stack overflow="auto">
            <Notifications
              filter="not_archived"
              onClickAvatar={onClickAvatar}
              onCloseDialog={onClickExit}
              onShowDoxDetails={onShowDoxDetails}
              onShowDocumentDetails={onShowDocumentDetails}
              onShowContractVersion={onShowContractVersion}
            />
          </Stack>
        </Stack>
      </PageStep>
      <PageStep step="dox_details">
        <ViewContent display="flex" flexDirection="column" disablePadding={false} overflow={'auto'}>
          {stack.head?.dox && <DoxDetails dox={stack.head.dox} treatment={stack.head.treatment} />}
        </ViewContent>
      </PageStep>
      <PageStep step="document_details">
        <ViewContent display="flex" flexDirection="column" disablePadding={false} overflow={'auto'} height={700}>
          {stack.head?.document && (
            <DocumentEditor
              document={stack.head?.document}
              isEditing={false}
              sectionIndex={sectionIndex}
              isEditable={false}
              revisionId={stack.head?.revisionId ?? 0}
              isMarkdownContentChanged={false}
              markdownContentLen={0}
              onChangeDocument={() => {}}
              onChangeIsEditing={() => {}}
              onChangeSectionIndex={setSectionIndex}
              onChangeRevisionId={onChangeDocumentRevisionId}
              onChangeDraftRevision={() => {}}
              onChangeDraftDocument={() => {}}
              onChangeMarkdownContent={() => {}}
              onChangeMarkdownContentLen={() => {}}
              onChangeInitialCommand={() => {}}
              onCloseDocumentEditor={() => {}}
              onEditedDocumentAssignedDoxChanged={() => {}}
              isDesktop={false}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="contract_version">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.contractType && stack.head?.targetProfileType && stack.head?.contractVersion && (
            <ContractVersionViewer
              isEditable={true}
              sectionsEditability={
                stack.head?.contractOwnerProfileId === 0 ? 'allExceptMandatory' : 'allExceptNotRevocable'
              }
              contractOwnerProfileId={stack.head?.contractOwnerProfileId}
              contractType={stack.head?.contractType}
              targetProfileType={stack.head?.targetProfileType}
              consentsSnapshot={stack.head?.contractVersion}
              onAddMissingInfo={(mandatoryFieldsNames, optionalFieldsNames) => {
                if (!stack.head?.contractOwnerProfileId) return
                onAddMissingInfo(stack.head?.contractOwnerProfileId, mandatoryFieldsNames, optionalFieldsNames)
              }}
              disablePadding={true}
              onSaved={() => {
                stack.pop()
              }}
              onCanceled={() => {
                stack.pop()
              }}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="profile_informations">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.informationsProfileId && (
            <AccountEditorContextProvider>
              <ProfileInformations
                isInDialog={true}
                profileId={stack.head?.informationsProfileId}
                ownerProfileId={stack.head?.informationsOwnerProfileId}
                mandatoryFieldsNames={stack.head?.mandatoryFieldsNames}
                optionalFieldsNames={stack.head?.optionalFieldsNames}
              />
            </AccountEditorContextProvider>
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="account">
        {stack.head?.account &&
          (stack.head.account.profile?.type === ProfileType.customer ? (
            <AccountInfoCustomer account={stack.head.account} />
          ) : stack.head.account?.profile?.type === ProfileType.structure ? (
            <AccountInfoStructure account={stack.head.account} />
          ) : (
            <AccountInfoOperator account={stack.head.account} />
          ))}
      </PageStep>
    </PageStepper>
  )
}
