import { StackProps } from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../contexts/AuthContext'
import { ViewContent } from '../viewContent/ViewContent'
import { IDoxShareStepperProps } from './DoxShareStepper.types'
import { useStackState } from '../../hooks/Stack'
import { useChange } from '../../hooks/Change'
import { PageStepper } from '../pageStepper/PageStepper'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { PageStep } from '../pageStepper/PageStep'
import { AccountEditorContextProvider } from '../../contexts/AccountEditorContext'
import { ProfileInformations } from '../profileInformations/ProfileInformations'
import { DoxShare } from '../doxShare/DoxShare'
import { Dox } from '../../models/Dox'

type StepperState = {
  step: any
  dox?: Dox
  guestProfileId?: number
  informationsProfileId?: number
  informationsOwnerProfileId?: number
}

export function DoxShareStepper(props: IDoxShareStepperProps & StackProps): JSX.Element {
  const { dox } = props
  const { t } = useTranslation()
  const authContext = useAuthContext()
  const stack = useStackState<StepperState>([])

  useChange(() => {
    stack.setItems([{ step: 'permission', dox: dox }])
  }, [history])

  function onClickExit() {
    props.onClickExit()
  }

  function onSetProfileInformations() {
    stack.push({
      step: 'profile_informations',
      informationsProfileId: authContext.assistedAccountProfileId,
      informationsOwnerProfileId: authContext.linkedStructureProfileId ?? authContext.loggedProfileId,
    })
  }

  const backButtonVisible = stack.length > 1
  const headerTitle = stepTitle(stack.head)

  const stackProps: any = { ...props }
  delete stackProps.dox
  delete stackProps.onClickExit

  return (
    <PageStepper {...stackProps} activeStep={stack.head?.step}>
      <ViewHeader
        title={t(headerTitle)}
        backButtonVisible={backButtonVisible}
        onClickBack={stack.pop}
        exitButtonVisible={true}
        onClickExit={onClickExit}
      />
      <PageStep step="permission">
        <ViewContent disablePadding={true} sx={{ overflowY: 'auto' }}>
          {stack.head?.dox && (
            <DoxShare
              flexGrow={1}
              dox={stack.head?.dox}
              onSetProfileInformations={onSetProfileInformations}
              onClickExit={onClickExit}
            />
          )}
        </ViewContent>
      </PageStep>
      <PageStep step="profile_informations">
        <ViewContent disablePadding={true} overflow={'auto'}>
          {stack.head?.informationsProfileId && (
            <AccountEditorContextProvider neededFields={['email']}>
              <ProfileInformations
                isInDialog={true}
                profileId={stack.head?.informationsProfileId}
                ownerProfileId={stack.head?.informationsOwnerProfileId}
              />
            </AccountEditorContextProvider>
          )}
        </ViewContent>
      </PageStep>
    </PageStepper>
  )
}

function stepTitle(step: any) {
  if (!step) {
    return msgIds.MSG_DOX_SHARE_DIALOG_TITLE
  }

  switch (step.step) {
    case 'permission':
      return msgIds.MSG_DOX_SHARE_DIALOG_TITLE
    case 'profile_informations':
      return msgIds.MSG_PROFILE_INFORMATIONS
    default:
      return msgIds.MSG_DOX_SHARE_DIALOG_TITLE
  }
}
