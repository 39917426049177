import { Box, IconButton, Stack, StackProps, Typography, useTheme } from '@mui/material'
import { IViewHeaderProps } from './ViewHeader.types'
import { ArrowLeftIco, CloseIco } from '../icons'
import { forwardRef } from 'react'

function ViewHeaderRef(props: IViewHeaderProps & StackProps, ref: any): JSX.Element {
  const { title, exitButtonVisible, onClickExit, backButtonVisible, onClickBack, sx, ...rest } = props
  const theme = useTheme()
  return (
    <Stack
      ref={ref}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{
        m: 0,
        pl: 3,
        pr: 3,
        pt: 1.8,
        pb: 1.8,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        ...sx,
      }}
      {...rest}
    >
      {backButtonVisible && (
        <IconButton
          size="small"
          onClick={() => onClickBack && onClickBack()}
          sx={{ color: theme.palette.common.gray11 }}
        >
          <ArrowLeftIco />
        </IconButton>
      )}
      <Box flexGrow={1}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {exitButtonVisible && (
        <IconButton
          aria-label="close"
          size="small"
          onClick={() => onClickExit && onClickExit()}
          sx={{ color: theme.palette.common.gray11 }}
        >
          <CloseIco />
        </IconButton>
      )}
    </Stack>
  )
}

export const ViewHeader = forwardRef(ViewHeaderRef)
