import { Alert, CircularProgress, Stack, TextField } from '@mui/material'
import { IDoxDeleteConfirmProps } from './DoxDeleteConfirm.types'
import { DoxDetails } from '../doxDetails/DoxDetails'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Treatment } from '../../models/Treatment'
import * as dalDox from '../../dal/DalDox'
import * as dalTreatment from '../../dal/DalTreatment'
import { Utils } from '../../shared/Utils'
import { Dox } from '../../models/Dox'
import msgIds from '../../locales/msgIds'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'

export function DoxDeleteConfirm(props: IDoxDeleteConfirmProps): JSX.Element {
  const { doxId, onConfirm, onCancel, disablePadding, ...stackProps } = props
  const { t } = useTranslation()
  const archiveContext = useArchiveContext()
  const [dox, setDox] = useState<Dox>()
  const [treatment, setTreatment] = useState<Treatment>()
  const [word, setWord] = useState<string>('')
  const [wordError, setWordError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const showExtraConfirm = dox
    ? dox.contentDocumentCount > 0 || dox.contentDraftDocumentCount > 0 || dox.contentDoxCount > 0
    : false

  useEffect(() => {
    if (!doxId) {
      return
    }
    const loadDoxDetails = async (abortSignal: AbortSignal) => {
      try {
        setDox(undefined)
        // TODO: consider expanding the getDoxDetails call on the backend side with the
        //       'treatment' attribute to allow treatment fetch with a single call
        const dox = await dalDox.getDoxDetails(abortSignal, doxId)
        archiveContext.rwArchiveDoxes.initRoutes(dox) // the routes are valorised only if the dox belongs to the owned archive
        onCheckIsEmpty(dox.contentDocumentCount === 0)
        setDox(dox)

        const treatment = dox.treatmentId ? await dalTreatment.getTreatment(abortSignal, dox.treatmentId) : undefined
        setTreatment(treatment)
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      }
    }

    const abortController = new AbortController()
    loadDoxDetails(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [doxId, t])

  function onChangeWord(word: string) {
    setWordError(false)
    setWord(word)
  }

  function onCheckIsEmpty(isEmpty: boolean) {
    setIsEmpty(isEmpty)
  }

  function onOk() {
    if (isEmpty || word === t(msgIds.MSG_DOX_DELETE_CONFIRM_WORD)) {
      onConfirm()
    } else {
      setWordError(true)
    }
  }

  return (
    <Stack spacing={2} justifyContent="space-between" overflow="hidden" {...stackProps}>
      {showExtraConfirm && (
        <>
          <Alert severity="error">{t(msgIds.MSG_DOX_DELETE_CONFIRM_ALERT)}</Alert>
          <TextField
            label={t(msgIds.MSG_DOX_DELETE_CONFIRM_WORD_LABEL)}
            error={wordError}
            onChange={(event) => onChangeWord(event.target.value)}
          />
        </>
      )}
      {!dox && (
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
          <CircularProgress />
        </Stack>
      )}
      {dox && (
        <Stack flexGrow={1} flexShrink={1} overflow="auto">
          <DoxDetails dox={dox} treatment={treatment} />
        </Stack>
      )}
      <ViewActions disablePadding={disablePadding}>
        <ViewActionsButton onClick={onOk} defaultAction>
          {t(msgIds.MSG_OK)}
        </ViewActionsButton>
        <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}
