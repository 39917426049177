import { Document } from '../../models/Document'
import { DocumentRevision } from '../../models/DocumentRevision'
import { isPersonalDoxMimetype } from '../../shared/Constants'
import { IDocumentEditorState } from './DocumentEditor.types'
import { validateDocumentInfoForm } from './DocumentInfoFormUtils'

export function getDocumentRevision(
  document: Document | undefined,
  revisionId: number | undefined,
  isEditing: boolean
): DocumentRevision | undefined {
  if (revisionId === undefined) {
    return isEditing ? document?.getDraftRevision() : document?.getLastArchivedRevision()
  } else if (revisionId === 0) {
    return (
      (isEditing ? document?.getDraftRevision() : document?.getLastArchivedRevision()) || document?.revisions?.at(0)
    )
  } else {
    return document?.getRevision(revisionId)
  }
}

export function areRevisionInfoChanged(
  draftRevision: DocumentRevision | undefined,
  revision: DocumentRevision | undefined,
  draftDocument: Document | undefined,
  document: Document
): boolean {
  return (
    draftRevision?.name !== revision?.name ||
    draftRevision?.editedAt !== revision?.editedAt ||
    draftRevision?.creatorIdentity !== revision?.creatorIdentity ||
    draftDocument?.anonymousAt !== document.anonymousAt
  )
}

export function isRevisionContentChanged(
  draftRevision: DocumentRevision | undefined,
  revision: DocumentRevision | undefined
): boolean {
  return (draftRevision?.checksum || '') !== (revision?.checksum || '')
}

export function getDocumentEditorSavingInfo(documentEditorState: Partial<IDocumentEditorState>) {
  const {
    revisionId,
    document,
    draftDocument,
    draftRevision,
    isEditing,
    isMarkdownContentChanged,
    markdownContentLen,
  } = documentEditorState

  if (isEditing) {
    const revision = getDocumentRevision(document, revisionId || 0, isEditing)

    const areInfoChanged = Boolean(document && areRevisionInfoChanged(draftRevision, revision, draftDocument, document))
    const documentInfoFormErrors =
      draftDocument && draftRevision ? validateDocumentInfoForm(draftDocument, draftRevision) : {}
    const areDraftInfoValid = Object.values(documentInfoFormErrors).filter((e) => !!e).length === 0

    let isContentChanged = false
    let contentLen = 0
    if (draftRevision) {
      isContentChanged =
        isPersonalDoxMimetype(draftRevision.mimetype) === true
          ? isMarkdownContentChanged ?? false
          : isRevisionContentChanged(draftRevision, revision)
      contentLen =
        draftRevision && isPersonalDoxMimetype(draftRevision.mimetype) === true
          ? markdownContentLen ?? 0
          : DocumentRevision.getContentLength(draftRevision)
    }
    const isContentValid = contentLen !== 0

    const canSave: boolean = (areInfoChanged || isContentChanged) && areDraftInfoValid && isContentValid
    const canArchive: boolean = Boolean(document?.draftRevisionId) && !areInfoChanged && !isContentChanged

    const info = {
      areInfoChanged,
      documentInfoFormErrors,
      areDraftInfoValid,

      isContentChanged,
      contentLen,
      isContentValid,

      canSave,
      canArchive,
    }
    return info
  } else {
    const info = {
      areInfoChanged: false,
      documentInfoFormErrors: {},
      areDraftInfoValid: true,

      isContentChanged: false,
      contentLen: 0,
      isContentValid: true,

      canSave: false,
      canArchive: Boolean(document?.draftRevisionId),
    }
    return info
  }
}
