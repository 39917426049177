import {
  Box,
  Button,
  ButtonProps,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useMemo, useRef } from 'react'
import { RetentionRules, isOperator } from '../../shared/Constants'
import FromToDatePicker from '../fromToDatePicker/FromToDatePicker'
import { CloseIco, InfoIco } from '../icons'
import { useChange } from '../../hooks/Change'
import { useArchiveContext } from '../../contexts/ArchiveContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { Treatment } from '../../models/Treatment'
import TreatmentListDialog from '../../dialogs/treatmentListDialog/TreatmentListDialog'
import * as dalDox from '../../dal/DalDox'
import * as dalTreatment from '../../dal/DalTreatment'
import { Utils, ValFieldType, ValType } from '../../shared/Utils'
import { InfoTooltip } from '../infoTooltip/InfoTooltip'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import { enqueueSnackbar } from 'notistack'
import { ViewContent } from '../viewContent/ViewContent'
import { IDoxEditorProps } from './DoxEditor.types'
import { ITreatmentListDialogData } from '../../dialogs/treatmentListDialog/TreatmentListDialog.types'

type RetentionState = 'none' | 'direct' | 'inherited'

export function DoxEditor(props: IDoxEditorProps): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const archiveContext = useArchiveContext()
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [draft, setDraft] = useState<any>({})
  const [treatments, setTreatments] = useState<Treatment[]>([])

  // dialogs
  const [treatmentListDialogData, setTreatmentListDialogData] = useState<ITreatmentListDialogData>()
  const [treatmentListDialogOpen, setTreatmentListDialogOpen] = useState(false)

  const operator = isOperator(authContext.loggedProfileType)
  const doxRoot = props.dox.isRoot

  const nameFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (nameFieldRef.current) {
      nameFieldRef.current.focus()
      if (draft.name.startsWith(t(msgIds.MSG_DOX_EDITOR_NEW_DOX_NAME))) {
        nameFieldRef.current.select()
      }
    }
  }, [])

  useChange(() => {
    const draft = {
      name: props.dox.name,
      notes: props.dox.notes,
      retentionInheritedFrom: props.dox.retentionInheritedFrom,
      treatmentId: props.dox.treatmentId,
      retentionRules: props.dox.retentionRules,
      retentionStartAt: props.dox.retentionStartAt,
      retentionEndAt: props.dox.retentionEndAt,
    }
    setDraft(draft)
  }, [props.dox])

  const parentDox = archiveContext.rwArchiveDoxes.getDox(props.dox.parentId)
  const nameValErr: string | null = useMemo(() => {
    let error: string | undefined = undefined
    const isValidated: boolean = Utils.isValidField(draft.name, ValFieldType.text, (err) => (error = err), t, [
      { type: ValType.notNull },
      { type: ValType.notEmpty },
    ])
    if (!isValidated) {
      return error || 'error: empty field not allowed'
    }
    // check if edited dox name match with dox name of a brother
    const brothers = parentDox?.children || []
    const brotherNames = brothers.filter((p) => p.id !== props.dox.id).map((p) => p.name)
    const isNameDuplicated = !!brotherNames.find((p) => p.toLocaleLowerCase() === draft.name.toLocaleLowerCase())
    if (isNameDuplicated) {
      return t(msgIds.MSG_VAL_ERR_DUPLICATED_NAME) as string
    }
    return null
  }, [draft, props.dox.id, parentDox, t])

  const isValidated = nameValErr === null

  const treatment = treatments.find(({ id }) => id === draft.treatmentId)

  let retentionState: RetentionState = useMemo(() => {
    if (!!draft.treatmentId && !draft.retentionInheritedFrom) {
      return 'direct'
    } else if (!!draft.treatmentId && !!draft.retentionInheritedFrom) {
      return 'inherited'
    } else {
      return 'none'
    }
  }, [draft.treatmentId, draft.retentionInheritedFrom])

  const areTreatmentsNeeded = operator && (retentionState === 'direct' || retentionState === 'none')
  const areDoxDetailsNeeded = operator && !!props.dox.treatmentId && props.mode === 'edit'

  useEffect(() => {
    const loadTreatments = async (abortSignal: AbortSignal) => {
      try {
        setIsLoading(true)
        if (areDoxDetailsNeeded) {
          const data = await dalDox.getDoxDetails(abortController.signal, props.dox.id)
          props.dox.retentionRules = data.retentionRules
          props.dox.retentionStartAt = data.retentionStartAt
          props.dox.retentionEndAt = data.retentionEndAt
        }

        if (areTreatmentsNeeded) {
          const data = await dalTreatment.getTreatments(
            abortSignal,
            authContext.linkedStructureProfileId || 0,
            true,
            false
          )
          setTreatments(data)
        }
      } catch (err) {
        Utils.enqueueSnackbarError2(err, t)
      } finally {
        setIsLoading(false)
      }
    }

    const abortController = new AbortController()

    if (areDoxDetailsNeeded || areTreatmentsNeeded) {
      loadTreatments(abortController.signal)
    }

    return () => {
      abortController.abort()
    }
  }, [areDoxDetailsNeeded, areTreatmentsNeeded, authContext.linkedStructureProfileId, t])

  function patchDraft(key: string, value: any) {
    setDraft((draft: any) => {
      const updatedDraft = { ...draft, [key]: value }

      if (key === 'treatmentId') {
        if (value) {
          if (!updatedDraft.retentionRules) {
            const treatment = treatments.find((p) => p.id === updatedDraft.treatmentId)
            updatedDraft.retentionRules = RetentionRules.centralized
            updatedDraft.retentionStartAt = Utils.today()
            updatedDraft.retentionEndAt = treatment?.computeRetentionEndFrom(updatedDraft.retentionStartAt)
          } else {
            updatedDraft.retentionRules = RetentionRules.centralized
            updatedDraft.retentionStartAt = undefined
            updatedDraft.retentionEndAt = undefined
          }
        } else {
          updatedDraft.retentionRules = RetentionRules.none
          updatedDraft.retentionStartAt = undefined
          updatedDraft.retentionEndAt = undefined
        }
      }

      if (key === 'retentionRules') {
        if (value === RetentionRules.specific) {
          updatedDraft.retentionStartAt = undefined
          updatedDraft.retentionEndAt = undefined
        }
      }

      return updatedDraft
    })
  }

  function onTreatment() {
    setTreatmentListDialogOpen(true)
    setTreatmentListDialogData({
      treatments: treatments,
      onResult: async (result) => {
        setTreatmentListDialogOpen(false)
        if (result.userChoice !== 'yes') return
        const selectedTreatment = result.treatment
        if (selectedTreatment) {
          patchDraft('treatmentId', selectedTreatment.id)
        }
      },
    })
  }

  async function onSave() {
    const toSave = { ...draft }
    console.log({ toSave })
    if (draft.retentionInheritedFrom) {
      toSave.treatmentId = undefined
      toSave.retentionRules = undefined
      toSave.retentionStartAt = undefined
      toSave.retentionEndAt = undefined
    }

    const abortController = new AbortController()
    try {
      if (props.mode === 'edit') {
        const updatedDox = await dalDox.updateDox(
          abortController.signal,
          props.dox.id,
          toSave.name,
          toSave.notes,
          toSave.treatmentId,
          toSave.retentionRules,
          toSave.retentionStartAt,
          toSave.retentionEndAt
        )
        archiveContext.replaceDox(props.dox.id, updatedDox, true)
        enqueueSnackbar(t(msgIds.MSG_DOX_UPDATED_SUCCESSFULLY), { variant: 'success' })
        props.onUpdated(updatedDox)
      } else {
        const dox = await dalDox.createDox(
          abortController.signal,
          toSave.name,
          toSave.notes,
          props.dox.targetProfileId,
          props.dox.parentId,
          toSave.treatmentId,
          toSave.retentionRules,
          toSave.retentionStartAt,
          toSave.retentionEndAt
        )
        archiveContext.addDox(dox, true)
        enqueueSnackbar(t(msgIds.MSG_DOX_CREATED_SUCCESSFULLY), { variant: 'success' })
        props.onUpdated(dox)
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    }
  }

  // ugly
  const stackProps: any = { ...props }
  delete stackProps.mode
  delete stackProps.dox
  delete stackProps.onUpdated
  delete stackProps.onCancel

  return (
    <Stack alignItems="stretch" justifyContent="space-between" {...stackProps}>
      {treatmentListDialogData && (
        <TreatmentListDialog
          {...treatmentListDialogData}
          isOpen={treatmentListDialogOpen}
          onClose={() => setTreatmentListDialogOpen(false)}
        />
      )}
      <ViewContent id="dox-editor-view-content" spacing={2} alignItems="stretch">
        {!(props.mode === 'edit' && doxRoot && operator) && (
          <TextField label={t(msgIds.MSG_DOX_EDITOR_PARENT_LABEL)} value={props.dox.parentsRouteWithRoot} disabled />
        )}
        {!(props.mode === 'edit' && doxRoot && operator) && (
          <TextField
            inputRef={nameFieldRef}
            label={t(msgIds.MSG_DOX_EDITOR_NAME_LABEL)}
            value={draft.name}
            onChange={(event) => patchDraft('name', event.target.value)}
            error={!!nameValErr}
            helperText={nameValErr}
          />
        )}
        {!(props.mode === 'edit' && doxRoot && operator) && (
          <TextField
            multiline
            label={t(msgIds.MSG_DOX_EDITOR_NOTES_LABEL)}
            value={draft.notes}
            onChange={(event) => patchDraft('notes', event.target.value)}
          />
        )}

        {operator &&
          (doxRoot ||
            (props.mode === 'new' && (retentionState === 'direct' || retentionState === 'none')) ||
            (props.mode === 'edit' && (retentionState === 'direct' || retentionState === 'none'))) && (
            <Stack>
              <SelectExternalButton
                onClick={onTreatment}
                variant="outlined"
                color="secondary"
                sx={{
                  justifyContent: 'space-between',
                  m: 0,
                  py: 1.9,
                  px: 1.5,
                  borderColor: theme.palette.common.gray6,
                }}
              >
                <Typography
                  sx={{
                    display: treatment ? 'block' : 'none',
                    position: 'absolute',
                    left: '8px',
                    top: 'calc(-1em - 0.5px)',
                    py: '1px',
                    px: '6px',
                    textAlign: 'left',
                    textTransform: 'none',
                    backgroundColor: 'white',
                  }}
                  variant="caption"
                >
                  {t(msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.primary, textTransform: 'none', textAlign: 'left' }}
                >
                  {!treatment && (
                    <Typography style={{ color: theme.palette.text.secondary }}>
                      {t(msgIds.MSG_DOX_EDITOR_RETENTION_PLACEHOLDER)}
                    </Typography>
                  )}
                  {treatment && (
                    <>
                      <span style={{ color: theme.palette.text.secondary }}>{t(msgIds.MSG_FINALITY)}:</span>{' '}
                      {treatment.code} {treatment.shortDescription} <br />
                      <span style={{ color: theme.palette.text.secondary }}>{t(msgIds.MSG_RETENTION)}:</span>{' '}
                      {Utils.getRetentionDurationDsc(treatment, t)}
                    </>
                  )}
                </Typography>
                {treatment && (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      patchDraft('treatmentId', undefined)
                    }}
                  >
                    <CloseIco />
                  </IconButton>
                )}
              </SelectExternalButton>
            </Stack>
          )}

        {operator &&
          ((props.mode === 'new' && retentionState === 'direct') ||
            (props.mode === 'edit' && (retentionState === 'direct' || retentionState === 'inherited'))) && (
            <>
              <Box>
                <Typography variant="subtitle1">{t(msgIds.MSG_DOX_EDITOR_RETENTION_TITLE)}</Typography>
                <Typography variant="subtitle2">{t(msgIds.MSG_DOX_EDITOR_RETENTION_DESCRIPTION)}</Typography>
              </Box>
              <RadioGroup
                aria-labelledby="retention-rules-radio-buttons-group-label"
                name="retention-rules-radio-buttons-group"
                value={draft.retentionRules}
                onChange={(_event, value) => patchDraft('retentionRules', parseInt(value, 10))}
              >
                <FormControlLabel
                  value={RetentionRules.specific}
                  control={<Radio />}
                  label={t(msgIds.MSG_DOX_EDITOR_RETENTION_SPECIFIC_LABEL)}
                />
                <FormControlLabel
                  value={RetentionRules.centralized}
                  control={<Radio />}
                  label={t(msgIds.MSG_DOX_EDITOR_RETENTION_CENTRALIZED_LABEL)}
                />
              </RadioGroup>
            </>
          )}

        {operator && draft.retentionRules === RetentionRules.centralized && (
          <Stack direction="row" flexWrap="nowrap" alignItems="center" spacing={1}>
            <FromToDatePicker
              flexWrap="nowrap"
              fromDate={draft.retentionStartAt}
              toDate={draft.retentionEndAt}
              onChangeFromDate={(date) => patchDraft('retentionStartAt', date)}
              onChangeToDate={(date) => patchDraft('retentionEndAt', date)}
              disabled={retentionState !== 'direct'}
            />
            <InfoTooltip title={t(msgIds.MSG_DOX_EDITOR_RETENTION_END_AT_INFO)} placement="top" arrow>
              <Box>
                <InfoIco sx={{ marginTop: '12px' }} />
              </Box>
            </InfoTooltip>
          </Stack>
        )}
      </ViewContent>
      <ViewActions>
        <ViewActionsButton autoFocus defaultAction onClick={onSave} disabled={!isValidated}>
          {t(msgIds.MSG_SAVE)}
        </ViewActionsButton>
        <ViewActionsButton onClick={props.onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
      </ViewActions>
    </Stack>
  )
}

const SelectExternalButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}))
