import React, { useMemo, useState } from 'react'
import { ArchiveDoxes } from '../shared/types/ArchiveDoxes'
import { ArchiveTypes } from '../models/ArchiveTypes'
import { Dox } from '../models/Dox'
import { DoxTemplate } from '../models/DoxTemplate'
import { Treatment } from '../models/Treatment'

export interface IArchiveContextState {
  archiveType: ArchiveTypes
  rwArchiveDoxes: ArchiveDoxes
  roArchiveDoxes: ArchiveDoxes
  setArchiveType: (archiveType: ArchiveTypes) => void
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => void
  // manipulation actions
  addDox: (dox: Dox, isRW: boolean) => void
  removeDox: (dox: Dox, isRW: boolean) => void
  replaceDox: (doxId: number, newDox: Dox, isRW: boolean) => void
  moveDox: (dox: Dox, newParentId: number, isRW: boolean) => void
  createOrganizedDoxFromTemplate: (
    parentDox: Dox,
    doxTemplate: DoxTemplate,
    treatments: Treatment[],
    isRW: boolean
  ) => Promise<void>
}

const initialState: IArchiveContextState = {
  archiveType: ArchiveTypes.none,
  rwArchiveDoxes: new ArchiveDoxes(),
  roArchiveDoxes: new ArchiveDoxes(),
  setArchiveType: (archiveType: ArchiveTypes) => {},
  setRWArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
  setROArchiveDoxes: (archiveDoxes: ArchiveDoxes) => {},
  // manipulation actions
  addDox: () => {},
  removeDox: () => {},
  replaceDox: () => {},
  moveDox: () => {},
  createOrganizedDoxFromTemplate: async () => {},
}

interface IProps {
  children?: React.ReactNode
}

const ArchiveContext = React.createContext<IArchiveContextState>(initialState)
export const ArchiveContextProvider: React.FC<IProps> = ({ children }) => {
  const [archiveType, setArchiveType] = useState<ArchiveTypes>(ArchiveTypes.none)
  const [rwArchiveDoxes, setRWArchiveDoxes] = useState<ArchiveDoxes>(new ArchiveDoxes())
  const [roArchiveDoxes, setROArchiveDoxes] = useState<ArchiveDoxes>(new ArchiveDoxes())

  const addDox = (dox: Dox, isRW: boolean) => {
    if (isRW) {
      rwArchiveDoxes.addDox(dox)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      roArchiveDoxes.addDox(dox)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const removeDox = (dox: Dox, isRW: boolean) => {
    if (isRW) {
      rwArchiveDoxes.removeDox(dox)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      roArchiveDoxes.removeDox(dox)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const replaceDox = (doxId: number, newDox: Dox, isRW: boolean) => {
    if (isRW) {
      rwArchiveDoxes.replaceDox(doxId, newDox)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      roArchiveDoxes.replaceDox(doxId, newDox)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const moveDox = (dox: Dox, newParentId: number, isRW: boolean) => {
    if (isRW) {
      rwArchiveDoxes.moveDox(dox, newParentId)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      roArchiveDoxes.moveDox(dox, newParentId)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const createOrganizedDoxFromTemplate = async (
    parentDox: Dox,
    doxTemplate: DoxTemplate,
    treatments: Treatment[],
    isRW: boolean
  ) => {
    if (isRW) {
      await rwArchiveDoxes.createOrganizedDoxFromTemplate(parentDox, doxTemplate, treatments)
      setRWArchiveDoxes(rwArchiveDoxes.getCopy())
    } else {
      await roArchiveDoxes.createOrganizedDoxFromTemplate(parentDox, doxTemplate, treatments)
      setROArchiveDoxes(roArchiveDoxes.getCopy())
    }
  }

  const contextValue = useMemo<IArchiveContextState>(
    () => ({
      archiveType,
      rwArchiveDoxes,
      roArchiveDoxes,
      setArchiveType,
      setRWArchiveDoxes,
      setROArchiveDoxes,
      addDox,
      removeDox,
      replaceDox,
      moveDox,
      createOrganizedDoxFromTemplate,
    }),
    [
      archiveType,
      rwArchiveDoxes,
      roArchiveDoxes,
      setArchiveType,
      setRWArchiveDoxes,
      setROArchiveDoxes,
      addDox,
      removeDox,
      replaceDox,
      moveDox,
      createOrganizedDoxFromTemplate,
    ]
  )

  return <ArchiveContext.Provider value={contextValue}>{children}</ArchiveContext.Provider>
}

export const useArchiveContext = (): IArchiveContextState => {
  const context = React.useContext(ArchiveContext)
  if (!context) {
    throw new Error('useArchiveContext must be used inside ArchiveContextProvider')
  }
  return context
}
