import { useMemo, useState } from 'react'
import { Dox } from '../../models/Dox'
import { ChevronRightIco, ExpandMoreIco, MoreVertIco, RetentionDisabledIco, RetentionIco } from '../icons'
import { DoxSelectionMode, IDoxesNavProps } from './DoxesNav.types'
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { MouseEvent } from 'react'
import { ArchiveTypes } from '../../models/ArchiveTypes'
import { Utils } from '../../shared/Utils'
import { useTranslation } from 'react-i18next'
import DoxCommandsMenu from '../doxCommandsMenu/DoxCommandsMenu'
import { useArchiveContext } from '../../contexts/ArchiveContext'

export default function DoxesNav(props: IDoxesNavProps): JSX.Element {
  const archiveContext = useArchiveContext()
  const availableDoxes = useMemo(() => {
    return archiveContext.archiveType === ArchiveTypes.structureArchiveSharedByCustomer ||
      archiveContext.archiveType === ArchiveTypes.customerArchiveReceived
      ? archiveContext.roArchiveDoxes
      : archiveContext.rwArchiveDoxes
  }, [archiveContext.archiveType, archiveContext.rwArchiveDoxes.distinct, archiveContext.roArchiveDoxes.distinct])

  const roots = sort(availableDoxes.roots, archiveContext.archiveType)
  const expandedDoxIds: number[] = props.selectedDoxId
    ? availableDoxes.getDoxIdsFromLeafToRoot(props.selectedDoxId)
    : []

  function onClickDox(dox: Dox) {
    const index = props.selection.findIndex(({ id }) => dox.id === id)
    const selected = index !== -1
    let selection = [...props.selection]
    if (props.selectionMode === DoxSelectionMode.multiple) {
      if (!selected) {
        selection = [...props.selection, dox]
      } else {
        selection.splice(index, 1)
      }
    } else if (props.selectionMode === DoxSelectionMode.hierarchical) {
      const descendants = listAllDescendants(dox)
      if (!selected) {
        selection = [...props.selection, dox, ...descendants]
      } else {
        const ids = descendants.map(({ id }) => id).concat([dox.id])
        selection = selection.filter(({ id }) => !ids.includes(id))
      }
    } else {
      // fallback to single mode
      if (!selected) {
        selection = [dox]
      } else {
        selection = []
      }
    }

    if (archiveContext.archiveType === ArchiveTypes.customerArchiveReceived && selection.length === 0) {
      return
    }
    props.onChangeSelection(selection)
  }

  return (
    <DoxesNavList
      roots={roots}
      selection={props.selection}
      expandedDoxIds={expandedDoxIds}
      onClickDox={onClickDox}
      level={0}
      onDoxCommand={props.onDoxCommand}
    />
  )
}

interface IDoxesNavListProps {
  roots: Dox[]
  selection: Dox[]
  expandedDoxIds?: number[]
  onClickDox: (dox: Dox) => void
  level: number
  onDoxCommand: (dox: Dox, action: string) => void
}

function DoxesNavList(props: IDoxesNavListProps): JSX.Element {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true} disablePadding>
      {props.roots.map((dox) => {
        return (
          <DoxListItem
            key={dox.id}
            dox={dox}
            selection={props.selection}
            onClickDox={props.onClickDox}
            level={props.level}
            expandedDoxIds={props.expandedDoxIds}
            childrenExpanded={props.level === 0 || props.expandedDoxIds?.includes(dox.id)}
            onDoxCommand={props.onDoxCommand}
          />
        )
      })}
    </List>
  )
}

// TODO put it in a "global" file?
const iconSizePx = 34

interface IDoxListItemProps {
  dox: Dox
  selection: Dox[]
  onClickDox: (dox: Dox) => void
  expandedDoxIds?: number[]
  childrenExpanded?: boolean
  level: number
  onDoxCommand: (dox: Dox, action: string) => void
}

function DoxListItem(props: IDoxListItemProps): JSX.Element {
  const archiveContext = useArchiveContext()
  const { dox } = props
  const [childrenExpanded, setChildrenExpanded] = useState(props.childrenExpanded === true)
  const hasChildren = dox.children.length !== 0
  const children = sort(dox.children, archiveContext.archiveType)

  function toggleChildren(event: MouseEvent<HTMLButtonElement, any>) {
    event.stopPropagation()
    event.preventDefault()
    setChildrenExpanded((flag) => !flag)
  }

  const levelPadding = props.level * 2 * 8
  const paddingLeft = hasChildren ? levelPadding : iconSizePx + levelPadding

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  function onClickMore(event: MouseEvent<HTMLElement>) {
    setAnchorEl((a) => {
      if (a) {
        return null
      } else {
        return event.currentTarget
      }
    })
  }
  function onCloseCommands() {
    setAnchorEl(null)
  }

  const moreButtonId = `dox-more-button-${dox.id}`

  return (
    <>
      <ListItem
        disablePadding
        disableGutters={true}
        sx={{ width: '100%', paddingLeft: `${paddingLeft}px` }}
        secondaryAction={
          <>
            <IconButton
              id={moreButtonId}
              size="small"
              aria-haspopup="true"
              aria-expanded={anchorEl ? 'true' : undefined}
              aria-controls={anchorEl ? 'dox-commands-menu' : undefined}
              onClick={onClickMore}
            >
              <MoreVertIco />
            </IconButton>
            <DoxCommandsMenu
              dox={dox}
              anchorEl={anchorEl}
              anchorId={moreButtonId}
              onClose={onCloseCommands}
              onDoxCommand={props.onDoxCommand}
            />
          </>
        }
        dense={true}
      >
        {hasChildren && (
          <IconButton size="small" onClick={toggleChildren}>
            {childrenExpanded ? <ExpandMoreIco /> : <ChevronRightIco />}
          </IconButton>
        )}
        <ListItemButton
          onClick={() => props.onClickDox(dox)}
          selected={isDoxSelected(dox, props.selection)}
          disableGutters={false}
          dense={true}
          sx={{ paddingLeft: '8px' }}
        >
          <DoxItem dox={dox} />
        </ListItemButton>
      </ListItem>
      <Collapse in={childrenExpanded} timeout="auto" unmountOnExit={false}>
        <DoxesNavList
          roots={children}
          selection={props.selection}
          expandedDoxIds={props.expandedDoxIds}
          onClickDox={props.onClickDox}
          level={props.level + 1}
          onDoxCommand={props.onDoxCommand}
        />
      </Collapse>
    </>
  )
}

interface IDoxItemProps {
  dox: Dox
}

function DoxItem({ dox }: IDoxItemProps): JSX.Element {
  const { i18n } = useTranslation()
  const archiveContext = useArchiveContext()

  if (archiveContext.archiveType === ArchiveTypes.structureArchiveForCustomer) {
    if (!dox.treatmentId) {
      return (
        <>
          <ListItemIcon sx={{ minWidth: '32px' }}>
            <RetentionDisabledIco opacity={0.5} />
          </ListItemIcon>
          <ListItemText primary={dox.name} primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }} />
        </>
      )
    } else if (dox.treatmentId && !dox.retentionInheritedFrom) {
      return (
        <>
          <ListItemIcon sx={{ minWidth: '32px' }}>
            <RetentionIco />
          </ListItemIcon>
          <ListItemText primary={dox.name} primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }} />
        </>
      )
    } else {
      return (
        <>
          <ListItemIcon sx={{ minWidth: '32px' }}>
            <RetentionIco opacity={0.5} />
          </ListItemIcon>
          <ListItemText primary={dox.name} primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }} />
        </>
      )
    }
  } else if (archiveContext.archiveType === ArchiveTypes.customerArchiveReceived) {
    const secondaryText = Utils.toLocaleDateString(dox.deliveredAt, i18n) + ' ' + dox.deliveredByIdentity
    return (
      <ListItemText
        primary={dox.name}
        secondary={secondaryText}
        primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }}
      />
    )
  } else {
    return <ListItemText primary={dox.name} primaryTypographyProps={{ noWrap: true, textOverflow: 'ellipsis' }} />
  }
}

function sort(children: Dox[], archiveType: ArchiveTypes): Dox[] {
  if (archiveType === ArchiveTypes.customerArchiveReceived) {
    return children.toSorted((a, b) => {
      if (!a.deliveredAt && !b.deliveredAt) {
        return 0
      } else if (!a.deliveredAt && b.deliveredAt) {
        return -1
      } else if (a.deliveredAt && !b.deliveredAt) {
        return 1
      } else {
        if (a.deliveredAt! < b.deliveredAt!) {
          return -1
        } else if (a.deliveredAt! > b.deliveredAt!) {
          return 1
        } else {
          return 0
        }
      }
    })
  } else {
    return children.toSorted((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }
}

function isDoxSelected(dox: Dox, selection: Dox[]) {
  return !!selection.find(({ id }) => dox.id === id)
}

function listAllDescendants(dox: Dox): Dox[] {
  if (dox.children.length === 0) {
    return []
  }
  return dox.children.reduce(
    (all, child) => {
      return all.concat(listAllDescendants(child))
    },
    [...dox.children]
  )
}
