import { ArchiveItemSourceType, ArchiveItemSourceType_all } from '../shared/Constants'
import { DocumentRevision, IDocumentRevisionJson } from './DocumentRevision'

export interface IDocumentJson {
  document_id: number
  target_profile_id: number
  target_identity: string
  author_profile_id: number
  author_identity: string
  owner_profile_id: number
  owner_identity: string
  anonymous_at: string
  source_type: number
  content_type: number
  draft_revision_id: number
  last_archived_revision_id: number
  delivered_at: string
  expired_at: string
  deleted_at: string
  is_in_diary: boolean
  created_at: string
  updated_at: string
  dox_ids: number[]
  revisions: IDocumentRevisionJson[]
}

export class Document {
  documentId: number = 0
  targetProfileId: number = 0
  targetIdentity: string = ''
  authorProfileId: number = 0
  authorIdentity: string = ''
  ownerProfileId: number = 0
  ownerIdentity: string = ''
  anonymousAt: string = ''
  sourceType: ArchiveItemSourceType_all = ArchiveItemSourceType.none
  contentType: number = 0
  draftRevisionId: number = 0
  lastArchivedRevisionId: number = 0
  deliveredAt: string = ''
  expiredAt: string = ''
  deletedAt: string = ''
  isInDiary: boolean = false
  createdAt: string = ''
  updatedAt: string = ''
  doxIds: number[] = []
  revisions: DocumentRevision[] = []

  constructor() {}

  public static serialize(obj: Document): IDocumentJson {
    return {
      document_id: obj.documentId,
      target_profile_id: obj.targetProfileId,
      target_identity: obj.targetIdentity,
      author_profile_id: obj.authorProfileId,
      author_identity: obj.authorIdentity,
      owner_profile_id: obj.ownerProfileId,
      owner_identity: obj.ownerIdentity,
      anonymous_at: obj.anonymousAt,
      source_type: obj.sourceType,
      content_type: obj.contentType,
      draft_revision_id: obj.draftRevisionId,
      last_archived_revision_id: obj.lastArchivedRevisionId,
      delivered_at: obj.deliveredAt,
      expired_at: obj.expiredAt,
      deleted_at: obj.deletedAt,
      is_in_diary: obj.isInDiary,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      dox_ids: obj.doxIds,
      revisions: DocumentRevision.serializeArray(obj.revisions),
    }
  }

  public static deserialize(json: IDocumentJson): Document {
    const res = new Document()
    res.documentId = json.document_id
    res.targetProfileId = json.target_profile_id
    res.targetIdentity = json.target_identity
    res.authorProfileId = json.author_profile_id
    res.authorIdentity = json.author_identity
    res.ownerProfileId = json.owner_profile_id
    res.ownerIdentity = json.owner_identity
    res.anonymousAt = json.anonymous_at
    res.sourceType = json.source_type
    res.contentType = json.content_type
    res.draftRevisionId = json.draft_revision_id
    res.lastArchivedRevisionId = json.last_archived_revision_id
    res.deliveredAt = json.delivered_at
    res.expiredAt = json.expired_at
    res.deletedAt = json.deleted_at
    res.isInDiary = json.is_in_diary
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    res.doxIds = json.dox_ids
    res.revisions = DocumentRevision.deserializeArray(json.revisions)
    return res
  }

  public getLastArchivedRevision(): DocumentRevision | undefined {
    return this.revisions?.find((p) => p.revisionId === this.lastArchivedRevisionId)
  }

  public getDraftRevision(): DocumentRevision | undefined {
    return this.revisions?.find((p) => p.revisionId === this.draftRevisionId)
  }

  public getRevision(revisionId: number): DocumentRevision | undefined {
    return this.revisions?.find((p) => p.revisionId === revisionId)
  }

  public getName(): string | undefined {
    const revision = this.getLastArchivedRevision() || this.getDraftRevision()
    return revision?.name || revision?.filename
  }
}
