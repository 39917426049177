import { Box, Button, Card, Divider, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import { IDocumentsTemplatesProps } from './DocumentsTemplates.types'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { EditorFormContainer } from '../editorFormContainer/EditorFormContainer'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { DeleteIco } from '../icons'
import { ViewActions } from '../viewActions/ViewActions'
import { ViewActionsButton } from '../viewActions/ViewActionsButton'
import MarkdownEditor from '../markdownEditor/MarkdownEditor'
import * as dalAccount from '../../dal/DalAccount'
import { Utils } from '../../shared/Utils'
import { FePreferences } from '../../models/FePreferences'
import { InternalDocumentTemplate } from '../../models/InternalDocumentTemplate'
import MarkdownViewer from '../markdownEditor/MarkdownViewer'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { useAuthContext } from '../../contexts/AuthContext'

export function DocumentsTemplates(props: IDocumentsTemplatesProps): JSX.Element {
  const { account, isEditMode, setIsLoading } = props
  const profileId = account.profile?.profileId
  const { t } = useTranslation()
  const theme = useTheme()
  const authContext = useAuthContext()
  const [docsTemplates, setDocsTemplate] = useState<InternalDocumentTemplate[]>(
    account.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || []
  )
  const [selectedDocTemplateIndex, setSelectedDocTemplateIndex] = useState<number>(0)
  const selectedDocTemplateIndexRef = useRef(selectedDocTemplateIndex)

  const docTemplate = docsTemplates[selectedDocTemplateIndex]
  const [changed, setChanged] = useState(false)
  const [toDeleteIndex, setToDeleteIndex] = useState<number>(-1)

  function initFields() {
    setChanged(false)
    setSelectedDocTemplateIndex(0)
    setToDeleteIndex(-1)
    setDocsTemplate(account.profile?.fePreferences?.archiveCfg?.internalDocumentTemplates || [])
  }

  useEffect(() => {
    initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    selectedDocTemplateIndexRef.current = selectedDocTemplateIndex
  }, [selectedDocTemplateIndex])

  function onCancel() {
    initFields()
    props.onCancel && props.onCancel()
  }

  function onAdd() {
    setDocsTemplate((ts) => {
      return [
        {
          mimetype: 'text/markdown:custom1',
          name: t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_DEFAULT),
        } as InternalDocumentTemplate,
        ...ts,
      ]
    })
    setSelectedDocTemplateIndex(0)
    setChanged(true)
  }

  function onSelectDocTemplate(index: number) {
    setSelectedDocTemplateIndex(index)
  }

  function isDocTemplateSelected(index: number) {
    return index === selectedDocTemplateIndex
  }

  function onChangeDocTemplate(key: string, value: string) {
    // Use selectedDocTemplateIndexRef because in the case of 'content'
    // the event is called by the Markdown editor via an asynchronous
    // function and selectedDocTemplateIndex is equal to the previous value
    if (selectedDocTemplateIndexRef.current < 0 || selectedDocTemplateIndexRef.current >= docsTemplates.length) {
      return
    }
    setDocsTemplate((ts) => {
      const copy = [...ts]
      const template: InternalDocumentTemplate = { ...copy[selectedDocTemplateIndexRef.current], [key]: value }
      copy.splice(selectedDocTemplateIndexRef.current, 1, template)
      return copy
    })
    setChanged(true)
  }

  async function onSave() {
    if (!profileId) {
      return
    }
    try {
      setIsLoading(true)
      const internalDocumentTemplates = docsTemplates
      const fePreferences = account.profile?.fePreferences
      const fePreferencesUpdated: FePreferences = {
        ...fePreferences,
        archiveCfg: { ...fePreferences?.archiveCfg, internalDocumentTemplates },
      }
      const abortController = new AbortController()
      const updatedAccount = await dalAccount.updateProfile(abortController.signal, profileId, {
        fePreferences: fePreferencesUpdated,
      })
      account.profile = updatedAccount.profile
      authContext.setLinkedStructureAccount(updatedAccount)
      initFields()
      props.onSave && props.onSave()
    } catch (error) {
      Utils.enqueueSnackbarError2(error, t)
    } finally {
      setIsLoading(false)
    }
  }

  function onDelete(index: number, event: MouseEvent) {
    event.stopPropagation()
    setToDeleteIndex(index)
  }

  function onDeleteConfirmation(index: number) {
    setDocsTemplate((ts) => {
      const copy = [...ts]
      copy.splice(index, 1)
      return copy
    })
    setChanged(true)
  }

  const count = docsTemplates?.length || 0
  const backgroundColor = theme.palette.common.white
  const borderColor = theme.palette.common.gray6

  return (
    <EditorFormContainer>
      <SimpleDialog
        title={t(msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_TITLE)}
        content={t(msgIds.MSG_DOCUMENTS_TEMPLATES_DELETE_DIALOG_CONFIRM_BODY)}
        actionsStyle="yesNO"
        isOpen={toDeleteIndex >= 0}
        onClose={(result) => {
          if (result.userChoice === 'yes') {
            onDeleteConfirmation(toDeleteIndex)
          }
          setToDeleteIndex(-1)
        }}
      />
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography py={1}>{t(msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_LABEL, { count })}</Typography>
          {isEditMode && <Button onClick={onAdd}>{t(msgIds.MSG_DOCUMENTS_TEMPLATES_LIST_ADD_BUTTON)}</Button>}
        </Stack>
        <Card
          variant="outlined"
          sx={{
            height: 300,
            borderColor,
            overflow: 'auto',
          }}
        >
          {Array.isArray(docsTemplates) &&
            docsTemplates.map((template, i) => (
              <Stack
                key={i}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                px={2}
                py={1}
                sx={{
                  backgroundColor: isDocTemplateSelected(i) ? theme.palette.primary.lighter : backgroundColor,
                }}
                onClick={() => onSelectDocTemplate(i)}
              >
                <Stack>
                  <Typography>{template.name}</Typography>
                  <Typography variant="caption" color={theme.palette.text.secondary}>
                    {template.dsc}
                  </Typography>
                </Stack>
                {isEditMode && (
                  <IconButton onClick={(e) => onDelete(i, e)}>
                    <DeleteIco />
                  </IconButton>
                )}
              </Stack>
            ))}
        </Card>
      </Stack>
      <Divider />
      <TextField
        disabled={!isEditMode || !docTemplate}
        label={t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_NAME_LABEL)}
        value={docTemplate?.name || ''}
        onChange={(e) => onChangeDocTemplate('name', e.target.value)}
      />
      <TextField
        disabled={!isEditMode || !docTemplate}
        label={t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_DESCRIPTION_LABEL)}
        multiline
        maxRows={4}
        value={docTemplate?.dsc || ''}
        onChange={(e) => onChangeDocTemplate('dsc', e.target.value)}
      />
      <Divider />
      <Stack spacing={2}>
        <Typography>{t(msgIds.MSG_DOCUMENTS_TEMPLATES_TEMPLATE_CONTENT_LABEL)}</Typography>
        {isEditMode && docTemplate ? (
          <Stack height={300}>
            <MarkdownEditor
              height="unset"
              style={{ flex: 1, boxSizing: 'border-box' }}
              initialValue={docTemplate?.content || ''}
              onContentChanged={(content, isChanged, len) => onChangeDocTemplate('content', content)}
            />
          </Stack>
        ) : (
          <Card
            variant="outlined"
            sx={{
              p: 1.5,
              height: 300,
              borderColor,
              overflow: 'auto',
            }}
          >
            <Box sx={{ opacity: 0.5 }}>
              <MarkdownViewer initialValue={docTemplate?.content} />
            </Box>
          </Card>
        )}
      </Stack>
      {isEditMode && (
        <ViewActions justifyContent={'center'} sx={{ paddingTop: 6 }}>
          <ViewActionsButton autoFocus defaultAction disabled={!changed} onClick={onSave}>
            {t(msgIds.MSG_SAVE)}
          </ViewActionsButton>
          <ViewActionsButton onClick={onCancel}>{t(msgIds.MSG_CANCEL)}</ViewActionsButton>
        </ViewActions>
      )}
    </EditorFormContainer>
  )
}
