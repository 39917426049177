import { Box, Stack, StackProps, useTheme } from '@mui/material'
import { IDesktopMenuProps } from './DesktopMenu.types'
import { useTranslation } from 'react-i18next'
import msgIds from '../../locales/msgIds'
import { DesktopDocumentsMenu } from '../desktopDocumentsMenu/DesktopDocumentsMenu'
import { useDesktopContext } from '../../contexts/DesktopContext'
import { ActionType, ArchiveItemSourceType } from '../../shared/Constants'
import { DragEvent, useState } from 'react'
import { Utils } from '../../shared/Utils'
import { createDocumentFile } from '../../shared/types/DocumentFile'
import { useAuthContext } from '../../contexts/AuthContext'
import { IInternalDocTemplateSelectorDialogData } from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog.types'
import InternalDocTemplateSelectorDialog from '../../dialogs/internalDocTemplateSelectorDialog/InternalDocTemplateSelectorDialog'
import SimpleDialog from '../../dialogs/simpleDialog/SimpleDialog'
import { ISimpleDialogData } from '../../dialogs/simpleDialog/SimpleDialog.types'

export function DesktopMenu(props: IDesktopMenuProps & StackProps): JSX.Element {
  const authContext = useAuthContext()
  const desktop = useDesktopContext()
  const {
    menuMode,
    collapsedWith,
    expandedWith,
    toggleMenuMode,
    onCreateDocumentFromTemplate,
    onCreateDocument,
    onRemoveDesktopDocument,
    onRemoveAllDocuments,
    onClickDesktopDocument,
    ...rest
  } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [isHoverDrop, setIsHoverDrop] = useState(false)
  const grayAreaWidth = menuMode === 'expanded' ? expandedWith : collapsedWith

  // dialogs
  const [simpleDialogData, setSimpleDialogData] = useState<ISimpleDialogData>()
  const [simpleDialogOpen, setSimpleDialogOpen] = useState(false)
  const [internalDocTemplateSelectorDialogData, setInternalDocTemplateSelectorDialogData] =
    useState<IInternalDocTemplateSelectorDialogData>()
  const [internalDocTemplateSelectorDialogOpen, setInternalDocTemplateSelectorDialogOpen] = useState(false)

  async function onDrop(event: DragEvent<HTMLElement>) {
    event.preventDefault()
    setIsHoverDrop(false)
    const files = Utils.filesFromDragEvent(event)
    const loads = files.map((file) =>
      createDocumentFile(file).catch((error) => {
        Utils.enqueueSnackbarError2(error, t)
        return null
      })
    )
    const docFiles = await Promise.all(loads)
    for (const docFile of docFiles) {
      if (docFile) {
        onCreateDocument(ArchiveItemSourceType.externalSource, docFile)
      }
    }
  }

  function onDragOver(event: DragEvent<HTMLElement>) {
    event.preventDefault()
    setIsHoverDrop(true)
  }

  return (
    <Stack {...rest}>
      {simpleDialogData && <SimpleDialog {...simpleDialogData} isOpen={simpleDialogOpen}></SimpleDialog>}
      {internalDocTemplateSelectorDialogData && (
        <InternalDocTemplateSelectorDialog
          {...internalDocTemplateSelectorDialogData}
          isOpen={internalDocTemplateSelectorDialogOpen}
          onClose={() => setInternalDocTemplateSelectorDialogOpen(false)}
        />
      )}

      <Stack
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={() => setIsHoverDrop(true)}
        onDragLeave={() => setIsHoverDrop(false)}
        sx={{
          position: 'relative',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            backgroundColor: isHoverDrop ? theme.palette.grey[500] : theme.palette.grey[400],
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            transition: theme.transitions.create('width'),
            width: grayAreaWidth,
          }}
        />
        <Box
          p={1}
          width={grayAreaWidth}
          sx={{
            transition: theme.transitions.create('width'),
          }}
        ></Box>
        <Stack flex={1} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <DesktopDocumentsMenu
            mode={menuMode}
            desktopDocuments={desktop.desktopDocuments}
            currentDesktopDocument={desktop.desktopDocuments[desktop.currentDocumentIndex]}
            onRemoveDesktopDocument={onRemoveDesktopDocument}
            onClickDesktopDocument={onClickDesktopDocument}
            width={grayAreaWidth}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
