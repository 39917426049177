import { ConsentMode, ConsentMode_all } from '../shared/Constants'
import { Consent, IConsentJson } from './Consent'
import { ContractSectionAction, IContractSectionActionJson } from './ContractSectionAction'
import { ITreatmentJson, Treatment } from './Treatment'

export interface IContractSectionJson {
  id: number
  version_id: number
  previous_section_id: number
  consent_mode: number
  text: string
  before_of: number
  reference: string
  created_at: string
  updated_at: string
  actions: IContractSectionActionJson[]
  treatments: ITreatmentJson[]
  consent?: IConsentJson
}

export class ContractSection {
  id: number = 0
  versionId: number = 0
  previousSectionId: number = 0
  consentMode: ConsentMode_all = ConsentMode.none
  text: string = ''
  beforeOf: number = 0
  reference: string = ''
  createdAt: string = ''
  updatedAt: string = ''
  actions: ContractSectionAction[] = []
  treatments: Treatment[] = []
  consent?: Consent

  _isAccepted: boolean = false
  get isAccepted(): boolean {
    return !!this.consent && !this.consent.revokedAt
  }

  _isDataProcessingSection: boolean = false
  get isDataProcessingSection(): boolean {
    return this.actions.some((p) => p.action === 'data_processing')
  }

  constructor() {}

  public static serialize(obj: ContractSection): IContractSectionJson {
    return {
      id: obj.id,
      version_id: obj.versionId,
      previous_section_id: obj.previousSectionId,
      consent_mode: obj.consentMode,
      text: obj.text,
      before_of: obj.beforeOf,
      reference: obj.reference,
      created_at: obj.createdAt,
      updated_at: obj.updatedAt,
      actions: ContractSectionAction.serializeArray(obj.actions),
      treatments: Treatment.serializeArray(obj.treatments),
      consent: obj.consent ? Consent.serialize(obj.consent) : undefined,
    }
  }

  public static deserialize(json: IContractSectionJson): ContractSection {
    const res = new ContractSection()
    res.id = json.id
    res.versionId = json.version_id
    res.previousSectionId = json.previous_section_id
    res.consentMode = json.consent_mode
    res.text = json.text
    res.beforeOf = json.before_of
    res.reference = json.reference
    res.createdAt = json.created_at
    res.updatedAt = json.updated_at
    res.actions = ContractSectionAction.deserializeArray(json.actions)
    res.treatments = Treatment.deserializeArray(json.treatments)
    res.consent = json.consent ? Consent.deserialize(json.consent) : undefined
    return res
  }

  public static serializeArray(objs: ContractSection[]): IContractSectionJson[] {
    const jsons = objs.map((p) => {
      return ContractSection.serialize(p)!
    })
    return jsons
  }

  public static deserializeArray(json: IContractSectionJson[]): ContractSection[] {
    const res = json.map((p) => {
      return ContractSection.deserialize(p)!
    })
    return res
  }
}
