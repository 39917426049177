import React from 'react'
import { ThemeProvider } from '@mui/material'

import { useAuthContext } from '../../contexts/AuthContext'
import { getProfileBasedTheme } from '../../themes/ThemeUtils'

interface IDynamicThemeProviderProps {
  children: React.ReactNode
}

export default function DynamicThemeProvider(props: IDynamicThemeProviderProps) {
  const authContext = useAuthContext()
  const theme = getProfileBasedTheme(authContext.loggedAccount?.profile?.type)

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
