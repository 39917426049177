import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import * as dalAccount from '../../dal/DalAccount'
import LeftbarItemsGroup from './LeftbarItemsGroup'
import AccountIdentity from '../identities/AccountIdentity'
import { ILeftbarItemsArea } from './LeftbarData'
import { useAuthContext } from '../../contexts/AuthContext'
import { StyledDialog } from '../../dialogs/styledDialog/StyledDialog'
import { useState } from 'react'
import { ViewHeader } from '../viewHeader/ViewHeader'
import { ViewContent } from '../viewContent/ViewContent'
import msgIds from '../../locales/msgIds'
import { useTranslation } from 'react-i18next'
import { ProfileSelector } from '../profileSelector/ProfileSelector'
import { Account } from '../../models/Account'
import { Utils } from '../../shared/Utils'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'

interface ILeftbarItemsAreaProps {
  data: ILeftbarItemsArea
  index: number
}

export default function LeftbarItemsArea(props: ILeftbarItemsAreaProps) {
  const authContext = useAuthContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [availableAccounts, setAvailableAccounts] = useState<Account[]>([])
  const enableOpenProfile = authContext.loggedUserId === props.data.account.user?.userId

  // dialogs
  const [profileSelectorDialogOpen, setProfileSelectorDialogOpen] = useState(false)

  async function onClickAccount() {
    if (!enableOpenProfile) {
      return
    }
    try {
      setIsLoading(true)
      const abortController = new AbortController()
      const accounts = await dalAccount.getLoggedAvailableAccounts(abortController.signal)
      const availableAccounts = accounts.filter((p) => !p.profile?.expiredAt)
      setAvailableAccounts(availableAccounts)

      if (accounts.length > 1) {
        setProfileSelectorDialogOpen(true)
      } else {
        enqueueSnackbar(t(msgIds.MSG_NO_OTHER_AVAILABLE_ACCOUNTS), { variant: 'success' })
      }
    } catch (err) {
      Utils.enqueueSnackbarError2(err, t)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
        marginTop: props.index === 0 ? '0px' : '30px',
        flexGrow: 1,
      }}
    >
      <Box onClick={onClickAccount} sx={{ cursor: enableOpenProfile ? 'pointer' : undefined }}>
        <AccountIdentity
          account={props.data.account}
          infoToShow={props.data.accountInfoToShow}
          showProfileDsc={true}
          showProfileInfo={false}
          avatarClicked={(account) => {}}
        />
      </Box>
      {enableOpenProfile && (
        <StyledDialog
          minHeight="auto"
          open={profileSelectorDialogOpen}
          onClose={() => setProfileSelectorDialogOpen(false)}
        >
          <ViewHeader
            title={t(msgIds.MSG_PROFILE_SELECTOR_DIALOG_TITLE)}
            exitButtonVisible={true}
            onClickExit={() => setProfileSelectorDialogOpen(false)}
          />
          <ViewContent>
            <ProfileSelector
              accounts={availableAccounts}
              onChangeAccount={() => {
                setProfileSelectorDialogOpen(false)
                navigate('/notice_board', { replace: true })
              }}
            />
          </ViewContent>
        </StyledDialog>
      )}

      <List sx={{ paddingTop: 0 }}>
        {props.data.items?.map((group, index) => (
          <ListItem key={group.title} disablePadding>
            <LeftbarItemsGroup data={group} isExpandedInit={group.isExpandedInit} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
