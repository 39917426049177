import { useTheme } from '@mui/material'
import React, { useReducer } from 'react'

import { Action } from '../shared/types/Action'

export const topbarHeight = 64
export const leftbarWidth = 240
export const commandbarHeight = 50

interface IContextState {
  // context props
  canToggleLeftbarStatus: boolean
  isLeftbarOpened: boolean
  windowWidth: number
  windowHeight: number

  // context actions
  enableLeftbarToggle: () => void
  disableLeftbarToggle: () => void
  openLeftbar: () => void
  closeLeftbar: () => void
  toggleLeftbar: () => void
  setWindowWidth: (width: number) => void
  setWindowHeight: (height: number) => void
}

const initialState: IContextState = {
  // context props
  canToggleLeftbarStatus: false,
  isLeftbarOpened: false,
  windowWidth: 0,
  windowHeight: 0,

  // context actions
  enableLeftbarToggle: () => {},
  disableLeftbarToggle: () => {},
  openLeftbar: () => {},
  closeLeftbar: () => {},
  toggleLeftbar: () => {},
  setWindowWidth: (width: number) => {},
  setWindowHeight: (height: number) => {},
}

type Actions =
  | Action<'ENABLE_LEFTBAR_TOGGLE', undefined>
  | Action<'DISABLE_LEFTBAR_TOGGLE', undefined>
  | Action<'OPEN_LEFTBAR', undefined>
  | Action<'CLOSE_LEFTBAR', undefined>
  | Action<'TOGGLE_LEFTBAR', undefined>
  | Action<'SET_WINDOW_WIDTH', number>
  | Action<'SET_WINDOW_HEIGHT', number>

type ReducerFunc = (state: IContextState, action: Actions) => IContextState
function reducer(state: IContextState, action: Actions): IContextState {
  switch (action.type) {
    case 'ENABLE_LEFTBAR_TOGGLE': {
      return {
        ...state,
        canToggleLeftbarStatus: true,
      }
    }
    case 'DISABLE_LEFTBAR_TOGGLE': {
      return {
        ...state,
        canToggleLeftbarStatus: false,
      }
    }
    case 'OPEN_LEFTBAR': {
      return {
        ...state,
        isLeftbarOpened: true,
      }
    }
    case 'CLOSE_LEFTBAR': {
      return {
        ...state,
        isLeftbarOpened: false,
      }
    }
    case 'TOGGLE_LEFTBAR': {
      return {
        ...state,
        isLeftbarOpened: !state.isLeftbarOpened,
      }
    }
    case 'SET_WINDOW_WIDTH': {
      return {
        ...state,
        windowWidth: action.payload ?? 0,
      }
    }
    case 'SET_WINDOW_HEIGHT': {
      return {
        ...state,
        windowHeight: action.payload ?? 0,
      }
    }
  }
}

const injectActions = (dispatch: (arg: Actions) => void, state: IContextState) => {
  state.enableLeftbarToggle = () => dispatch({ type: 'ENABLE_LEFTBAR_TOGGLE' })
  state.disableLeftbarToggle = () => dispatch({ type: 'DISABLE_LEFTBAR_TOGGLE' })
  state.openLeftbar = () => dispatch({ type: 'OPEN_LEFTBAR' })
  state.closeLeftbar = () => dispatch({ type: 'CLOSE_LEFTBAR' })
  state.toggleLeftbar = () => dispatch({ type: 'TOGGLE_LEFTBAR' })
  state.setWindowWidth = (width: number) => dispatch({ type: 'SET_WINDOW_WIDTH', payload: width })
  state.setWindowHeight = (height: number) => dispatch({ type: 'SET_WINDOW_HEIGHT', payload: height })
}

interface IProps {
  children?: React.ReactNode
}

const UiContext = React.createContext<IContextState>(initialState)
export const UiContextProvider: React.FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer<ReducerFunc>(reducer, initialState)
  injectActions(dispatch, state)

  const theme = useTheme()

  React.useEffect(() => {
    const handleWindowResize = () => {
      state.setWindowWidth(window.innerWidth)
      state.setWindowHeight(window.innerHeight)

      if (window.innerWidth > theme.breakpoints.values['md']) {
        state.disableLeftbarToggle()
        state.openLeftbar()
      } else {
        state.enableLeftbarToggle()
        state.closeLeftbar()
      }
    }

    window.addEventListener('resize', handleWindowResize)

    handleWindowResize() // inizializzo il layout

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return <UiContext.Provider value={state}>{children}</UiContext.Provider>
}

export const useUiContext = (): IContextState => React.useContext(UiContext)
